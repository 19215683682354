import dayjs from "dayjs";

import { CalendarEvent } from "../types/calendar.types";

type Holiday = {
  date: number;
  name: string;
};

type HolidaysByMonth = {
  [key: number]: Holiday[];
};

export const holidays: HolidaysByMonth = {
  0: [
    {
      date: 1,
      name: "New Year's Day",
    },
  ],
  1: [
    {
      date: 14,
      name: "Valentine's Day",
    },
    {
      date: 20,
      name: "Presidents' Day",
    },
  ],
  2: [
    {
      date: 17,
      name: "St. Patrick's Day",
    },
  ],
  3: [
    {
      date: 1,
      name: "April Fools' Day",
    },
    {
      date: 22,
      name: "Earth Day",
    },
  ],
  4: [
    {
      date: 5,
      name: "Cinco de Mayo",
    },
    {
      date: 31,
      name: "Memorial Day",
    },
  ],
  5: [
    {
      date: 1,
      name: "LGBTQ+ Pride Month",
    },
  ],
  6: [
    {
      date: 4,
      name: "Independence Day",
    },
  ],
  8: [
    {
      date: 6,
      name: "Labor Day",
    },
  ],
  9: [
    {
      date: 31,
      name: "Halloween",
    },
  ],
  10: [
    {
      date: 11,
      name: "Veterans Day",
    },
    {
      date: 25,
      name: "Thanksgiving Day",
    },
  ],
  11: [
    {
      date: 25,
      name: "Christmas Day",
    },
  ],
};

export const getHolidayCalendarEventsByMonth = (month: number): CalendarEvent[] => {
  if (!holidays[month]) return [];

  const currentYear = dayjs().year();

  return holidays[month].map((holiday) => {
    const date = dayjs().year(currentYear).month(month).date(holiday.date);

    return {
      id: date.toISOString(),
      title: holiday.name,
      start: date.toISOString(),
      allDay: true,
      color: "#00b4d8",
      extendedProps: {
        isHoliday: true,
      },
    } as CalendarEvent;
  });
};
