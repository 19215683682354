import { createEntityAdapter, createSlice, EntityId, EntityState, PayloadAction } from "@reduxjs/toolkit";

import { Content, ContentStage, ContentStatus } from "../../API";
import { brand } from "../../api/brandApi";
import { content } from "../../api/contentApi";
import { RootState } from "../store";

export const objFromArray = (arr: any[], key = "id"): {} =>
  arr.reduce((accumulator, current) => {
    // eslint-disable-next-line no-param-reassign
    accumulator[current[key]] = current;
    return accumulator;
  }, {});

// By default, `createEntityAdapter` gives you `{ ids: [], entities: {} }`.
export const columnAdapter = createEntityAdapter<ContentStatus>();
export const contentAdapter = createEntityAdapter<Content>();

export type KanbanState = {
  openContentDialog: boolean;
  columnOrder: string[];
  column: EntityState<ContentStatus>;
  content: EntityState<Content>;
  selectedContent: Content | undefined;
  openBrainstormPanel: boolean;
};

export const initialKanbanState: KanbanState = {
  openContentDialog: false,
  columnOrder: [],
  column: columnAdapter.getInitialState(),
  content: contentAdapter.getInitialState(),
  selectedContent: undefined,
  openBrainstormPanel: true,
};

const kanbanSlice = createSlice({
  name: "kanban",
  initialState: initialKanbanState,
  reducers: {
    openContentModal(state, action: PayloadAction<Content | undefined>) {
      state.selectedContent = action.payload;
      state.openContentDialog = true;
    },
    setSelectedContent(state, action: PayloadAction<Content | undefined>) {
      state.selectedContent = action.payload;
    },
    closeContentDialog(state) {
      state.openContentDialog = false;
    },
    updateSelectedContent(state, action: PayloadAction<Content>) {
      state.selectedContent = action.payload;

      contentAdapter.updateOne(state.content, {
        id: action.payload.id,
        changes: action.payload,
      });
    },
    setOpenBrainstormPanel(state, action: PayloadAction<boolean>) {
      state.openBrainstormPanel = action.payload;
    },
    updateContentAdapter(state, action: PayloadAction<Content>) {
      const content = action.payload;

      contentAdapter.updateOne(state.content, {
        id: content.id,
        changes: content,
      });
    },
    deleteContentFromStore(state, action: PayloadAction<Content>) {
      const content = action.payload;
      columnAdapter.updateOne(state.column, {
        id: content.statusId,
        changes: { contentIds: state.column.entities[content.statusId]?.contentIds.filter((c) => c !== content.id) },
      });
    },
    clearColumn(state, action: PayloadAction<string>) {
      const columnId = action.payload;
      const column = state.column.entities[columnId];

      // Delete the contents from state
      if (column?.contentIds) {
        contentAdapter.removeMany(state.content, column.contentIds as EntityId[]);
      }

      // Delete the contentIds references from the column
      columnAdapter.updateOne(state.column, {
        id: columnId,
        changes: { contentIds: [] },
      });
    },
    moveContent(state, action: PayloadAction<{ contentId: string; position: number; destinationColumnId?: string }>): void {
      const { contentId, position, destinationColumnId } = action.payload;

      const sourceColumnId = state.content.entities[contentId]?.statusId;

      if (!sourceColumnId) return;

      // Remove content from source column
      columnAdapter.updateOne(state.column, {
        id: sourceColumnId,
        changes: { contentIds: state.column.entities[sourceColumnId]?.contentIds.filter((c) => c !== contentId) },
      });

      // If columnId exists, it means that we have to add the content to the new column
      if (destinationColumnId) {
        // Change the content's columnId/statusId reference
        contentAdapter.updateOne(state.content, {
          id: contentId,
          changes: { statusId: destinationColumnId },
        });

        // Push the contentId to the specified position for the destination column
        state.column.entities[destinationColumnId]?.contentIds.splice(position, 0, contentId);
      } else {
        // Push the contentId to the specified position for the same column
        state.column.entities[sourceColumnId]?.contentIds.splice(position, 0, contentId);
      }
    },
    updateContentStatus(state, action: PayloadAction<{ contentId: string; statusId: string }>) {
      const { contentId, statusId } = action.payload;

      // Change content's columnId/statusId reference
      contentAdapter.updateOne(state.content, {
        id: contentId,
        changes: { statusId },
      });

      // Push the contentId to the end of the column
      const previousContentIds = state.column.entities[statusId]?.contentIds;

      if (previousContentIds) {
        const updatedContentIds = [...previousContentIds, contentId];

        columnAdapter.updateOne(state.column, {
          id: statusId,
          changes: { contentIds: updatedContentIds },
        });
      }
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(brand.endpoints.getBrand.matchFulfilled, (state, { payload }) => {
      // reduce the statuses by the id property into a shape of { 1: { ...contentStatus }}
      if (payload?.status) {
        columnAdapter.setAll(state.column, payload.status.items as ContentStatus[]);
      }

      const statusOrder = payload?.statusOrder as string[];

      if (statusOrder) {
        state.columnOrder = statusOrder;
      }
    });
    builder.addMatcher(content.endpoints.listContentByStatusId.matchFulfilled, (state, { payload }) => {
      if (payload?.listContentByStatusId) {
        contentAdapter.addMany(state.content, payload.listContentByStatusId.items as Content[]);
      }
    });
    builder.addMatcher(content.endpoints.createContent.matchFulfilled, (state, { payload }) => {
      if (payload?.createContent) {
        // Add the content
        contentAdapter.addOne(state.content, payload.createContent as Content);

        // Add the contentId reference to the column
        columnAdapter.updateOne(state.column, {
          id: payload.createContent.statusId,
          changes: {
            contentIds: [
              ...(state.column.entities[payload.createContent.statusId]?.contentIds || []),
              payload.createContent.id,
            ],
          },
        });
      }
    });
  },
});

export const {
  openContentModal,
  setSelectedContent,
  closeContentDialog,
  updateSelectedContent,
  moveContent,
  clearColumn,
  deleteContentFromStore,
  updateContentAdapter,
  setOpenBrainstormPanel,
} = kanbanSlice.actions;
export default kanbanSlice.reducer;

export const {
  selectById: selectColumnById,
  selectIds: selectColumnIds,
  selectEntities: selectColumnEntities,
  selectAll: selectAllColumns,
  selectTotal: selectTotalColumns,
} = columnAdapter.getSelectors<RootState>((state) => state.kanban.column);

export const {
  selectById: selectContentById,
  selectIds: selectContentIds,
  selectEntities: selectContentEntities,
  selectAll: selectAllContents,
  selectTotal: selectTotalContents,
} = contentAdapter.getSelectors<RootState>((state) => state.kanban.content);

export const selectAllColumnsInOrder = (state: RootState) => {
  return state.kanban.columnOrder.map((columnId) => selectColumnById(state, columnId));
};

export const selectInProgressColumn = (state: RootState) => {
  const columns = selectAllColumns(state);

  return columns.find((column) => column.stage === ContentStage.IN_PROGRESS);
};

export const selectApprovalsColumn = (state: RootState) => {
  const columns = selectAllColumns(state);

  return columns.find((column) => column.stage === ContentStage.APPROVAL_NEEDED);
};

export const selectHasPlatformSelected = (state: RootState) => {
  return state.kanban.selectedContent?.platforms && state.kanban.selectedContent?.platforms?.length > 0;
};