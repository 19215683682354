import { createEntityAdapter, createSlice, EntityState, PayloadAction } from "@reduxjs/toolkit";
import dayjs from "dayjs";

import { Content, Platform } from "../../API";
import { CalendarEvent } from "../../types/calendar.types";
import { getHolidayCalendarEventsByMonth } from "../../utils/dateUtils";
import { RootState } from "../store";
import { getInstagramProfileFeed } from "./instagramSlice";

export const calendarContentAdapter = createEntityAdapter<Content>();

export type CalendarState = {
  content: EntityState<Content>;
  events: CalendarEvent[];
};

export const initialCalendarState: CalendarState = {
  content: calendarContentAdapter.getInitialState(),
  events: [],
};

const calendarSlice = createSlice({
  name: "calendar",
  initialState: initialCalendarState,
  reducers: {
    updateCalendarContentPublishDate: (state, action: PayloadAction<{ contentId: string; publishDate: string }>) => {
      const { contentId, publishDate } = action.payload;

      const prevEvents = [...state.events];

      const updatedEvents = prevEvents.map((event) => {
        if (event.id === contentId) {
          return {
            ...event,
            start: publishDate,
            extendedProps: {
              ...event.extendedProps,
              content: {
                ...event.extendedProps?.content,
                publishDate,
              },
            },
          };
        }
      });

      state.events = updatedEvents as CalendarEvent[];
    },

    updateCalendarContent: (state, action: PayloadAction<Content>) => {
      const contentToUpdate = action.payload;

      const prevEvents = [...state.events];

      const updatedEvents = prevEvents.map((event) => {
        if (event.id === contentToUpdate.id) {
          return {
            ...event,
            extendedProps: {
              ...event.extendedProps,
              content: {
                ...contentToUpdate,
              },
            },
          };
        }
      });

      state.events = updatedEvents as CalendarEvent[];
    },

    addCalendarContent: (state, action: PayloadAction<Content>) => {
      const content = action.payload;

      calendarContentAdapter.addOne(state.content, content);

      const updatedEvents = [
        ...state.events,
        {
          id: content.id,
          title: content.title,
          start: content.publishDate,
          allDay: false,
          extendedProps: { content },
        } as CalendarEvent,
      ];

      state.events = updatedEvents;
    },

    removeCalendarEvent: (state, action: PayloadAction<string>) => {
      const contentId = action.payload;
      calendarContentAdapter.removeOne(state.content, contentId);

      const updatedEvents = state.events.filter((event) => event.id !== contentId);
      state.events = updatedEvents;
    },

    setCalendarHolidayEvents: (state, action: PayloadAction<number>) => {
      const month = action.payload;

      const updatedEvents = [...state.events].filter((event) => !event.extendedProps?.isHoliday);

      state.events = [...updatedEvents, ...getHolidayCalendarEventsByMonth(month)];
    },

    setCalendarEvents: (state, action: PayloadAction<Content[]>) => {
      const contents = action.payload;

      calendarContentAdapter.addMany(state.content, contents);

      const events: CalendarEvent[] = contents
        .filter((cont) => cont?.publishDate && !cont.published)
        .map((item) => {
          const content = item as Content;

          return {
            id: content.id,
            title: content.title,
            start: content.publishDate,
            // color: content?.published ? "#ff7096" : "#ff3424",
            extendedProps: {
              content,
            },
          } as CalendarEvent;
        });

      state.events = [...state.events, ...events];
    },

    resetCalendarState: (state) => {
      state.content = calendarContentAdapter.getInitialState();
      state.events = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getInstagramProfileFeed.fulfilled, (state, action) => {
      const instagramFeed = action.payload;

      const updatedEvents = [
        ...state.events,
        ...(instagramFeed?.map((item) => {
          return {
            id: item.id,
            title: item?.caption ?? "Untitled",
            start: dayjs(item.timestamp, "YYYY-MM-DDTHH:mm:ssZ").toISOString(),
            color: "#ff7096",
            extendedProps: {
              imageUrl: item.thumbnail_url ?? item.media_url,
              platforms: [Platform.INSTAGRAM],
              ...item,
            },
          } as CalendarEvent;
        }) || []),
      ];

      state.events = updatedEvents;
    });
  },
});

export const {
  addCalendarContent,
  setCalendarHolidayEvents,
  updateCalendarContentPublishDate,
  updateCalendarContent,
  removeCalendarEvent,
  setCalendarEvents,
  resetCalendarState,
} = calendarSlice.actions;
export default calendarSlice.reducer;

export const {
  selectById: selectCalendarContentById,
  selectIds: selectCalendarContentIds,
  selectEntities: selectCalendarContentEntities,
  selectAll: selectAllCalendarContents,
  selectTotal: selectTotalCalendarContents,
} = calendarContentAdapter.getSelectors<RootState>((state) => state.calendar.content);

