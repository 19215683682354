import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Platform } from "../../API";
import { MediaPreview } from "../../types/file.types";

export type ContentView = "grid" | "list" | "kanban";

export type PlanningState = {
  selectedPlatformPreview: Platform | undefined;
  selectedContentView: ContentView;
  mainMediaUrl: MediaPreview | undefined;
  lastSavedStatus: {
    isEdited: boolean;
    lastSaved: string | undefined;
  };
};

export const initialPlanningState: PlanningState = {
  selectedPlatformPreview: undefined,
  selectedContentView: "kanban",
  mainMediaUrl: undefined,
  lastSavedStatus: {
    isEdited: false,
    lastSaved: undefined,
  },
};

const planningSlice = createSlice({
  name: "planning",
  initialState: initialPlanningState,
  reducers: {
    setSelectedPlatformPreview(state, action: PayloadAction<Platform>) {
      state.selectedPlatformPreview = action.payload;
    },
    setSelectedContentView(state, action: PayloadAction<ContentView>) {
      state.selectedContentView = action.payload;
    },
    setMainMediaUrl(state, action: PayloadAction<PlanningState["mainMediaUrl"]>) {
      state.mainMediaUrl = action.payload;
    },
    updateLastSavedStatus(state, action: PayloadAction<PlanningState["lastSavedStatus"]>) {
      state.lastSavedStatus = action.payload;
    },
    resetState() {
      return initialPlanningState;
    },
  },
});

export const { setSelectedContentView, setSelectedPlatformPreview, setMainMediaUrl, updateLastSavedStatus, resetState } =
  planningSlice.actions;
export default planningSlice.reducer;
