import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

import { ChannelStatistics, YouTubeVideo, YouTubeVideoWithMetrics } from "../../types/youtubeApi.types";

type GetYouTubeMetricsThunkParams = {
  accessToken: string;
  youtubeChannelId: string;
};

export const getYouTubeMetrics = createAsyncThunk(
  "youtube/getYouTubeMetrics",
  async ({ accessToken, youtubeChannelId }: GetYouTubeMetricsThunkParams) => {
    const axiosInstanceStats = axios.create({
      baseURL: "https://www.googleapis.com/youtube/v3",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const response = await axiosInstanceStats.get("/channels", {
      params: {
        part: "statistics",
        id: youtubeChannelId,
      },
    });

    return response.data.items[0].statistics as ChannelStatistics;
  },
);

type GetYouTubeVideosThunkParams = {
  accessToken: string;
  youtubeChannelId: string;
};

export const getYouTubeVideos = createAsyncThunk(
  "youtube/getYouTubeVideos",
  async ({ accessToken, youtubeChannelId }: GetYouTubeVideosThunkParams) => {
    const axiosInstanceStats = axios.create({
      baseURL: "https://www.googleapis.com/youtube/v3",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const response = await axiosInstanceStats.get("/search", {
      params: {
        part: "id,snippet",
        channelId: youtubeChannelId,
        maxResults: 20,
        order: "date",
      },
    });

    const videos = response.data.items as YouTubeVideo[];

    const videosWithMetricsPromises = videos.map((video) => {
      if (video.id.videoId) {
        return axiosInstanceStats.get("/videos", {
          params: {
            part: "snippet,contentDetails,statistics",
            id: video.id.videoId,
          },
        });
      }
    });

    const videosWithMetrics = await Promise.all(videosWithMetricsPromises.filter((v) => v !== undefined));

    const videosWithMetricsResult = videosWithMetrics.map((v) => v?.data?.items[0]);

    return videosWithMetricsResult as YouTubeVideoWithMetrics[];
  },
);

// End of thunk functions

export type YouTubeState = {
  isLoading: boolean;
  metrics: ChannelStatistics | null;
  videos: YouTubeVideoWithMetrics[] | null;
};

export const initialYouTubeState: YouTubeState = {
  isLoading: false,
  metrics: null,
  videos: null,
};

const youtubeSlice = createSlice({
  name: "youtube",
  initialState: initialYouTubeState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getYouTubeMetrics.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getYouTubeMetrics.fulfilled, (state, { payload }) => {
      state.metrics = payload;
      state.isLoading = false;
    });
    builder.addCase(getYouTubeMetrics.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getYouTubeVideos.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getYouTubeVideos.fulfilled, (state, { payload }) => {
      state.videos = payload;
      state.isLoading = false;
    });
    builder.addCase(getYouTubeVideos.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

// export const {} = youtubeSlice.actions;
export default youtubeSlice.reducer;
