import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";

import { RangeShortcutOption, rangeShortcuts } from "../../components/RangeShortcuts";
import { RootState } from "../store";

export type AnalyticsState = {
  currentRange: RangeShortcutOption;
  timeframe: string[];
  comparisonTimeframe: string[];
};

export const initialAnalyticsState: AnalyticsState = {
  currentRange: rangeShortcuts[0],
  timeframe: [dayjs().subtract(7, "day").toISOString(), dayjs().subtract(1, "day").toISOString()],
  comparisonTimeframe: [dayjs().subtract(14, "day").toISOString(), dayjs().subtract(8, "day").toISOString()],
};

const analyticsSlice = createSlice({
  name: "analytics",
  initialState: initialAnalyticsState,
  reducers: {
    setTimeframe(state, { payload }) {
      state.timeframe = payload.timeframe;
      state.comparisonTimeframe = payload.comparisonTimeframe;
    },
    setRange(state, { payload }) {
      state.currentRange = payload;
    },
  },
});

export const { setTimeframe, setRange } = analyticsSlice.actions;
export default analyticsSlice.reducer;

export const selectTimeframe = (state: RootState) => {
  return `${dayjs(state.analytics.timeframe[0])?.format("MMM D")} - ${dayjs(state.analytics.timeframe[1])?.format("MMM D")}`;
};

export const selectComparisonTimeframe = (state: RootState) => {
  return `vs ${dayjs(state.analytics.comparisonTimeframe[0])?.format("MMM D")} - ${dayjs(
    state.analytics.comparisonTimeframe[1],
  )?.format("MMM D")}`;
};