/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { combineReducers, configureStore, PreloadedState } from "@reduxjs/toolkit";

import { instagramApi } from "../api/instagramApi";
import { rootApi } from "../api/rootApi";
import analyticsReducer from "./slices/analyticsSlice";
import batchPlanningReducer from "./slices/batchPlanningSlice";
import calendarReducer from "./slices/calendarSlice";
import dashboardReducer from "./slices/dashboardSlice";
import facebookReducer from "./slices/facebookSlice";
import fileReducer from "./slices/fileSlice";
import instagramReducer from "./slices/instagramSlice";
import kanbanReducer from "./slices/kanbanSlice";
import planningReducer from "./slices/planningSlice";
import userReducer from "./slices/userSlice";
import youtubeReducer from "./slices/youtubeSlice";

const rootReducer = combineReducers({
  user: userReducer,
  kanban: kanbanReducer,
  calendar: calendarReducer,
  file: fileReducer,
  instagram: instagramReducer,
  facebook: facebookReducer,
  planning: planningReducer,
  dashboard: dashboardReducer,
  youtube: youtubeReducer,
  batchPlanning: batchPlanningReducer,
  analytics: analyticsReducer,
  [rootApi.reducerPath]: rootApi.reducer,
  [instagramApi.reducerPath]: instagramApi.reducer,
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) =>
  configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware(
        process.env.NODE_ENV === "development"
          ? {
              immutableCheck: {
                ignoredPaths: ["ignoredPath", "ignoredNested.one", "ignoredNested.two"],
              },
            }
          : {},
      ).concat(rootApi.middleware, instagramApi.middleware),
  });

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];
