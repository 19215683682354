import { RootState } from "./store";

export const storageItemName = "member";

export const loadState = () => {
  try {
    const serializedState = sessionStorage.getItem(storageItemName);

    if (serializedState === null) {
      return undefined;
    }

    return JSON.parse(serializedState);
  } catch (error) {
    console.log(error);
    return undefined;
  }
};

export const saveState = (state: RootState["user"]) => {
  try {
    const serializedState = JSON.stringify(state);
    sessionStorage.setItem(storageItemName, serializedState);
  } catch (error) {
    console.log(error);
  }
};
