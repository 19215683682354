import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type FileState = {
  files: File[];
  dragUrl: string | undefined;
  isUploadingFile: boolean;
  uploadProgress: number;
};

export const initialFileState: FileState = {
  files: [],
  dragUrl: undefined,
  isUploadingFile: false,
  uploadProgress: 0,
};

const fileSlice = createSlice({
  name: "file",
  initialState: initialFileState,
  reducers: {
    setFiles(state, action: PayloadAction<File[]>) {
      state.files = action.payload;
    },
    setDragUrl(state, action: PayloadAction<string | undefined>) {
      state.dragUrl = action.payload;
    },
    setIsUploadingFile(state, action: PayloadAction<boolean>) {
      state.isUploadingFile = action.payload;
    },
    setFileUploadProgress(state, action: PayloadAction<number>) {
      state.uploadProgress = action.payload;
    },
  },
});

export const { setFiles, setDragUrl, setIsUploadingFile, setFileUploadProgress } = fileSlice.actions;
export default fileSlice.reducer;
