import {
  CreateContentMutation,
  CreateContentMutationVariables,
  CreateContentStatusMutation,
  CreateContentStatusMutationVariables,
  DeleteContentMutation,
  DeleteContentMutationVariables,
  DeleteContentStatusMutation,
  DeleteContentStatusMutationVariables,
  GetContentQuery,
  GetContentQueryVariables,
  ListContentByBrandIdAndApprovalStatusQuery,
  ListContentByBrandIdAndApprovalStatusQueryVariables,
  ListContentByBrandIdAndCreatedAtQuery,
  ListContentByBrandIdAndCreatedAtQueryVariables,
  ListContentByBrandIdAndPublishDateQuery,
  ListContentByBrandIdAndPublishDateQueryVariables,
  ListContentByBrandIdQuery,
  ListContentByBrandIdQueryVariables,
  ListContentByStatusIdQuery,
  ListContentByStatusIdQueryVariables,
  UpdateContentMutation,
  UpdateContentMutationVariables,
  UpdateContentStatusMutation,
  UpdateContentStatusMutationVariables,
} from "../API";
import {
  createContent,
  createContentStatus,
  deleteContent,
  deleteContentStatus,
  updateContent,
  updateContentStatus,
} from "../graphql/mutations";
import {
  getContent,
  listContentByBrandId,
  listContentByBrandIdAndApprovalStatus,
  listContentByBrandIdAndCreatedAt,
  listContentByBrandIdAndPublishDate,
  listContentByStatusId,
} from "../graphql/queries";
import { rootApi } from "./rootApi";

export const content = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    // ======================================================
    // ========== CONTENT STATUS ============================
    // ======================================================

    createContentStatus: builder.mutation<CreateContentStatusMutation, CreateContentStatusMutationVariables>({
      query: (variables) => ({
        graphqlQuery: createContentStatus,
        variables,
      }),
      invalidatesTags: ["ContentBoard"],
    }),

    updateContentStatus: builder.mutation<UpdateContentStatusMutation, UpdateContentStatusMutationVariables>({
      query: (variables) => ({
        graphqlQuery: updateContentStatus,
        variables,
      }),
    }),

    deleteContentStatus: builder.mutation<DeleteContentStatusMutation, DeleteContentStatusMutationVariables>({
      query: (variables) => ({
        graphqlQuery: deleteContentStatus,
        variables,
      }),
    }),

    // ======================================================
    // ========== CONTENT ===================================
    // ======================================================

    createContent: builder.mutation<CreateContentMutation, CreateContentMutationVariables>({
      query: (variables) => ({
        graphqlQuery: createContent,
        variables,
      }),
      invalidatesTags: ["ContentListAndBoardView"],
    }),

    updateContent: builder.mutation<UpdateContentMutation, UpdateContentMutationVariables>({
      query: (variables) => ({
        graphqlQuery: updateContent,
        variables,
      }),
    }),

    deleteContent: builder.mutation<DeleteContentMutation, DeleteContentMutationVariables>({
      query: (variables) => ({
        graphqlQuery: deleteContent,
        variables,
      }),
      invalidatesTags: ["ContentBoard", "Calendar", "ContentListAndBoardView"],
    }),

    getContent: builder.query<GetContentQuery, GetContentQueryVariables>({
      query: (variables) => ({
        graphqlQuery: getContent,
        variables,
      }),
    }),

    listContentByBrandId: builder.query<ListContentByBrandIdQuery, ListContentByBrandIdQueryVariables>({
      query: (variables) => ({
        graphqlQuery: listContentByBrandId,
        variables,
      }),
    }),

    listContentByBrandIdAndCreatedAt: builder.query<
      ListContentByBrandIdAndCreatedAtQuery,
      ListContentByBrandIdAndCreatedAtQueryVariables
    >({
      query: (variables) => ({
        graphqlQuery: listContentByBrandIdAndCreatedAt,
        variables,
      }),
      providesTags: ["ContentListAndBoardView"],
    }),

    listContentByBrandIdAndPublishDate: builder.query<
      ListContentByBrandIdAndPublishDateQuery,
      ListContentByBrandIdAndPublishDateQueryVariables
    >({
      query: (variables) => ({
        graphqlQuery: listContentByBrandIdAndPublishDate,
        variables,
      }),
      providesTags: ["Calendar"],
    }),

    listContentByBrandIdAndApprovalStatus: builder.query<
      ListContentByBrandIdAndApprovalStatusQuery,
      ListContentByBrandIdAndApprovalStatusQueryVariables
    >({
      query: (variables) => ({
        graphqlQuery: listContentByBrandIdAndApprovalStatus,
        variables,
      }),
    }),

    listContentByStatusId: builder.query<ListContentByStatusIdQuery, ListContentByStatusIdQueryVariables>({
      query: (variables) => ({
        graphqlQuery: listContentByStatusId,
        variables,
      }),
      transformResponse: (response: ListContentByStatusIdQuery) => {
        return {
          listContentByStatusId: {
            __typename: "ModelContentConnection",
            ...response.listContentByStatusId,
            items: response.listContentByStatusId?.items.filter((content) => content !== null) ?? [],
          },
        };
      },
    }),
  }),
});

export const {
  // ContentStatus
  useCreateContentStatusMutation,
  useUpdateContentStatusMutation,
  useDeleteContentStatusMutation,
  // Content
  useCreateContentMutation,
  useUpdateContentMutation,
  useDeleteContentMutation,
  useGetContentQuery,
  useListContentByBrandIdQuery,
  useLazyListContentByBrandIdQuery,
  useListContentByStatusIdQuery,
  useLazyListContentByStatusIdQuery,
  useListContentByBrandIdAndCreatedAtQuery,
  useLazyListContentByBrandIdAndCreatedAtQuery,
  useListContentByBrandIdAndPublishDateQuery,
  useListContentByBrandIdAndApprovalStatusQuery,
  useLazyListContentByBrandIdAndPublishDateQuery,
} = content;
