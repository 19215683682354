import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Content } from "../../API";
import { BatchMediaPreview } from "../../pages/Planning/BatchInstagramPlanning/BatchMediaBacklog";
import { CalendarEvent } from "../../types/batchPlanning.types";

export const batchPlanningContentAdapter = createEntityAdapter<Content>();

export type BatchPlanningState = {
  events: CalendarEvent[];
  mediaBacklog: BatchMediaPreview[];
};

export const initialBatchPlanningState: BatchPlanningState = {
  events: [],
  mediaBacklog: [],
};

const batchPlanningSlice = createSlice({
  name: "batchPlanning",
  initialState: initialBatchPlanningState,
  reducers: {
    addCalendarEvent: (state, action: PayloadAction<CalendarEvent>) => {
      const event = action.payload;
      state.events = [...state.events, event];
    },

    removeCalendarEvent: (state, action: PayloadAction<string>) => {
      const imageUrl = action.payload;
      const updatedEvents = state.events.filter((event) => event.extendedProps?.imageUrl !== imageUrl);
      state.events = updatedEvents;
    },

    setMedia: (state, action: PayloadAction<BatchMediaPreview[]>) => {
      state.mediaBacklog = action.payload;
    },

    resetBatchPlanning: (state) => {
      state.events = [];
      state.mediaBacklog = [];
    },
  },
});

export const { addCalendarEvent, removeCalendarEvent, setMedia, resetBatchPlanning } = batchPlanningSlice.actions;
export default batchPlanningSlice.reducer;
