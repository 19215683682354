import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React from "react";

type LoadingProps = {
  text?: string;
};

const Loading: React.FC<LoadingProps> = ({ text }) => (
  <Stack sx={{ textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center", marginTop: 5 }}>
    <CircularProgress color="secondary" style={{ margin: "15px 0px" }} />
    <Typography variant="subtitle2" sx={{ padding: 0 }}>
      {text ?? "Loading..."}
    </Typography>
  </Stack>
);

export default Loading;
