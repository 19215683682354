/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createAnalyticsValues = /* GraphQL */ `
  mutation CreateAnalyticsValues(
    $input: CreateAnalyticsValuesInput!
    $condition: ModelAnalyticsValuesConditionInput
  ) {
    createAnalyticsValues(input: $input, condition: $condition) {
      id
      analyticsId
      value
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAnalyticsValues = /* GraphQL */ `
  mutation UpdateAnalyticsValues(
    $input: UpdateAnalyticsValuesInput!
    $condition: ModelAnalyticsValuesConditionInput
  ) {
    updateAnalyticsValues(input: $input, condition: $condition) {
      id
      analyticsId
      value
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAnalyticsValues = /* GraphQL */ `
  mutation DeleteAnalyticsValues(
    $input: DeleteAnalyticsValuesInput!
    $condition: ModelAnalyticsValuesConditionInput
  ) {
    deleteAnalyticsValues(input: $input, condition: $condition) {
      id
      analyticsId
      value
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAnalytics = /* GraphQL */ `
  mutation CreateAnalytics(
    $input: CreateAnalyticsInput!
    $condition: ModelAnalyticsConditionInput
  ) {
    createAnalytics(input: $input, condition: $condition) {
      id
      brandId
      platform
      createdAt
      values {
        id
        analyticsId
        value
        createdAt
        updatedAt
        __typename
      }
      updatedAt
      __typename
    }
  }
`;
export const updateAnalytics = /* GraphQL */ `
  mutation UpdateAnalytics(
    $input: UpdateAnalyticsInput!
    $condition: ModelAnalyticsConditionInput
  ) {
    updateAnalytics(input: $input, condition: $condition) {
      id
      brandId
      platform
      createdAt
      values {
        id
        analyticsId
        value
        createdAt
        updatedAt
        __typename
      }
      updatedAt
      __typename
    }
  }
`;
export const deleteAnalytics = /* GraphQL */ `
  mutation DeleteAnalytics(
    $input: DeleteAnalyticsInput!
    $condition: ModelAnalyticsConditionInput
  ) {
    deleteAnalytics(input: $input, condition: $condition) {
      id
      brandId
      platform
      createdAt
      values {
        id
        analyticsId
        value
        createdAt
        updatedAt
        __typename
      }
      updatedAt
      __typename
    }
  }
`;
export const createBrand = /* GraphQL */ `
  mutation CreateBrand(
    $input: CreateBrandInput!
    $condition: ModelBrandConditionInput
  ) {
    createBrand(input: $input, condition: $condition) {
      id
      name
      connections {
        id
        brandId
        youtubeChannelName
        youtubeChannelId
        youtubeUserId
        youtubeChannelPictureUrl
        tiktok
        instagramUserId
        instagramUsername
        instagramProfilePictureUrl
        instagramTokenId
        facebookPageId
        facebookPageName
        facebookPagePictureUrl
        facebookTokenId
        createdAt
        updatedAt
        __typename
      }
      connectionId
      categories
      status {
        items {
          id
          brandId
          content {
            items {
              id
              assignedMemberId
              brandId
              categories
              createdAt
              createdBy
              contentScheduleId
              dueDate
              publishDate
              published
              statusId
              markdown
              approvalStatus
              title
              description
              caption
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          contentIds
          name
          stage
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      statusOrder
      brandProfileUrl {
        fileName
        objectKey
        size
        dateUploaded
        uploadedBy
        type
        customPrefix
        blurhash
        __typename
      }
      goals {
        platform
        start
        goal
        __typename
      }
      mission
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateBrand = /* GraphQL */ `
  mutation UpdateBrand(
    $input: UpdateBrandInput!
    $condition: ModelBrandConditionInput
  ) {
    updateBrand(input: $input, condition: $condition) {
      id
      name
      connections {
        id
        brandId
        youtubeChannelName
        youtubeChannelId
        youtubeUserId
        youtubeChannelPictureUrl
        tiktok
        instagramUserId
        instagramUsername
        instagramProfilePictureUrl
        instagramTokenId
        facebookPageId
        facebookPageName
        facebookPagePictureUrl
        facebookTokenId
        createdAt
        updatedAt
        __typename
      }
      connectionId
      categories
      status {
        items {
          id
          brandId
          content {
            items {
              id
              assignedMemberId
              brandId
              categories
              createdAt
              createdBy
              contentScheduleId
              dueDate
              publishDate
              published
              statusId
              markdown
              approvalStatus
              title
              description
              caption
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          contentIds
          name
          stage
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      statusOrder
      brandProfileUrl {
        fileName
        objectKey
        size
        dateUploaded
        uploadedBy
        type
        customPrefix
        blurhash
        __typename
      }
      goals {
        platform
        start
        goal
        __typename
      }
      mission
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteBrand = /* GraphQL */ `
  mutation DeleteBrand(
    $input: DeleteBrandInput!
    $condition: ModelBrandConditionInput
  ) {
    deleteBrand(input: $input, condition: $condition) {
      id
      name
      connections {
        id
        brandId
        youtubeChannelName
        youtubeChannelId
        youtubeUserId
        youtubeChannelPictureUrl
        tiktok
        instagramUserId
        instagramUsername
        instagramProfilePictureUrl
        instagramTokenId
        facebookPageId
        facebookPageName
        facebookPagePictureUrl
        facebookTokenId
        createdAt
        updatedAt
        __typename
      }
      connectionId
      categories
      status {
        items {
          id
          brandId
          content {
            items {
              id
              assignedMemberId
              brandId
              categories
              createdAt
              createdBy
              contentScheduleId
              dueDate
              publishDate
              published
              statusId
              markdown
              approvalStatus
              title
              description
              caption
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          contentIds
          name
          stage
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      statusOrder
      brandProfileUrl {
        fileName
        objectKey
        size
        dateUploaded
        uploadedBy
        type
        customPrefix
        blurhash
        __typename
      }
      goals {
        platform
        start
        goal
        __typename
      }
      mission
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createBrandMember = /* GraphQL */ `
  mutation CreateBrandMember(
    $input: CreateBrandMemberInput!
    $condition: ModelBrandMemberConditionInput
  ) {
    createBrandMember(input: $input, condition: $condition) {
      id
      member {
        id
        name
        email
        brandMembers {
          items {
            id
            member {
              id
              name
              email
              subscription
              stripeCustomerId
              lastLogin
              createdAt
              updatedAt
              __typename
            }
            memberId
            brandId
            brand {
              id
              name
              connectionId
              categories
              statusOrder
              mission
              createdAt
              updatedAt
              __typename
            }
            accessLevel
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        subscription
        stripeCustomerId
        lastLogin
        createdAt
        updatedAt
        __typename
      }
      memberId
      brandId
      brand {
        id
        name
        connections {
          id
          brandId
          youtubeChannelName
          youtubeChannelId
          youtubeUserId
          youtubeChannelPictureUrl
          tiktok
          instagramUserId
          instagramUsername
          instagramProfilePictureUrl
          instagramTokenId
          facebookPageId
          facebookPageName
          facebookPagePictureUrl
          facebookTokenId
          createdAt
          updatedAt
          __typename
        }
        connectionId
        categories
        status {
          items {
            id
            brandId
            content {
              nextToken
              __typename
            }
            contentIds
            name
            stage
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        statusOrder
        brandProfileUrl {
          fileName
          objectKey
          size
          dateUploaded
          uploadedBy
          type
          customPrefix
          blurhash
          __typename
        }
        goals {
          platform
          start
          goal
          __typename
        }
        mission
        createdAt
        updatedAt
        __typename
      }
      accessLevel
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateBrandMember = /* GraphQL */ `
  mutation UpdateBrandMember(
    $input: UpdateBrandMemberInput!
    $condition: ModelBrandMemberConditionInput
  ) {
    updateBrandMember(input: $input, condition: $condition) {
      id
      member {
        id
        name
        email
        brandMembers {
          items {
            id
            member {
              id
              name
              email
              subscription
              stripeCustomerId
              lastLogin
              createdAt
              updatedAt
              __typename
            }
            memberId
            brandId
            brand {
              id
              name
              connectionId
              categories
              statusOrder
              mission
              createdAt
              updatedAt
              __typename
            }
            accessLevel
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        subscription
        stripeCustomerId
        lastLogin
        createdAt
        updatedAt
        __typename
      }
      memberId
      brandId
      brand {
        id
        name
        connections {
          id
          brandId
          youtubeChannelName
          youtubeChannelId
          youtubeUserId
          youtubeChannelPictureUrl
          tiktok
          instagramUserId
          instagramUsername
          instagramProfilePictureUrl
          instagramTokenId
          facebookPageId
          facebookPageName
          facebookPagePictureUrl
          facebookTokenId
          createdAt
          updatedAt
          __typename
        }
        connectionId
        categories
        status {
          items {
            id
            brandId
            content {
              nextToken
              __typename
            }
            contentIds
            name
            stage
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        statusOrder
        brandProfileUrl {
          fileName
          objectKey
          size
          dateUploaded
          uploadedBy
          type
          customPrefix
          blurhash
          __typename
        }
        goals {
          platform
          start
          goal
          __typename
        }
        mission
        createdAt
        updatedAt
        __typename
      }
      accessLevel
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteBrandMember = /* GraphQL */ `
  mutation DeleteBrandMember(
    $input: DeleteBrandMemberInput!
    $condition: ModelBrandMemberConditionInput
  ) {
    deleteBrandMember(input: $input, condition: $condition) {
      id
      member {
        id
        name
        email
        brandMembers {
          items {
            id
            member {
              id
              name
              email
              subscription
              stripeCustomerId
              lastLogin
              createdAt
              updatedAt
              __typename
            }
            memberId
            brandId
            brand {
              id
              name
              connectionId
              categories
              statusOrder
              mission
              createdAt
              updatedAt
              __typename
            }
            accessLevel
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        subscription
        stripeCustomerId
        lastLogin
        createdAt
        updatedAt
        __typename
      }
      memberId
      brandId
      brand {
        id
        name
        connections {
          id
          brandId
          youtubeChannelName
          youtubeChannelId
          youtubeUserId
          youtubeChannelPictureUrl
          tiktok
          instagramUserId
          instagramUsername
          instagramProfilePictureUrl
          instagramTokenId
          facebookPageId
          facebookPageName
          facebookPagePictureUrl
          facebookTokenId
          createdAt
          updatedAt
          __typename
        }
        connectionId
        categories
        status {
          items {
            id
            brandId
            content {
              nextToken
              __typename
            }
            contentIds
            name
            stage
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        statusOrder
        brandProfileUrl {
          fileName
          objectKey
          size
          dateUploaded
          uploadedBy
          type
          customPrefix
          blurhash
          __typename
        }
        goals {
          platform
          start
          goal
          __typename
        }
        mission
        createdAt
        updatedAt
        __typename
      }
      accessLevel
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createConnections = /* GraphQL */ `
  mutation CreateConnections(
    $input: CreateConnectionsInput!
    $condition: ModelConnectionsConditionInput
  ) {
    createConnections(input: $input, condition: $condition) {
      id
      brandId
      youtubeChannelName
      youtubeChannelId
      youtubeUserId
      youtubeChannelPictureUrl
      tiktok
      instagramUserId
      instagramUsername
      instagramProfilePictureUrl
      instagramTokenId
      facebookPageId
      facebookPageName
      facebookPagePictureUrl
      facebookTokenId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateConnections = /* GraphQL */ `
  mutation UpdateConnections(
    $input: UpdateConnectionsInput!
    $condition: ModelConnectionsConditionInput
  ) {
    updateConnections(input: $input, condition: $condition) {
      id
      brandId
      youtubeChannelName
      youtubeChannelId
      youtubeUserId
      youtubeChannelPictureUrl
      tiktok
      instagramUserId
      instagramUsername
      instagramProfilePictureUrl
      instagramTokenId
      facebookPageId
      facebookPageName
      facebookPagePictureUrl
      facebookTokenId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteConnections = /* GraphQL */ `
  mutation DeleteConnections(
    $input: DeleteConnectionsInput!
    $condition: ModelConnectionsConditionInput
  ) {
    deleteConnections(input: $input, condition: $condition) {
      id
      brandId
      youtubeChannelName
      youtubeChannelId
      youtubeUserId
      youtubeChannelPictureUrl
      tiktok
      instagramUserId
      instagramUsername
      instagramProfilePictureUrl
      instagramTokenId
      facebookPageId
      facebookPageName
      facebookPagePictureUrl
      facebookTokenId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createContentStatus = /* GraphQL */ `
  mutation CreateContentStatus(
    $input: CreateContentStatusInput!
    $condition: ModelContentStatusConditionInput
  ) {
    createContentStatus(input: $input, condition: $condition) {
      id
      brandId
      content {
        items {
          id
          assignedTo {
            id
            name
            email
            brandMembers {
              nextToken
              __typename
            }
            subscription
            stripeCustomerId
            lastLogin
            createdAt
            updatedAt
            __typename
          }
          assignedMemberId
          brandId
          categories
          createdAt
          createdBy
          contentScheduleId
          dueDate
          media {
            fileName
            objectKey
            size
            dateUploaded
            uploadedBy
            type
            customPrefix
            blurhash
            __typename
          }
          platforms {
            platform
            status
            postUrl
            publishDate
            __typename
          }
          publishDate
          published
          status {
            id
            brandId
            content {
              nextToken
              __typename
            }
            contentIds
            name
            stage
            createdAt
            updatedAt
            __typename
          }
          statusId
          markdown
          comments {
            items {
              id
              createdAt
              contentId
              message
              memberId
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          checklist {
            id
            title
            completed
            __typename
          }
          approvalStatus
          thumbnail {
            fileName
            objectKey
            size
            dateUploaded
            uploadedBy
            type
            customPrefix
            blurhash
            __typename
          }
          title
          description
          caption
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contentIds
      name
      stage
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateContentStatus = /* GraphQL */ `
  mutation UpdateContentStatus(
    $input: UpdateContentStatusInput!
    $condition: ModelContentStatusConditionInput
  ) {
    updateContentStatus(input: $input, condition: $condition) {
      id
      brandId
      content {
        items {
          id
          assignedTo {
            id
            name
            email
            brandMembers {
              nextToken
              __typename
            }
            subscription
            stripeCustomerId
            lastLogin
            createdAt
            updatedAt
            __typename
          }
          assignedMemberId
          brandId
          categories
          createdAt
          createdBy
          contentScheduleId
          dueDate
          media {
            fileName
            objectKey
            size
            dateUploaded
            uploadedBy
            type
            customPrefix
            blurhash
            __typename
          }
          platforms {
            platform
            status
            postUrl
            publishDate
            __typename
          }
          publishDate
          published
          status {
            id
            brandId
            content {
              nextToken
              __typename
            }
            contentIds
            name
            stage
            createdAt
            updatedAt
            __typename
          }
          statusId
          markdown
          comments {
            items {
              id
              createdAt
              contentId
              message
              memberId
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          checklist {
            id
            title
            completed
            __typename
          }
          approvalStatus
          thumbnail {
            fileName
            objectKey
            size
            dateUploaded
            uploadedBy
            type
            customPrefix
            blurhash
            __typename
          }
          title
          description
          caption
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contentIds
      name
      stage
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteContentStatus = /* GraphQL */ `
  mutation DeleteContentStatus(
    $input: DeleteContentStatusInput!
    $condition: ModelContentStatusConditionInput
  ) {
    deleteContentStatus(input: $input, condition: $condition) {
      id
      brandId
      content {
        items {
          id
          assignedTo {
            id
            name
            email
            brandMembers {
              nextToken
              __typename
            }
            subscription
            stripeCustomerId
            lastLogin
            createdAt
            updatedAt
            __typename
          }
          assignedMemberId
          brandId
          categories
          createdAt
          createdBy
          contentScheduleId
          dueDate
          media {
            fileName
            objectKey
            size
            dateUploaded
            uploadedBy
            type
            customPrefix
            blurhash
            __typename
          }
          platforms {
            platform
            status
            postUrl
            publishDate
            __typename
          }
          publishDate
          published
          status {
            id
            brandId
            content {
              nextToken
              __typename
            }
            contentIds
            name
            stage
            createdAt
            updatedAt
            __typename
          }
          statusId
          markdown
          comments {
            items {
              id
              createdAt
              contentId
              message
              memberId
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          checklist {
            id
            title
            completed
            __typename
          }
          approvalStatus
          thumbnail {
            fileName
            objectKey
            size
            dateUploaded
            uploadedBy
            type
            customPrefix
            blurhash
            __typename
          }
          title
          description
          caption
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contentIds
      name
      stage
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createContent = /* GraphQL */ `
  mutation CreateContent(
    $input: CreateContentInput!
    $condition: ModelContentConditionInput
  ) {
    createContent(input: $input, condition: $condition) {
      id
      assignedTo {
        id
        name
        email
        brandMembers {
          items {
            id
            member {
              id
              name
              email
              subscription
              stripeCustomerId
              lastLogin
              createdAt
              updatedAt
              __typename
            }
            memberId
            brandId
            brand {
              id
              name
              connectionId
              categories
              statusOrder
              mission
              createdAt
              updatedAt
              __typename
            }
            accessLevel
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        subscription
        stripeCustomerId
        lastLogin
        createdAt
        updatedAt
        __typename
      }
      assignedMemberId
      brandId
      categories
      createdAt
      createdBy
      contentScheduleId
      dueDate
      media {
        fileName
        objectKey
        size
        dateUploaded
        uploadedBy
        type
        customPrefix
        blurhash
        __typename
      }
      platforms {
        platform
        status
        postUrl
        publishDate
        __typename
      }
      publishDate
      published
      status {
        id
        brandId
        content {
          items {
            id
            assignedTo {
              id
              name
              email
              subscription
              stripeCustomerId
              lastLogin
              createdAt
              updatedAt
              __typename
            }
            assignedMemberId
            brandId
            categories
            createdAt
            createdBy
            contentScheduleId
            dueDate
            media {
              fileName
              objectKey
              size
              dateUploaded
              uploadedBy
              type
              customPrefix
              blurhash
              __typename
            }
            platforms {
              platform
              status
              postUrl
              publishDate
              __typename
            }
            publishDate
            published
            status {
              id
              brandId
              contentIds
              name
              stage
              createdAt
              updatedAt
              __typename
            }
            statusId
            markdown
            comments {
              nextToken
              __typename
            }
            checklist {
              id
              title
              completed
              __typename
            }
            approvalStatus
            thumbnail {
              fileName
              objectKey
              size
              dateUploaded
              uploadedBy
              type
              customPrefix
              blurhash
              __typename
            }
            title
            description
            caption
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        contentIds
        name
        stage
        createdAt
        updatedAt
        __typename
      }
      statusId
      markdown
      comments {
        items {
          id
          createdAt
          contentId
          message
          memberId
          member {
            id
            name
            email
            brandMembers {
              nextToken
              __typename
            }
            subscription
            stripeCustomerId
            lastLogin
            createdAt
            updatedAt
            __typename
          }
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      checklist {
        id
        title
        completed
        __typename
      }
      approvalStatus
      thumbnail {
        fileName
        objectKey
        size
        dateUploaded
        uploadedBy
        type
        customPrefix
        blurhash
        __typename
      }
      title
      description
      caption
      updatedAt
      __typename
    }
  }
`;
export const updateContent = /* GraphQL */ `
  mutation UpdateContent(
    $input: UpdateContentInput!
    $condition: ModelContentConditionInput
  ) {
    updateContent(input: $input, condition: $condition) {
      id
      assignedTo {
        id
        name
        email
        brandMembers {
          items {
            id
            member {
              id
              name
              email
              subscription
              stripeCustomerId
              lastLogin
              createdAt
              updatedAt
              __typename
            }
            memberId
            brandId
            brand {
              id
              name
              connectionId
              categories
              statusOrder
              mission
              createdAt
              updatedAt
              __typename
            }
            accessLevel
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        subscription
        stripeCustomerId
        lastLogin
        createdAt
        updatedAt
        __typename
      }
      assignedMemberId
      brandId
      categories
      createdAt
      createdBy
      contentScheduleId
      dueDate
      media {
        fileName
        objectKey
        size
        dateUploaded
        uploadedBy
        type
        customPrefix
        blurhash
        __typename
      }
      platforms {
        platform
        status
        postUrl
        publishDate
        __typename
      }
      publishDate
      published
      status {
        id
        brandId
        content {
          items {
            id
            assignedTo {
              id
              name
              email
              subscription
              stripeCustomerId
              lastLogin
              createdAt
              updatedAt
              __typename
            }
            assignedMemberId
            brandId
            categories
            createdAt
            createdBy
            contentScheduleId
            dueDate
            media {
              fileName
              objectKey
              size
              dateUploaded
              uploadedBy
              type
              customPrefix
              blurhash
              __typename
            }
            platforms {
              platform
              status
              postUrl
              publishDate
              __typename
            }
            publishDate
            published
            status {
              id
              brandId
              contentIds
              name
              stage
              createdAt
              updatedAt
              __typename
            }
            statusId
            markdown
            comments {
              nextToken
              __typename
            }
            checklist {
              id
              title
              completed
              __typename
            }
            approvalStatus
            thumbnail {
              fileName
              objectKey
              size
              dateUploaded
              uploadedBy
              type
              customPrefix
              blurhash
              __typename
            }
            title
            description
            caption
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        contentIds
        name
        stage
        createdAt
        updatedAt
        __typename
      }
      statusId
      markdown
      comments {
        items {
          id
          createdAt
          contentId
          message
          memberId
          member {
            id
            name
            email
            brandMembers {
              nextToken
              __typename
            }
            subscription
            stripeCustomerId
            lastLogin
            createdAt
            updatedAt
            __typename
          }
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      checklist {
        id
        title
        completed
        __typename
      }
      approvalStatus
      thumbnail {
        fileName
        objectKey
        size
        dateUploaded
        uploadedBy
        type
        customPrefix
        blurhash
        __typename
      }
      title
      description
      caption
      updatedAt
      __typename
    }
  }
`;
export const deleteContent = /* GraphQL */ `
  mutation DeleteContent(
    $input: DeleteContentInput!
    $condition: ModelContentConditionInput
  ) {
    deleteContent(input: $input, condition: $condition) {
      id
      assignedTo {
        id
        name
        email
        brandMembers {
          items {
            id
            member {
              id
              name
              email
              subscription
              stripeCustomerId
              lastLogin
              createdAt
              updatedAt
              __typename
            }
            memberId
            brandId
            brand {
              id
              name
              connectionId
              categories
              statusOrder
              mission
              createdAt
              updatedAt
              __typename
            }
            accessLevel
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        subscription
        stripeCustomerId
        lastLogin
        createdAt
        updatedAt
        __typename
      }
      assignedMemberId
      brandId
      categories
      createdAt
      createdBy
      contentScheduleId
      dueDate
      media {
        fileName
        objectKey
        size
        dateUploaded
        uploadedBy
        type
        customPrefix
        blurhash
        __typename
      }
      platforms {
        platform
        status
        postUrl
        publishDate
        __typename
      }
      publishDate
      published
      status {
        id
        brandId
        content {
          items {
            id
            assignedTo {
              id
              name
              email
              subscription
              stripeCustomerId
              lastLogin
              createdAt
              updatedAt
              __typename
            }
            assignedMemberId
            brandId
            categories
            createdAt
            createdBy
            contentScheduleId
            dueDate
            media {
              fileName
              objectKey
              size
              dateUploaded
              uploadedBy
              type
              customPrefix
              blurhash
              __typename
            }
            platforms {
              platform
              status
              postUrl
              publishDate
              __typename
            }
            publishDate
            published
            status {
              id
              brandId
              contentIds
              name
              stage
              createdAt
              updatedAt
              __typename
            }
            statusId
            markdown
            comments {
              nextToken
              __typename
            }
            checklist {
              id
              title
              completed
              __typename
            }
            approvalStatus
            thumbnail {
              fileName
              objectKey
              size
              dateUploaded
              uploadedBy
              type
              customPrefix
              blurhash
              __typename
            }
            title
            description
            caption
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        contentIds
        name
        stage
        createdAt
        updatedAt
        __typename
      }
      statusId
      markdown
      comments {
        items {
          id
          createdAt
          contentId
          message
          memberId
          member {
            id
            name
            email
            brandMembers {
              nextToken
              __typename
            }
            subscription
            stripeCustomerId
            lastLogin
            createdAt
            updatedAt
            __typename
          }
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      checklist {
        id
        title
        completed
        __typename
      }
      approvalStatus
      thumbnail {
        fileName
        objectKey
        size
        dateUploaded
        uploadedBy
        type
        customPrefix
        blurhash
        __typename
      }
      title
      description
      caption
      updatedAt
      __typename
    }
  }
`;
export const createContentCategory = /* GraphQL */ `
  mutation CreateContentCategory(
    $input: CreateContentCategoryInput!
    $condition: ModelContentCategoryConditionInput
  ) {
    createContentCategory(input: $input, condition: $condition) {
      id
      contentId
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateContentCategory = /* GraphQL */ `
  mutation UpdateContentCategory(
    $input: UpdateContentCategoryInput!
    $condition: ModelContentCategoryConditionInput
  ) {
    updateContentCategory(input: $input, condition: $condition) {
      id
      contentId
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteContentCategory = /* GraphQL */ `
  mutation DeleteContentCategory(
    $input: DeleteContentCategoryInput!
    $condition: ModelContentCategoryConditionInput
  ) {
    deleteContentCategory(input: $input, condition: $condition) {
      id
      contentId
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createComment = /* GraphQL */ `
  mutation CreateComment(
    $input: CreateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    createComment(input: $input, condition: $condition) {
      id
      createdAt
      contentId
      message
      memberId
      member {
        id
        name
        email
        brandMembers {
          items {
            id
            member {
              id
              name
              email
              subscription
              stripeCustomerId
              lastLogin
              createdAt
              updatedAt
              __typename
            }
            memberId
            brandId
            brand {
              id
              name
              connectionId
              categories
              statusOrder
              mission
              createdAt
              updatedAt
              __typename
            }
            accessLevel
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        subscription
        stripeCustomerId
        lastLogin
        createdAt
        updatedAt
        __typename
      }
      updatedAt
      __typename
    }
  }
`;
export const updateComment = /* GraphQL */ `
  mutation UpdateComment(
    $input: UpdateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    updateComment(input: $input, condition: $condition) {
      id
      createdAt
      contentId
      message
      memberId
      member {
        id
        name
        email
        brandMembers {
          items {
            id
            member {
              id
              name
              email
              subscription
              stripeCustomerId
              lastLogin
              createdAt
              updatedAt
              __typename
            }
            memberId
            brandId
            brand {
              id
              name
              connectionId
              categories
              statusOrder
              mission
              createdAt
              updatedAt
              __typename
            }
            accessLevel
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        subscription
        stripeCustomerId
        lastLogin
        createdAt
        updatedAt
        __typename
      }
      updatedAt
      __typename
    }
  }
`;
export const deleteComment = /* GraphQL */ `
  mutation DeleteComment(
    $input: DeleteCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    deleteComment(input: $input, condition: $condition) {
      id
      createdAt
      contentId
      message
      memberId
      member {
        id
        name
        email
        brandMembers {
          items {
            id
            member {
              id
              name
              email
              subscription
              stripeCustomerId
              lastLogin
              createdAt
              updatedAt
              __typename
            }
            memberId
            brandId
            brand {
              id
              name
              connectionId
              categories
              statusOrder
              mission
              createdAt
              updatedAt
              __typename
            }
            accessLevel
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        subscription
        stripeCustomerId
        lastLogin
        createdAt
        updatedAt
        __typename
      }
      updatedAt
      __typename
    }
  }
`;
export const createContentSchedule = /* GraphQL */ `
  mutation CreateContentSchedule(
    $input: CreateContentScheduleInput!
    $condition: ModelContentScheduleConditionInput
  ) {
    createContentSchedule(input: $input, condition: $condition) {
      id
      publishDate
      status
      contentId
      brandId
      platformData {
        platform
        accountId
        tokenId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateContentSchedule = /* GraphQL */ `
  mutation UpdateContentSchedule(
    $input: UpdateContentScheduleInput!
    $condition: ModelContentScheduleConditionInput
  ) {
    updateContentSchedule(input: $input, condition: $condition) {
      id
      publishDate
      status
      contentId
      brandId
      platformData {
        platform
        accountId
        tokenId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteContentSchedule = /* GraphQL */ `
  mutation DeleteContentSchedule(
    $input: DeleteContentScheduleInput!
    $condition: ModelContentScheduleConditionInput
  ) {
    deleteContentSchedule(input: $input, condition: $condition) {
      id
      publishDate
      status
      contentId
      brandId
      platformData {
        platform
        accountId
        tokenId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createMember = /* GraphQL */ `
  mutation CreateMember(
    $input: CreateMemberInput!
    $condition: ModelMemberConditionInput
  ) {
    createMember(input: $input, condition: $condition) {
      id
      name
      email
      brandMembers {
        items {
          id
          member {
            id
            name
            email
            brandMembers {
              nextToken
              __typename
            }
            subscription
            stripeCustomerId
            lastLogin
            createdAt
            updatedAt
            __typename
          }
          memberId
          brandId
          brand {
            id
            name
            connections {
              id
              brandId
              youtubeChannelName
              youtubeChannelId
              youtubeUserId
              youtubeChannelPictureUrl
              tiktok
              instagramUserId
              instagramUsername
              instagramProfilePictureUrl
              instagramTokenId
              facebookPageId
              facebookPageName
              facebookPagePictureUrl
              facebookTokenId
              createdAt
              updatedAt
              __typename
            }
            connectionId
            categories
            status {
              nextToken
              __typename
            }
            statusOrder
            brandProfileUrl {
              fileName
              objectKey
              size
              dateUploaded
              uploadedBy
              type
              customPrefix
              blurhash
              __typename
            }
            goals {
              platform
              start
              goal
              __typename
            }
            mission
            createdAt
            updatedAt
            __typename
          }
          accessLevel
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      subscription
      stripeCustomerId
      lastLogin
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateMember = /* GraphQL */ `
  mutation UpdateMember(
    $input: UpdateMemberInput!
    $condition: ModelMemberConditionInput
  ) {
    updateMember(input: $input, condition: $condition) {
      id
      name
      email
      brandMembers {
        items {
          id
          member {
            id
            name
            email
            brandMembers {
              nextToken
              __typename
            }
            subscription
            stripeCustomerId
            lastLogin
            createdAt
            updatedAt
            __typename
          }
          memberId
          brandId
          brand {
            id
            name
            connections {
              id
              brandId
              youtubeChannelName
              youtubeChannelId
              youtubeUserId
              youtubeChannelPictureUrl
              tiktok
              instagramUserId
              instagramUsername
              instagramProfilePictureUrl
              instagramTokenId
              facebookPageId
              facebookPageName
              facebookPagePictureUrl
              facebookTokenId
              createdAt
              updatedAt
              __typename
            }
            connectionId
            categories
            status {
              nextToken
              __typename
            }
            statusOrder
            brandProfileUrl {
              fileName
              objectKey
              size
              dateUploaded
              uploadedBy
              type
              customPrefix
              blurhash
              __typename
            }
            goals {
              platform
              start
              goal
              __typename
            }
            mission
            createdAt
            updatedAt
            __typename
          }
          accessLevel
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      subscription
      stripeCustomerId
      lastLogin
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteMember = /* GraphQL */ `
  mutation DeleteMember(
    $input: DeleteMemberInput!
    $condition: ModelMemberConditionInput
  ) {
    deleteMember(input: $input, condition: $condition) {
      id
      name
      email
      brandMembers {
        items {
          id
          member {
            id
            name
            email
            brandMembers {
              nextToken
              __typename
            }
            subscription
            stripeCustomerId
            lastLogin
            createdAt
            updatedAt
            __typename
          }
          memberId
          brandId
          brand {
            id
            name
            connections {
              id
              brandId
              youtubeChannelName
              youtubeChannelId
              youtubeUserId
              youtubeChannelPictureUrl
              tiktok
              instagramUserId
              instagramUsername
              instagramProfilePictureUrl
              instagramTokenId
              facebookPageId
              facebookPageName
              facebookPagePictureUrl
              facebookTokenId
              createdAt
              updatedAt
              __typename
            }
            connectionId
            categories
            status {
              nextToken
              __typename
            }
            statusOrder
            brandProfileUrl {
              fileName
              objectKey
              size
              dateUploaded
              uploadedBy
              type
              customPrefix
              blurhash
              __typename
            }
            goals {
              platform
              start
              goal
              __typename
            }
            mission
            createdAt
            updatedAt
            __typename
          }
          accessLevel
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      subscription
      stripeCustomerId
      lastLogin
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createToken = /* GraphQL */ `
  mutation CreateToken(
    $input: CreateTokenInput!
    $condition: ModelTokenConditionInput
  ) {
    createToken(input: $input, condition: $condition) {
      id
      createdAt
      brandId
      accessToken
      refreshToken
      tokenType
      expiryDate
      platform
      updatedAt
      __typename
    }
  }
`;
export const updateToken = /* GraphQL */ `
  mutation UpdateToken(
    $input: UpdateTokenInput!
    $condition: ModelTokenConditionInput
  ) {
    updateToken(input: $input, condition: $condition) {
      id
      createdAt
      brandId
      accessToken
      refreshToken
      tokenType
      expiryDate
      platform
      updatedAt
      __typename
    }
  }
`;
export const deleteToken = /* GraphQL */ `
  mutation DeleteToken(
    $input: DeleteTokenInput!
    $condition: ModelTokenConditionInput
  ) {
    deleteToken(input: $input, condition: $condition) {
      id
      createdAt
      brandId
      accessToken
      refreshToken
      tokenType
      expiryDate
      platform
      updatedAt
      __typename
    }
  }
`;
