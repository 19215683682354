import {
  CreateBrandMemberMutation,
  CreateBrandMemberMutationVariables,
  CreateMemberMutation,
  CreateMemberMutationVariables,
  DeleteMemberMutation,
  DeleteMemberMutationVariables,
  GetBrandMemberQuery,
  GetBrandMemberQueryVariables,
  GetMemberQuery,
  GetMemberQueryVariables,
  ListMembersByEmailQuery,
  ListMembersByEmailQueryVariables,
  UpdateMemberMutation,
  UpdateMemberMutationVariables,
} from "../API";
import { createBrandMember, createMember, deleteMember, updateMember } from "../graphql/mutations";
import { getBrandMember, getMember, listMembersByEmail } from "../graphql/queries";
import { rootApi } from "./rootApi";

export const member = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    // ======================================================
    // ========== MEMBER ====================================
    // ======================================================

    createMember: builder.mutation<CreateMemberMutation, CreateMemberMutationVariables>({
      query: (variables) => ({
        graphqlQuery: createMember,
        variables,
      }),
    }),

    updateMember: builder.mutation<UpdateMemberMutation, UpdateMemberMutationVariables>({
      query: (variables) => ({
        graphqlQuery: updateMember,
        variables,
      }),
    }),

    getMember: builder.query<GetMemberQuery, GetMemberQueryVariables>({
      query: (variables) => ({
        graphqlQuery: getMember,
        variables,
      }),
      providesTags: ["Member"],
    }),

    listMemberByEmail: builder.query<ListMembersByEmailQuery, ListMembersByEmailQueryVariables>({
      query: (variables) => ({
        graphqlQuery: listMembersByEmail,
        variables,
      }),
    }),

    deleteMember: builder.mutation<DeleteMemberMutation, DeleteMemberMutationVariables>({
      query: (variables) => ({
        graphqlQuery: deleteMember,
        variables,
      }),
      invalidatesTags: ["Member"],
    }),

    // ======================================================
    // ========== BRAND MEMBER ==============================
    // ======================================================

    createBrandMember: builder.mutation<CreateBrandMemberMutation, CreateBrandMemberMutationVariables>({
      query: (variables) => ({
        graphqlQuery: createBrandMember,
        variables,
      }),
    }),

    getBrandMember: builder.query<GetBrandMemberQuery, GetBrandMemberQueryVariables>({
      query: (variables) => ({
        graphqlQuery: getBrandMember,
        variables,
      }),
    }),
  }),
});

export const {
  useCreateMemberMutation,
  useUpdateMemberMutation,
  useGetMemberQuery,
  useDeleteMemberMutation,
  useLazyGetMemberQuery,
  useCreateBrandMemberMutation,
  useLazyGetBrandMemberQuery,
  useLazyListMemberByEmailQuery,
} = member;
