import "./index.css";

import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

// TODO: Add strict mode again
root.render(<App />);

reportWebVitals();
