/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAnalyticsValues = /* GraphQL */ `
  query GetAnalyticsValues($id: ID!) {
    getAnalyticsValues(id: $id) {
      id
      analyticsId
      value
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAnalyticsValues = /* GraphQL */ `
  query ListAnalyticsValues(
    $id: ID
    $filter: ModelAnalyticsValuesFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAnalyticsValues(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        analyticsId
        value
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAnalytics = /* GraphQL */ `
  query GetAnalytics($id: ID!) {
    getAnalytics(id: $id) {
      id
      brandId
      platform
      createdAt
      values {
        id
        analyticsId
        value
        createdAt
        updatedAt
        __typename
      }
      updatedAt
      __typename
    }
  }
`;
export const listAnalytics = /* GraphQL */ `
  query ListAnalytics(
    $id: ID
    $filter: ModelAnalyticsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAnalytics(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        brandId
        platform
        createdAt
        values {
          id
          analyticsId
          value
          createdAt
          updatedAt
          __typename
        }
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getBrand = /* GraphQL */ `
  query GetBrand($id: ID!) {
    getBrand(id: $id) {
      id
      name
      connections {
        id
        brandId
        youtubeChannelName
        youtubeChannelId
        youtubeUserId
        youtubeChannelPictureUrl
        tiktok
        instagramUserId
        instagramUsername
        instagramProfilePictureUrl
        instagramTokenId
        facebookPageId
        facebookPageName
        facebookPagePictureUrl
        facebookTokenId
        createdAt
        updatedAt
        __typename
      }
      connectionId
      categories
      status {
        items {
          id
          brandId
          content {
            items {
              id
              assignedMemberId
              brandId
              categories
              createdAt
              createdBy
              contentScheduleId
              dueDate
              publishDate
              published
              statusId
              markdown
              approvalStatus
              title
              description
              caption
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          contentIds
          name
          stage
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      statusOrder
      brandProfileUrl {
        fileName
        objectKey
        size
        dateUploaded
        uploadedBy
        type
        customPrefix
        blurhash
        __typename
      }
      goals {
        platform
        start
        goal
        __typename
      }
      mission
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listBrands = /* GraphQL */ `
  query ListBrands(
    $id: ID
    $filter: ModelBrandFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listBrands(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        connections {
          id
          brandId
          youtubeChannelName
          youtubeChannelId
          youtubeUserId
          youtubeChannelPictureUrl
          tiktok
          instagramUserId
          instagramUsername
          instagramProfilePictureUrl
          instagramTokenId
          facebookPageId
          facebookPageName
          facebookPagePictureUrl
          facebookTokenId
          createdAt
          updatedAt
          __typename
        }
        connectionId
        categories
        status {
          items {
            id
            brandId
            content {
              nextToken
              __typename
            }
            contentIds
            name
            stage
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        statusOrder
        brandProfileUrl {
          fileName
          objectKey
          size
          dateUploaded
          uploadedBy
          type
          customPrefix
          blurhash
          __typename
        }
        goals {
          platform
          start
          goal
          __typename
        }
        mission
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getBrandMember = /* GraphQL */ `
  query GetBrandMember($id: ID!) {
    getBrandMember(id: $id) {
      id
      member {
        id
        name
        email
        brandMembers {
          items {
            id
            member {
              id
              name
              email
              subscription
              stripeCustomerId
              lastLogin
              createdAt
              updatedAt
              __typename
            }
            memberId
            brandId
            brand {
              id
              name
              connectionId
              categories
              statusOrder
              mission
              createdAt
              updatedAt
              __typename
            }
            accessLevel
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        subscription
        stripeCustomerId
        lastLogin
        createdAt
        updatedAt
        __typename
      }
      memberId
      brandId
      brand {
        id
        name
        connections {
          id
          brandId
          youtubeChannelName
          youtubeChannelId
          youtubeUserId
          youtubeChannelPictureUrl
          tiktok
          instagramUserId
          instagramUsername
          instagramProfilePictureUrl
          instagramTokenId
          facebookPageId
          facebookPageName
          facebookPagePictureUrl
          facebookTokenId
          createdAt
          updatedAt
          __typename
        }
        connectionId
        categories
        status {
          items {
            id
            brandId
            content {
              nextToken
              __typename
            }
            contentIds
            name
            stage
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        statusOrder
        brandProfileUrl {
          fileName
          objectKey
          size
          dateUploaded
          uploadedBy
          type
          customPrefix
          blurhash
          __typename
        }
        goals {
          platform
          start
          goal
          __typename
        }
        mission
        createdAt
        updatedAt
        __typename
      }
      accessLevel
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listBrandMembers = /* GraphQL */ `
  query ListBrandMembers(
    $id: ID
    $filter: ModelBrandMemberFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listBrandMembers(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        member {
          id
          name
          email
          brandMembers {
            items {
              id
              memberId
              brandId
              accessLevel
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          subscription
          stripeCustomerId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        memberId
        brandId
        brand {
          id
          name
          connections {
            id
            brandId
            youtubeChannelName
            youtubeChannelId
            youtubeUserId
            youtubeChannelPictureUrl
            tiktok
            instagramUserId
            instagramUsername
            instagramProfilePictureUrl
            instagramTokenId
            facebookPageId
            facebookPageName
            facebookPagePictureUrl
            facebookTokenId
            createdAt
            updatedAt
            __typename
          }
          connectionId
          categories
          status {
            items {
              id
              brandId
              contentIds
              name
              stage
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          statusOrder
          brandProfileUrl {
            fileName
            objectKey
            size
            dateUploaded
            uploadedBy
            type
            customPrefix
            blurhash
            __typename
          }
          goals {
            platform
            start
            goal
            __typename
          }
          mission
          createdAt
          updatedAt
          __typename
        }
        accessLevel
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getConnections = /* GraphQL */ `
  query GetConnections($id: ID!) {
    getConnections(id: $id) {
      id
      brandId
      youtubeChannelName
      youtubeChannelId
      youtubeUserId
      youtubeChannelPictureUrl
      tiktok
      instagramUserId
      instagramUsername
      instagramProfilePictureUrl
      instagramTokenId
      facebookPageId
      facebookPageName
      facebookPagePictureUrl
      facebookTokenId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listConnections = /* GraphQL */ `
  query ListConnections(
    $id: ID
    $filter: ModelConnectionsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listConnections(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        brandId
        youtubeChannelName
        youtubeChannelId
        youtubeUserId
        youtubeChannelPictureUrl
        tiktok
        instagramUserId
        instagramUsername
        instagramProfilePictureUrl
        instagramTokenId
        facebookPageId
        facebookPageName
        facebookPagePictureUrl
        facebookTokenId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getContentStatus = /* GraphQL */ `
  query GetContentStatus($id: ID!) {
    getContentStatus(id: $id) {
      id
      brandId
      content {
        items {
          id
          assignedTo {
            id
            name
            email
            brandMembers {
              nextToken
              __typename
            }
            subscription
            stripeCustomerId
            lastLogin
            createdAt
            updatedAt
            __typename
          }
          assignedMemberId
          brandId
          categories
          createdAt
          createdBy
          contentScheduleId
          dueDate
          media {
            fileName
            objectKey
            size
            dateUploaded
            uploadedBy
            type
            customPrefix
            blurhash
            __typename
          }
          platforms {
            platform
            status
            postUrl
            publishDate
            __typename
          }
          publishDate
          published
          status {
            id
            brandId
            content {
              nextToken
              __typename
            }
            contentIds
            name
            stage
            createdAt
            updatedAt
            __typename
          }
          statusId
          markdown
          comments {
            items {
              id
              createdAt
              contentId
              message
              memberId
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          checklist {
            id
            title
            completed
            __typename
          }
          approvalStatus
          thumbnail {
            fileName
            objectKey
            size
            dateUploaded
            uploadedBy
            type
            customPrefix
            blurhash
            __typename
          }
          title
          description
          caption
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contentIds
      name
      stage
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listContentStatuses = /* GraphQL */ `
  query ListContentStatuses(
    $id: ID
    $filter: ModelContentStatusFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listContentStatuses(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        brandId
        content {
          items {
            id
            assignedTo {
              id
              name
              email
              subscription
              stripeCustomerId
              lastLogin
              createdAt
              updatedAt
              __typename
            }
            assignedMemberId
            brandId
            categories
            createdAt
            createdBy
            contentScheduleId
            dueDate
            media {
              fileName
              objectKey
              size
              dateUploaded
              uploadedBy
              type
              customPrefix
              blurhash
              __typename
            }
            platforms {
              platform
              status
              postUrl
              publishDate
              __typename
            }
            publishDate
            published
            status {
              id
              brandId
              contentIds
              name
              stage
              createdAt
              updatedAt
              __typename
            }
            statusId
            markdown
            comments {
              nextToken
              __typename
            }
            checklist {
              id
              title
              completed
              __typename
            }
            approvalStatus
            thumbnail {
              fileName
              objectKey
              size
              dateUploaded
              uploadedBy
              type
              customPrefix
              blurhash
              __typename
            }
            title
            description
            caption
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        contentIds
        name
        stage
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getContent = /* GraphQL */ `
  query GetContent($id: ID!) {
    getContent(id: $id) {
      id
      assignedTo {
        id
        name
        email
        brandMembers {
          items {
            id
            member {
              id
              name
              email
              subscription
              stripeCustomerId
              lastLogin
              createdAt
              updatedAt
              __typename
            }
            memberId
            brandId
            brand {
              id
              name
              connectionId
              categories
              statusOrder
              mission
              createdAt
              updatedAt
              __typename
            }
            accessLevel
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        subscription
        stripeCustomerId
        lastLogin
        createdAt
        updatedAt
        __typename
      }
      assignedMemberId
      brandId
      categories
      createdAt
      createdBy
      contentScheduleId
      dueDate
      media {
        fileName
        objectKey
        size
        dateUploaded
        uploadedBy
        type
        customPrefix
        blurhash
        __typename
      }
      platforms {
        platform
        status
        postUrl
        publishDate
        __typename
      }
      publishDate
      published
      status {
        id
        brandId
        content {
          items {
            id
            assignedTo {
              id
              name
              email
              subscription
              stripeCustomerId
              lastLogin
              createdAt
              updatedAt
              __typename
            }
            assignedMemberId
            brandId
            categories
            createdAt
            createdBy
            contentScheduleId
            dueDate
            media {
              fileName
              objectKey
              size
              dateUploaded
              uploadedBy
              type
              customPrefix
              blurhash
              __typename
            }
            platforms {
              platform
              status
              postUrl
              publishDate
              __typename
            }
            publishDate
            published
            status {
              id
              brandId
              contentIds
              name
              stage
              createdAt
              updatedAt
              __typename
            }
            statusId
            markdown
            comments {
              nextToken
              __typename
            }
            checklist {
              id
              title
              completed
              __typename
            }
            approvalStatus
            thumbnail {
              fileName
              objectKey
              size
              dateUploaded
              uploadedBy
              type
              customPrefix
              blurhash
              __typename
            }
            title
            description
            caption
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        contentIds
        name
        stage
        createdAt
        updatedAt
        __typename
      }
      statusId
      markdown
      comments {
        items {
          id
          createdAt
          contentId
          message
          memberId
          member {
            id
            name
            email
            brandMembers {
              nextToken
              __typename
            }
            subscription
            stripeCustomerId
            lastLogin
            createdAt
            updatedAt
            __typename
          }
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      checklist {
        id
        title
        completed
        __typename
      }
      approvalStatus
      thumbnail {
        fileName
        objectKey
        size
        dateUploaded
        uploadedBy
        type
        customPrefix
        blurhash
        __typename
      }
      title
      description
      caption
      updatedAt
      __typename
    }
  }
`;
export const listContents = /* GraphQL */ `
  query ListContents(
    $filter: ModelContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        assignedTo {
          id
          name
          email
          brandMembers {
            items {
              id
              memberId
              brandId
              accessLevel
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          subscription
          stripeCustomerId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        assignedMemberId
        brandId
        categories
        createdAt
        createdBy
        contentScheduleId
        dueDate
        media {
          fileName
          objectKey
          size
          dateUploaded
          uploadedBy
          type
          customPrefix
          blurhash
          __typename
        }
        platforms {
          platform
          status
          postUrl
          publishDate
          __typename
        }
        publishDate
        published
        status {
          id
          brandId
          content {
            items {
              id
              assignedMemberId
              brandId
              categories
              createdAt
              createdBy
              contentScheduleId
              dueDate
              publishDate
              published
              statusId
              markdown
              approvalStatus
              title
              description
              caption
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          contentIds
          name
          stage
          createdAt
          updatedAt
          __typename
        }
        statusId
        markdown
        comments {
          items {
            id
            createdAt
            contentId
            message
            memberId
            member {
              id
              name
              email
              subscription
              stripeCustomerId
              lastLogin
              createdAt
              updatedAt
              __typename
            }
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        checklist {
          id
          title
          completed
          __typename
        }
        approvalStatus
        thumbnail {
          fileName
          objectKey
          size
          dateUploaded
          uploadedBy
          type
          customPrefix
          blurhash
          __typename
        }
        title
        description
        caption
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getContentCategory = /* GraphQL */ `
  query GetContentCategory($id: ID!) {
    getContentCategory(id: $id) {
      id
      contentId
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listContentCategories = /* GraphQL */ `
  query ListContentCategories(
    $filter: ModelContentCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContentCategories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        contentId
        name
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getComment = /* GraphQL */ `
  query GetComment($id: ID!) {
    getComment(id: $id) {
      id
      createdAt
      contentId
      message
      memberId
      member {
        id
        name
        email
        brandMembers {
          items {
            id
            member {
              id
              name
              email
              subscription
              stripeCustomerId
              lastLogin
              createdAt
              updatedAt
              __typename
            }
            memberId
            brandId
            brand {
              id
              name
              connectionId
              categories
              statusOrder
              mission
              createdAt
              updatedAt
              __typename
            }
            accessLevel
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        subscription
        stripeCustomerId
        lastLogin
        createdAt
        updatedAt
        __typename
      }
      updatedAt
      __typename
    }
  }
`;
export const listComments = /* GraphQL */ `
  query ListComments(
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        contentId
        message
        memberId
        member {
          id
          name
          email
          brandMembers {
            items {
              id
              memberId
              brandId
              accessLevel
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          subscription
          stripeCustomerId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getContentSchedule = /* GraphQL */ `
  query GetContentSchedule($id: ID!) {
    getContentSchedule(id: $id) {
      id
      publishDate
      status
      contentId
      brandId
      platformData {
        platform
        accountId
        tokenId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listContentSchedules = /* GraphQL */ `
  query ListContentSchedules(
    $id: ID
    $filter: ModelContentScheduleFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listContentSchedules(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        publishDate
        status
        contentId
        brandId
        platformData {
          platform
          accountId
          tokenId
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMember = /* GraphQL */ `
  query GetMember($id: ID!) {
    getMember(id: $id) {
      id
      name
      email
      brandMembers {
        items {
          id
          member {
            id
            name
            email
            brandMembers {
              nextToken
              __typename
            }
            subscription
            stripeCustomerId
            lastLogin
            createdAt
            updatedAt
            __typename
          }
          memberId
          brandId
          brand {
            id
            name
            connections {
              id
              brandId
              youtubeChannelName
              youtubeChannelId
              youtubeUserId
              youtubeChannelPictureUrl
              tiktok
              instagramUserId
              instagramUsername
              instagramProfilePictureUrl
              instagramTokenId
              facebookPageId
              facebookPageName
              facebookPagePictureUrl
              facebookTokenId
              createdAt
              updatedAt
              __typename
            }
            connectionId
            categories
            status {
              nextToken
              __typename
            }
            statusOrder
            brandProfileUrl {
              fileName
              objectKey
              size
              dateUploaded
              uploadedBy
              type
              customPrefix
              blurhash
              __typename
            }
            goals {
              platform
              start
              goal
              __typename
            }
            mission
            createdAt
            updatedAt
            __typename
          }
          accessLevel
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      subscription
      stripeCustomerId
      lastLogin
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listMembers = /* GraphQL */ `
  query ListMembers(
    $id: ID
    $filter: ModelMemberFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMembers(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        email
        brandMembers {
          items {
            id
            member {
              id
              name
              email
              subscription
              stripeCustomerId
              lastLogin
              createdAt
              updatedAt
              __typename
            }
            memberId
            brandId
            brand {
              id
              name
              connectionId
              categories
              statusOrder
              mission
              createdAt
              updatedAt
              __typename
            }
            accessLevel
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        subscription
        stripeCustomerId
        lastLogin
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getToken = /* GraphQL */ `
  query GetToken($id: ID!) {
    getToken(id: $id) {
      id
      createdAt
      brandId
      accessToken
      refreshToken
      tokenType
      expiryDate
      platform
      updatedAt
      __typename
    }
  }
`;
export const listTokens = /* GraphQL */ `
  query ListTokens(
    $id: ID
    $filter: ModelTokenFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTokens(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        createdAt
        brandId
        accessToken
        refreshToken
        tokenType
        expiryDate
        platform
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const brandMembersByMemberId = /* GraphQL */ `
  query BrandMembersByMemberId(
    $memberId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelBrandMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    brandMembersByMemberId(
      memberId: $memberId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        member {
          id
          name
          email
          brandMembers {
            items {
              id
              memberId
              brandId
              accessLevel
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          subscription
          stripeCustomerId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        memberId
        brandId
        brand {
          id
          name
          connections {
            id
            brandId
            youtubeChannelName
            youtubeChannelId
            youtubeUserId
            youtubeChannelPictureUrl
            tiktok
            instagramUserId
            instagramUsername
            instagramProfilePictureUrl
            instagramTokenId
            facebookPageId
            facebookPageName
            facebookPagePictureUrl
            facebookTokenId
            createdAt
            updatedAt
            __typename
          }
          connectionId
          categories
          status {
            items {
              id
              brandId
              contentIds
              name
              stage
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          statusOrder
          brandProfileUrl {
            fileName
            objectKey
            size
            dateUploaded
            uploadedBy
            type
            customPrefix
            blurhash
            __typename
          }
          goals {
            platform
            start
            goal
            __typename
          }
          mission
          createdAt
          updatedAt
          __typename
        }
        accessLevel
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const brandMembersByBrandId = /* GraphQL */ `
  query BrandMembersByBrandId(
    $brandId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelBrandMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    brandMembersByBrandId(
      brandId: $brandId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        member {
          id
          name
          email
          brandMembers {
            items {
              id
              memberId
              brandId
              accessLevel
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          subscription
          stripeCustomerId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        memberId
        brandId
        brand {
          id
          name
          connections {
            id
            brandId
            youtubeChannelName
            youtubeChannelId
            youtubeUserId
            youtubeChannelPictureUrl
            tiktok
            instagramUserId
            instagramUsername
            instagramProfilePictureUrl
            instagramTokenId
            facebookPageId
            facebookPageName
            facebookPagePictureUrl
            facebookTokenId
            createdAt
            updatedAt
            __typename
          }
          connectionId
          categories
          status {
            items {
              id
              brandId
              contentIds
              name
              stage
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          statusOrder
          brandProfileUrl {
            fileName
            objectKey
            size
            dateUploaded
            uploadedBy
            type
            customPrefix
            blurhash
            __typename
          }
          goals {
            platform
            start
            goal
            __typename
          }
          mission
          createdAt
          updatedAt
          __typename
        }
        accessLevel
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listBrandMembersByBrandId = /* GraphQL */ `
  query ListBrandMembersByBrandId(
    $brandId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelBrandMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBrandMembersByBrandId(
      brandId: $brandId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        member {
          id
          name
          email
          brandMembers {
            items {
              id
              memberId
              brandId
              accessLevel
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          subscription
          stripeCustomerId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        memberId
        brandId
        brand {
          id
          name
          connections {
            id
            brandId
            youtubeChannelName
            youtubeChannelId
            youtubeUserId
            youtubeChannelPictureUrl
            tiktok
            instagramUserId
            instagramUsername
            instagramProfilePictureUrl
            instagramTokenId
            facebookPageId
            facebookPageName
            facebookPagePictureUrl
            facebookTokenId
            createdAt
            updatedAt
            __typename
          }
          connectionId
          categories
          status {
            items {
              id
              brandId
              contentIds
              name
              stage
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          statusOrder
          brandProfileUrl {
            fileName
            objectKey
            size
            dateUploaded
            uploadedBy
            type
            customPrefix
            blurhash
            __typename
          }
          goals {
            platform
            start
            goal
            __typename
          }
          mission
          createdAt
          updatedAt
          __typename
        }
        accessLevel
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const connectionsByBrandId = /* GraphQL */ `
  query ConnectionsByBrandId(
    $brandId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelConnectionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    connectionsByBrandId(
      brandId: $brandId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        brandId
        youtubeChannelName
        youtubeChannelId
        youtubeUserId
        youtubeChannelPictureUrl
        tiktok
        instagramUserId
        instagramUsername
        instagramProfilePictureUrl
        instagramTokenId
        facebookPageId
        facebookPageName
        facebookPagePictureUrl
        facebookTokenId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const contentStatusesByBrandId = /* GraphQL */ `
  query ContentStatusesByBrandId(
    $brandId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelContentStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    contentStatusesByBrandId(
      brandId: $brandId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        brandId
        content {
          items {
            id
            assignedTo {
              id
              name
              email
              subscription
              stripeCustomerId
              lastLogin
              createdAt
              updatedAt
              __typename
            }
            assignedMemberId
            brandId
            categories
            createdAt
            createdBy
            contentScheduleId
            dueDate
            media {
              fileName
              objectKey
              size
              dateUploaded
              uploadedBy
              type
              customPrefix
              blurhash
              __typename
            }
            platforms {
              platform
              status
              postUrl
              publishDate
              __typename
            }
            publishDate
            published
            status {
              id
              brandId
              contentIds
              name
              stage
              createdAt
              updatedAt
              __typename
            }
            statusId
            markdown
            comments {
              nextToken
              __typename
            }
            checklist {
              id
              title
              completed
              __typename
            }
            approvalStatus
            thumbnail {
              fileName
              objectKey
              size
              dateUploaded
              uploadedBy
              type
              customPrefix
              blurhash
              __typename
            }
            title
            description
            caption
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        contentIds
        name
        stage
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const contentsById = /* GraphQL */ `
  query ContentsById(
    $id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    contentsById(
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        assignedTo {
          id
          name
          email
          brandMembers {
            items {
              id
              memberId
              brandId
              accessLevel
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          subscription
          stripeCustomerId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        assignedMemberId
        brandId
        categories
        createdAt
        createdBy
        contentScheduleId
        dueDate
        media {
          fileName
          objectKey
          size
          dateUploaded
          uploadedBy
          type
          customPrefix
          blurhash
          __typename
        }
        platforms {
          platform
          status
          postUrl
          publishDate
          __typename
        }
        publishDate
        published
        status {
          id
          brandId
          content {
            items {
              id
              assignedMemberId
              brandId
              categories
              createdAt
              createdBy
              contentScheduleId
              dueDate
              publishDate
              published
              statusId
              markdown
              approvalStatus
              title
              description
              caption
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          contentIds
          name
          stage
          createdAt
          updatedAt
          __typename
        }
        statusId
        markdown
        comments {
          items {
            id
            createdAt
            contentId
            message
            memberId
            member {
              id
              name
              email
              subscription
              stripeCustomerId
              lastLogin
              createdAt
              updatedAt
              __typename
            }
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        checklist {
          id
          title
          completed
          __typename
        }
        approvalStatus
        thumbnail {
          fileName
          objectKey
          size
          dateUploaded
          uploadedBy
          type
          customPrefix
          blurhash
          __typename
        }
        title
        description
        caption
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const contentsByBrandId = /* GraphQL */ `
  query ContentsByBrandId(
    $brandId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    contentsByBrandId(
      brandId: $brandId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        assignedTo {
          id
          name
          email
          brandMembers {
            items {
              id
              memberId
              brandId
              accessLevel
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          subscription
          stripeCustomerId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        assignedMemberId
        brandId
        categories
        createdAt
        createdBy
        contentScheduleId
        dueDate
        media {
          fileName
          objectKey
          size
          dateUploaded
          uploadedBy
          type
          customPrefix
          blurhash
          __typename
        }
        platforms {
          platform
          status
          postUrl
          publishDate
          __typename
        }
        publishDate
        published
        status {
          id
          brandId
          content {
            items {
              id
              assignedMemberId
              brandId
              categories
              createdAt
              createdBy
              contentScheduleId
              dueDate
              publishDate
              published
              statusId
              markdown
              approvalStatus
              title
              description
              caption
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          contentIds
          name
          stage
          createdAt
          updatedAt
          __typename
        }
        statusId
        markdown
        comments {
          items {
            id
            createdAt
            contentId
            message
            memberId
            member {
              id
              name
              email
              subscription
              stripeCustomerId
              lastLogin
              createdAt
              updatedAt
              __typename
            }
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        checklist {
          id
          title
          completed
          __typename
        }
        approvalStatus
        thumbnail {
          fileName
          objectKey
          size
          dateUploaded
          uploadedBy
          type
          customPrefix
          blurhash
          __typename
        }
        title
        description
        caption
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listContentByBrandId = /* GraphQL */ `
  query ListContentByBrandId(
    $brandId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContentByBrandId(
      brandId: $brandId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        assignedTo {
          id
          name
          email
          brandMembers {
            items {
              id
              memberId
              brandId
              accessLevel
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          subscription
          stripeCustomerId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        assignedMemberId
        brandId
        categories
        createdAt
        createdBy
        contentScheduleId
        dueDate
        media {
          fileName
          objectKey
          size
          dateUploaded
          uploadedBy
          type
          customPrefix
          blurhash
          __typename
        }
        platforms {
          platform
          status
          postUrl
          publishDate
          __typename
        }
        publishDate
        published
        status {
          id
          brandId
          content {
            items {
              id
              assignedMemberId
              brandId
              categories
              createdAt
              createdBy
              contentScheduleId
              dueDate
              publishDate
              published
              statusId
              markdown
              approvalStatus
              title
              description
              caption
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          contentIds
          name
          stage
          createdAt
          updatedAt
          __typename
        }
        statusId
        markdown
        comments {
          items {
            id
            createdAt
            contentId
            message
            memberId
            member {
              id
              name
              email
              subscription
              stripeCustomerId
              lastLogin
              createdAt
              updatedAt
              __typename
            }
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        checklist {
          id
          title
          completed
          __typename
        }
        approvalStatus
        thumbnail {
          fileName
          objectKey
          size
          dateUploaded
          uploadedBy
          type
          customPrefix
          blurhash
          __typename
        }
        title
        description
        caption
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listContentByBrandIdAndCreatedAt = /* GraphQL */ `
  query ListContentByBrandIdAndCreatedAt(
    $brandId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContentByBrandIdAndCreatedAt(
      brandId: $brandId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        assignedTo {
          id
          name
          email
          brandMembers {
            items {
              id
              memberId
              brandId
              accessLevel
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          subscription
          stripeCustomerId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        assignedMemberId
        brandId
        categories
        createdAt
        createdBy
        contentScheduleId
        dueDate
        media {
          fileName
          objectKey
          size
          dateUploaded
          uploadedBy
          type
          customPrefix
          blurhash
          __typename
        }
        platforms {
          platform
          status
          postUrl
          publishDate
          __typename
        }
        publishDate
        published
        status {
          id
          brandId
          content {
            items {
              id
              assignedMemberId
              brandId
              categories
              createdAt
              createdBy
              contentScheduleId
              dueDate
              publishDate
              published
              statusId
              markdown
              approvalStatus
              title
              description
              caption
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          contentIds
          name
          stage
          createdAt
          updatedAt
          __typename
        }
        statusId
        markdown
        comments {
          items {
            id
            createdAt
            contentId
            message
            memberId
            member {
              id
              name
              email
              subscription
              stripeCustomerId
              lastLogin
              createdAt
              updatedAt
              __typename
            }
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        checklist {
          id
          title
          completed
          __typename
        }
        approvalStatus
        thumbnail {
          fileName
          objectKey
          size
          dateUploaded
          uploadedBy
          type
          customPrefix
          blurhash
          __typename
        }
        title
        description
        caption
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listContentByBrandIdAndPublishDate = /* GraphQL */ `
  query ListContentByBrandIdAndPublishDate(
    $brandId: ID!
    $publishDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContentByBrandIdAndPublishDate(
      brandId: $brandId
      publishDate: $publishDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        assignedTo {
          id
          name
          email
          brandMembers {
            items {
              id
              memberId
              brandId
              accessLevel
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          subscription
          stripeCustomerId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        assignedMemberId
        brandId
        categories
        createdAt
        createdBy
        contentScheduleId
        dueDate
        media {
          fileName
          objectKey
          size
          dateUploaded
          uploadedBy
          type
          customPrefix
          blurhash
          __typename
        }
        platforms {
          platform
          status
          postUrl
          publishDate
          __typename
        }
        publishDate
        published
        status {
          id
          brandId
          content {
            items {
              id
              assignedMemberId
              brandId
              categories
              createdAt
              createdBy
              contentScheduleId
              dueDate
              publishDate
              published
              statusId
              markdown
              approvalStatus
              title
              description
              caption
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          contentIds
          name
          stage
          createdAt
          updatedAt
          __typename
        }
        statusId
        markdown
        comments {
          items {
            id
            createdAt
            contentId
            message
            memberId
            member {
              id
              name
              email
              subscription
              stripeCustomerId
              lastLogin
              createdAt
              updatedAt
              __typename
            }
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        checklist {
          id
          title
          completed
          __typename
        }
        approvalStatus
        thumbnail {
          fileName
          objectKey
          size
          dateUploaded
          uploadedBy
          type
          customPrefix
          blurhash
          __typename
        }
        title
        description
        caption
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listContentByBrandIdAndApprovalStatus = /* GraphQL */ `
  query ListContentByBrandIdAndApprovalStatus(
    $brandId: ID!
    $approvalStatus: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContentByBrandIdAndApprovalStatus(
      brandId: $brandId
      approvalStatus: $approvalStatus
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        assignedTo {
          id
          name
          email
          brandMembers {
            items {
              id
              memberId
              brandId
              accessLevel
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          subscription
          stripeCustomerId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        assignedMemberId
        brandId
        categories
        createdAt
        createdBy
        contentScheduleId
        dueDate
        media {
          fileName
          objectKey
          size
          dateUploaded
          uploadedBy
          type
          customPrefix
          blurhash
          __typename
        }
        platforms {
          platform
          status
          postUrl
          publishDate
          __typename
        }
        publishDate
        published
        status {
          id
          brandId
          content {
            items {
              id
              assignedMemberId
              brandId
              categories
              createdAt
              createdBy
              contentScheduleId
              dueDate
              publishDate
              published
              statusId
              markdown
              approvalStatus
              title
              description
              caption
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          contentIds
          name
          stage
          createdAt
          updatedAt
          __typename
        }
        statusId
        markdown
        comments {
          items {
            id
            createdAt
            contentId
            message
            memberId
            member {
              id
              name
              email
              subscription
              stripeCustomerId
              lastLogin
              createdAt
              updatedAt
              __typename
            }
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        checklist {
          id
          title
          completed
          __typename
        }
        approvalStatus
        thumbnail {
          fileName
          objectKey
          size
          dateUploaded
          uploadedBy
          type
          customPrefix
          blurhash
          __typename
        }
        title
        description
        caption
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listContentByStatusId = /* GraphQL */ `
  query ListContentByStatusId(
    $statusId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContentByStatusId(
      statusId: $statusId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        assignedTo {
          id
          name
          email
          brandMembers {
            items {
              id
              memberId
              brandId
              accessLevel
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          subscription
          stripeCustomerId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        assignedMemberId
        brandId
        categories
        createdAt
        createdBy
        contentScheduleId
        dueDate
        media {
          fileName
          objectKey
          size
          dateUploaded
          uploadedBy
          type
          customPrefix
          blurhash
          __typename
        }
        platforms {
          platform
          status
          postUrl
          publishDate
          __typename
        }
        publishDate
        published
        status {
          id
          brandId
          content {
            items {
              id
              assignedMemberId
              brandId
              categories
              createdAt
              createdBy
              contentScheduleId
              dueDate
              publishDate
              published
              statusId
              markdown
              approvalStatus
              title
              description
              caption
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          contentIds
          name
          stage
          createdAt
          updatedAt
          __typename
        }
        statusId
        markdown
        comments {
          items {
            id
            createdAt
            contentId
            message
            memberId
            member {
              id
              name
              email
              subscription
              stripeCustomerId
              lastLogin
              createdAt
              updatedAt
              __typename
            }
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        checklist {
          id
          title
          completed
          __typename
        }
        approvalStatus
        thumbnail {
          fileName
          objectKey
          size
          dateUploaded
          uploadedBy
          type
          customPrefix
          blurhash
          __typename
        }
        title
        description
        caption
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const commentsByContentId = /* GraphQL */ `
  query CommentsByContentId(
    $contentId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    commentsByContentId(
      contentId: $contentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        contentId
        message
        memberId
        member {
          id
          name
          email
          brandMembers {
            items {
              id
              memberId
              brandId
              accessLevel
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          subscription
          stripeCustomerId
          lastLogin
          createdAt
          updatedAt
          __typename
        }
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listMembersByEmail = /* GraphQL */ `
  query ListMembersByEmail(
    $email: String!
    $sortDirection: ModelSortDirection
    $filter: ModelMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMembersByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        email
        brandMembers {
          items {
            id
            member {
              id
              name
              email
              subscription
              stripeCustomerId
              lastLogin
              createdAt
              updatedAt
              __typename
            }
            memberId
            brandId
            brand {
              id
              name
              connectionId
              categories
              statusOrder
              mission
              createdAt
              updatedAt
              __typename
            }
            accessLevel
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        subscription
        stripeCustomerId
        lastLogin
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listTokensByBrandId = /* GraphQL */ `
  query ListTokensByBrandId(
    $brandId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTokenFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTokensByBrandId(
      brandId: $brandId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        brandId
        accessToken
        refreshToken
        tokenType
        expiryDate
        platform
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
