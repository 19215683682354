import "./App.css";
import "@aws-amplify/ui-react/styles.css";

import { Authenticator } from "@aws-amplify/ui-react";
import { CssBaseline } from "@mui/material";
import { ThemeOptions, ThemeProvider } from "@mui/material/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Amplify } from "aws-amplify";
import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Toaster } from "react-hot-toast";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import awsExports from "./aws-exports";
import ErrorFallback from "./components/ErrorFallBack";
import { SettingsConsumer, SettingsProvider } from "./contexts/settings-context";
import { loadState, saveState } from "./redux/sessionStorage";
import { setupStore } from "./redux/store";
import Router from "./routes/Router";
import { createTheme } from "./styles";

Amplify.configure(awsExports);

const App: React.FC = () => {
  const persistedUserState = loadState();

  const store = setupStore({ user: persistedUserState });

  store.subscribe(() => saveState(store.getState().user));

  return (
    <BrowserRouter>
      <Provider store={store}>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID ?? ""}>
          <Authenticator.Provider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                <SettingsProvider>
                  <SettingsConsumer>
                    {(settings) => {
                      // Prevent theme flicker when restoring custom settings from browser storage
                      if (!settings.isInitialized) {
                        // return null;
                      }

                      const themeOptions = settings as ThemeOptions;
                      const theme = createTheme({ ...themeOptions });

                      return (
                        <ThemeProvider theme={theme}>
                          <Router />
                          <CssBaseline />
                          <Toaster />
                          {/* <Popover id="XskzUENw" /> */}
                        </ThemeProvider>
                      );
                    }}
                  </SettingsConsumer>
                </SettingsProvider>
              </ErrorBoundary>
            </LocalizationProvider>
          </Authenticator.Provider>
        </GoogleOAuthProvider>
      </Provider>
    </BrowserRouter>
  );
};

export default App;
