/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateAnalyticsValuesInput = {
  id?: string | null,
  analyticsId: string,
  value: string,
};

export type ModelAnalyticsValuesConditionInput = {
  analyticsId?: ModelIDInput | null,
  value?: ModelStringInput | null,
  and?: Array< ModelAnalyticsValuesConditionInput | null > | null,
  or?: Array< ModelAnalyticsValuesConditionInput | null > | null,
  not?: ModelAnalyticsValuesConditionInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type AnalyticsValues = {
  __typename: "AnalyticsValues",
  id: string,
  analyticsId: string,
  value: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateAnalyticsValuesInput = {
  id: string,
  analyticsId?: string | null,
  value?: string | null,
};

export type DeleteAnalyticsValuesInput = {
  id: string,
};

export type CreateAnalyticsInput = {
  id?: string | null,
  brandId: string,
  platform: Platform,
  createdAt?: string | null,
};

export enum Platform {
  YOUTUBE = "YOUTUBE",
  TIKTOK = "TIKTOK",
  INSTAGRAM = "INSTAGRAM",
  FACEBOOK = "FACEBOOK",
  ALL = "ALL",
}


export type ModelAnalyticsConditionInput = {
  brandId?: ModelIDInput | null,
  platform?: ModelPlatformInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelAnalyticsConditionInput | null > | null,
  or?: Array< ModelAnalyticsConditionInput | null > | null,
  not?: ModelAnalyticsConditionInput | null,
};

export type ModelPlatformInput = {
  eq?: Platform | null,
  ne?: Platform | null,
};

export type Analytics = {
  __typename: "Analytics",
  id: string,
  brandId: string,
  platform: Platform,
  createdAt: string,
  values?:  Array<AnalyticsValues | null > | null,
  updatedAt: string,
};

export type UpdateAnalyticsInput = {
  id: string,
  brandId?: string | null,
  platform?: Platform | null,
  createdAt?: string | null,
};

export type DeleteAnalyticsInput = {
  id: string,
};

export type CreateBrandInput = {
  id?: string | null,
  name: string,
  connectionId?: string | null,
  categories?: Array< string | null > | null,
  statusOrder?: Array< string | null > | null,
  brandProfileUrl?: MediaInput | null,
  goals?: Array< BrandGoalsInput | null > | null,
  mission?: string | null,
};

export type MediaInput = {
  fileName: string,
  objectKey: string,
  size: number,
  dateUploaded: string,
  uploadedBy: string,
  type: string,
  customPrefix?: string | null,
  blurhash?: string | null,
};

export type BrandGoalsInput = {
  platform: Platform,
  start: number,
  goal: number,
};

export type ModelBrandConditionInput = {
  name?: ModelStringInput | null,
  connectionId?: ModelIDInput | null,
  categories?: ModelStringInput | null,
  statusOrder?: ModelStringInput | null,
  mission?: ModelStringInput | null,
  and?: Array< ModelBrandConditionInput | null > | null,
  or?: Array< ModelBrandConditionInput | null > | null,
  not?: ModelBrandConditionInput | null,
};

export type Brand = {
  __typename: "Brand",
  id: string,
  name: string,
  connections?: Connections | null,
  connectionId?: string | null,
  categories?: Array< string | null > | null,
  status?: ModelContentStatusConnection | null,
  statusOrder?: Array< string | null > | null,
  brandProfileUrl?: Media | null,
  goals?:  Array<BrandGoals | null > | null,
  mission?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type Connections = {
  __typename: "Connections",
  id: string,
  brandId: string,
  youtubeChannelName?: string | null,
  youtubeChannelId?: string | null,
  youtubeUserId?: string | null,
  youtubeChannelPictureUrl?: string | null,
  tiktok?: string | null,
  instagramUserId?: string | null,
  instagramUsername?: string | null,
  instagramProfilePictureUrl?: string | null,
  instagramTokenId?: string | null,
  facebookPageId?: string | null,
  facebookPageName?: string | null,
  facebookPagePictureUrl?: string | null,
  facebookTokenId?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelContentStatusConnection = {
  __typename: "ModelContentStatusConnection",
  items:  Array<ContentStatus | null >,
  nextToken?: string | null,
};

export type ContentStatus = {
  __typename: "ContentStatus",
  id: string,
  brandId: string,
  content?: ModelContentConnection | null,
  contentIds: Array< string | null >,
  name: string,
  stage: ContentStage,
  createdAt: string,
  updatedAt: string,
};

export type ModelContentConnection = {
  __typename: "ModelContentConnection",
  items:  Array<Content | null >,
  nextToken?: string | null,
};

export type Content = {
  __typename: "Content",
  id: string,
  assignedTo?: Member | null,
  assignedMemberId?: string | null,
  brandId: string,
  categories?: Array< string | null > | null,
  createdAt: string,
  createdBy: string,
  contentScheduleId?: string | null,
  dueDate?: string | null,
  media?:  Array<Media | null > | null,
  platforms?:  Array<PlatformData | null > | null,
  publishDate?: string | null,
  published?: boolean | null,
  status: ContentStatus,
  statusId: string,
  markdown?: string | null,
  comments?: ModelCommentConnection | null,
  checklist?:  Array<ContentChecklist | null > | null,
  approvalStatus?: ApprovalStatus | null,
  thumbnail?: Media | null,
  title: string,
  description?: string | null,
  caption?: string | null,
  updatedAt: string,
};

export type Member = {
  __typename: "Member",
  id: string,
  name: string,
  email: string,
  brandMembers?: ModelBrandMemberConnection | null,
  subscription?: MemberPlan | null,
  stripeCustomerId?: string | null,
  lastLogin?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelBrandMemberConnection = {
  __typename: "ModelBrandMemberConnection",
  items:  Array<BrandMember | null >,
  nextToken?: string | null,
};

export type BrandMember = {
  __typename: "BrandMember",
  id: string,
  member?: Member | null,
  memberId: string,
  brandId: string,
  brand: Brand,
  accessLevel: AccessLevel,
  createdAt: string,
  updatedAt: string,
};

export enum AccessLevel {
  ADMIN = "ADMIN",
  EDITOR = "EDITOR",
  COMMENTOR = "COMMENTOR",
}


export enum MemberPlan {
  FREE_TRIAL = "FREE_TRIAL",
  STARTER = "STARTER",
  GROWTH = "GROWTH",
  BUSINESS = "BUSINESS",
  ENTERPRISE = "ENTERPRISE",
}


export type Media = {
  __typename: "Media",
  fileName: string,
  objectKey: string,
  size: number,
  dateUploaded: string,
  uploadedBy: string,
  type: string,
  customPrefix?: string | null,
  blurhash?: string | null,
};

export type PlatformData = {
  __typename: "PlatformData",
  platform: Platform,
  status: ContentPublishStatus,
  postUrl?: string | null,
  publishDate?: string | null,
};

export enum ContentPublishStatus {
  PENDING = "PENDING",
  PUBLISHED = "PUBLISHED",
  ERROR = "ERROR",
}


export type ModelCommentConnection = {
  __typename: "ModelCommentConnection",
  items:  Array<Comment | null >,
  nextToken?: string | null,
};

export type Comment = {
  __typename: "Comment",
  id: string,
  createdAt: string,
  contentId: string,
  message: string,
  memberId: string,
  member?: Member | null,
  updatedAt: string,
};

export type ContentChecklist = {
  __typename: "ContentChecklist",
  id: string,
  title: string,
  completed: boolean,
};

export enum ApprovalStatus {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
}


export enum ContentStage {
  TODO = "TODO",
  IN_PROGRESS = "IN_PROGRESS",
  APPROVAL_NEEDED = "APPROVAL_NEEDED",
  COMPLETE = "COMPLETE",
}


export type BrandGoals = {
  __typename: "BrandGoals",
  platform: Platform,
  start: number,
  goal: number,
};

export type UpdateBrandInput = {
  id: string,
  name?: string | null,
  connectionId?: string | null,
  categories?: Array< string | null > | null,
  statusOrder?: Array< string | null > | null,
  brandProfileUrl?: MediaInput | null,
  goals?: Array< BrandGoalsInput | null > | null,
  mission?: string | null,
};

export type DeleteBrandInput = {
  id: string,
};

export type CreateBrandMemberInput = {
  id?: string | null,
  memberId: string,
  brandId: string,
  accessLevel: AccessLevel,
};

export type ModelBrandMemberConditionInput = {
  memberId?: ModelIDInput | null,
  brandId?: ModelIDInput | null,
  accessLevel?: ModelAccessLevelInput | null,
  and?: Array< ModelBrandMemberConditionInput | null > | null,
  or?: Array< ModelBrandMemberConditionInput | null > | null,
  not?: ModelBrandMemberConditionInput | null,
};

export type ModelAccessLevelInput = {
  eq?: AccessLevel | null,
  ne?: AccessLevel | null,
};

export type UpdateBrandMemberInput = {
  id: string,
  memberId?: string | null,
  brandId?: string | null,
  accessLevel?: AccessLevel | null,
};

export type DeleteBrandMemberInput = {
  id: string,
};

export type CreateConnectionsInput = {
  id?: string | null,
  brandId: string,
  youtubeChannelName?: string | null,
  youtubeChannelId?: string | null,
  youtubeUserId?: string | null,
  youtubeChannelPictureUrl?: string | null,
  tiktok?: string | null,
  instagramUserId?: string | null,
  instagramUsername?: string | null,
  instagramProfilePictureUrl?: string | null,
  instagramTokenId?: string | null,
  facebookPageId?: string | null,
  facebookPageName?: string | null,
  facebookPagePictureUrl?: string | null,
  facebookTokenId?: string | null,
};

export type ModelConnectionsConditionInput = {
  brandId?: ModelIDInput | null,
  youtubeChannelName?: ModelStringInput | null,
  youtubeChannelId?: ModelStringInput | null,
  youtubeUserId?: ModelStringInput | null,
  youtubeChannelPictureUrl?: ModelStringInput | null,
  tiktok?: ModelStringInput | null,
  instagramUserId?: ModelStringInput | null,
  instagramUsername?: ModelStringInput | null,
  instagramProfilePictureUrl?: ModelStringInput | null,
  instagramTokenId?: ModelStringInput | null,
  facebookPageId?: ModelStringInput | null,
  facebookPageName?: ModelStringInput | null,
  facebookPagePictureUrl?: ModelStringInput | null,
  facebookTokenId?: ModelStringInput | null,
  and?: Array< ModelConnectionsConditionInput | null > | null,
  or?: Array< ModelConnectionsConditionInput | null > | null,
  not?: ModelConnectionsConditionInput | null,
};

export type UpdateConnectionsInput = {
  id: string,
  brandId?: string | null,
  youtubeChannelName?: string | null,
  youtubeChannelId?: string | null,
  youtubeUserId?: string | null,
  youtubeChannelPictureUrl?: string | null,
  tiktok?: string | null,
  instagramUserId?: string | null,
  instagramUsername?: string | null,
  instagramProfilePictureUrl?: string | null,
  instagramTokenId?: string | null,
  facebookPageId?: string | null,
  facebookPageName?: string | null,
  facebookPagePictureUrl?: string | null,
  facebookTokenId?: string | null,
};

export type DeleteConnectionsInput = {
  id: string,
};

export type CreateContentStatusInput = {
  id?: string | null,
  brandId: string,
  contentIds: Array< string | null >,
  name: string,
  stage: ContentStage,
};

export type ModelContentStatusConditionInput = {
  brandId?: ModelIDInput | null,
  contentIds?: ModelIDInput | null,
  name?: ModelStringInput | null,
  stage?: ModelContentStageInput | null,
  and?: Array< ModelContentStatusConditionInput | null > | null,
  or?: Array< ModelContentStatusConditionInput | null > | null,
  not?: ModelContentStatusConditionInput | null,
};

export type ModelContentStageInput = {
  eq?: ContentStage | null,
  ne?: ContentStage | null,
};

export type UpdateContentStatusInput = {
  id: string,
  brandId?: string | null,
  contentIds?: Array< string | null > | null,
  name?: string | null,
  stage?: ContentStage | null,
};

export type DeleteContentStatusInput = {
  id: string,
};

export type CreateContentInput = {
  id?: string | null,
  assignedMemberId?: string | null,
  brandId: string,
  categories?: Array< string | null > | null,
  createdAt?: string | null,
  createdBy: string,
  contentScheduleId?: string | null,
  dueDate?: string | null,
  media?: Array< MediaInput | null > | null,
  platforms?: Array< PlatformDataInput | null > | null,
  publishDate?: string | null,
  published?: boolean | null,
  statusId: string,
  markdown?: string | null,
  checklist?: Array< ContentChecklistInput | null > | null,
  approvalStatus?: ApprovalStatus | null,
  thumbnail?: MediaInput | null,
  title: string,
  description?: string | null,
  caption?: string | null,
};

export type PlatformDataInput = {
  platform: Platform,
  status: ContentPublishStatus,
  postUrl?: string | null,
  publishDate?: string | null,
};

export type ContentChecklistInput = {
  id: string,
  title: string,
  completed: boolean,
};

export type ModelContentConditionInput = {
  assignedMemberId?: ModelIDInput | null,
  brandId?: ModelIDInput | null,
  categories?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  contentScheduleId?: ModelIDInput | null,
  dueDate?: ModelStringInput | null,
  publishDate?: ModelStringInput | null,
  published?: ModelBooleanInput | null,
  statusId?: ModelIDInput | null,
  markdown?: ModelStringInput | null,
  approvalStatus?: ModelApprovalStatusInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  caption?: ModelStringInput | null,
  and?: Array< ModelContentConditionInput | null > | null,
  or?: Array< ModelContentConditionInput | null > | null,
  not?: ModelContentConditionInput | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelApprovalStatusInput = {
  eq?: ApprovalStatus | null,
  ne?: ApprovalStatus | null,
};

export type UpdateContentInput = {
  id: string,
  assignedMemberId?: string | null,
  brandId?: string | null,
  categories?: Array< string | null > | null,
  createdAt?: string | null,
  createdBy?: string | null,
  contentScheduleId?: string | null,
  dueDate?: string | null,
  media?: Array< MediaInput | null > | null,
  platforms?: Array< PlatformDataInput | null > | null,
  publishDate?: string | null,
  published?: boolean | null,
  statusId?: string | null,
  markdown?: string | null,
  checklist?: Array< ContentChecklistInput | null > | null,
  approvalStatus?: ApprovalStatus | null,
  thumbnail?: MediaInput | null,
  title?: string | null,
  description?: string | null,
  caption?: string | null,
};

export type DeleteContentInput = {
  id: string,
};

export type CreateContentCategoryInput = {
  id?: string | null,
  contentId: string,
  name: string,
};

export type ModelContentCategoryConditionInput = {
  contentId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  and?: Array< ModelContentCategoryConditionInput | null > | null,
  or?: Array< ModelContentCategoryConditionInput | null > | null,
  not?: ModelContentCategoryConditionInput | null,
};

export type ContentCategory = {
  __typename: "ContentCategory",
  id: string,
  contentId: string,
  name: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateContentCategoryInput = {
  id: string,
  contentId?: string | null,
  name?: string | null,
};

export type DeleteContentCategoryInput = {
  id: string,
};

export type CreateCommentInput = {
  id?: string | null,
  createdAt?: string | null,
  contentId: string,
  message: string,
  memberId: string,
};

export type ModelCommentConditionInput = {
  createdAt?: ModelStringInput | null,
  contentId?: ModelIDInput | null,
  message?: ModelStringInput | null,
  memberId?: ModelIDInput | null,
  and?: Array< ModelCommentConditionInput | null > | null,
  or?: Array< ModelCommentConditionInput | null > | null,
  not?: ModelCommentConditionInput | null,
};

export type UpdateCommentInput = {
  id: string,
  createdAt?: string | null,
  contentId?: string | null,
  message?: string | null,
  memberId?: string | null,
};

export type DeleteCommentInput = {
  id: string,
};

export type CreateContentScheduleInput = {
  id?: string | null,
  publishDate: number,
  status: ScheduleStatus,
  contentId: string,
  brandId?: string | null,
  platformData: Array< ContentSchedulePlatformDataInput >,
};

export enum ScheduleStatus {
  PENDING = "PENDING",
  ERROR = "ERROR",
  COMPLETE = "COMPLETE",
}


export type ContentSchedulePlatformDataInput = {
  platform: Platform,
  accountId: string,
  tokenId: string,
};

export type ModelContentScheduleConditionInput = {
  publishDate?: ModelIntInput | null,
  status?: ModelScheduleStatusInput | null,
  contentId?: ModelStringInput | null,
  brandId?: ModelStringInput | null,
  and?: Array< ModelContentScheduleConditionInput | null > | null,
  or?: Array< ModelContentScheduleConditionInput | null > | null,
  not?: ModelContentScheduleConditionInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelScheduleStatusInput = {
  eq?: ScheduleStatus | null,
  ne?: ScheduleStatus | null,
};

export type ContentSchedule = {
  __typename: "ContentSchedule",
  id: string,
  publishDate: number,
  status: ScheduleStatus,
  contentId: string,
  brandId?: string | null,
  platformData:  Array<ContentSchedulePlatformData >,
  createdAt: string,
  updatedAt: string,
};

export type ContentSchedulePlatformData = {
  __typename: "ContentSchedulePlatformData",
  platform: Platform,
  accountId: string,
  tokenId: string,
};

export type UpdateContentScheduleInput = {
  id: string,
  publishDate?: number | null,
  status?: ScheduleStatus | null,
  contentId?: string | null,
  brandId?: string | null,
  platformData?: Array< ContentSchedulePlatformDataInput > | null,
};

export type DeleteContentScheduleInput = {
  id: string,
};

export type CreateMemberInput = {
  id?: string | null,
  name: string,
  email: string,
  subscription?: MemberPlan | null,
  stripeCustomerId?: string | null,
  lastLogin?: string | null,
};

export type ModelMemberConditionInput = {
  name?: ModelStringInput | null,
  email?: ModelStringInput | null,
  subscription?: ModelMemberPlanInput | null,
  stripeCustomerId?: ModelStringInput | null,
  lastLogin?: ModelStringInput | null,
  and?: Array< ModelMemberConditionInput | null > | null,
  or?: Array< ModelMemberConditionInput | null > | null,
  not?: ModelMemberConditionInput | null,
};

export type ModelMemberPlanInput = {
  eq?: MemberPlan | null,
  ne?: MemberPlan | null,
};

export type UpdateMemberInput = {
  id: string,
  name?: string | null,
  email?: string | null,
  subscription?: MemberPlan | null,
  stripeCustomerId?: string | null,
  lastLogin?: string | null,
};

export type DeleteMemberInput = {
  id: string,
};

export type CreateTokenInput = {
  id?: string | null,
  createdAt?: string | null,
  brandId: string,
  accessToken: string,
  refreshToken?: string | null,
  tokenType?: string | null,
  expiryDate: string,
  platform: Platform,
};

export type ModelTokenConditionInput = {
  createdAt?: ModelStringInput | null,
  brandId?: ModelIDInput | null,
  accessToken?: ModelStringInput | null,
  refreshToken?: ModelStringInput | null,
  tokenType?: ModelStringInput | null,
  expiryDate?: ModelStringInput | null,
  platform?: ModelPlatformInput | null,
  and?: Array< ModelTokenConditionInput | null > | null,
  or?: Array< ModelTokenConditionInput | null > | null,
  not?: ModelTokenConditionInput | null,
};

export type Token = {
  __typename: "Token",
  id: string,
  createdAt: string,
  brandId: string,
  accessToken: string,
  refreshToken?: string | null,
  tokenType?: string | null,
  expiryDate: string,
  platform: Platform,
  updatedAt: string,
};

export type UpdateTokenInput = {
  id: string,
  createdAt?: string | null,
  brandId?: string | null,
  accessToken?: string | null,
  refreshToken?: string | null,
  tokenType?: string | null,
  expiryDate?: string | null,
  platform?: Platform | null,
};

export type DeleteTokenInput = {
  id: string,
};

export type ModelAnalyticsValuesFilterInput = {
  id?: ModelIDInput | null,
  analyticsId?: ModelIDInput | null,
  value?: ModelStringInput | null,
  and?: Array< ModelAnalyticsValuesFilterInput | null > | null,
  or?: Array< ModelAnalyticsValuesFilterInput | null > | null,
  not?: ModelAnalyticsValuesFilterInput | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelAnalyticsValuesConnection = {
  __typename: "ModelAnalyticsValuesConnection",
  items:  Array<AnalyticsValues | null >,
  nextToken?: string | null,
};

export type ModelAnalyticsFilterInput = {
  id?: ModelIDInput | null,
  brandId?: ModelIDInput | null,
  platform?: ModelPlatformInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelAnalyticsFilterInput | null > | null,
  or?: Array< ModelAnalyticsFilterInput | null > | null,
  not?: ModelAnalyticsFilterInput | null,
};

export type ModelAnalyticsConnection = {
  __typename: "ModelAnalyticsConnection",
  items:  Array<Analytics | null >,
  nextToken?: string | null,
};

export type ModelBrandFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  connectionId?: ModelIDInput | null,
  categories?: ModelStringInput | null,
  statusOrder?: ModelStringInput | null,
  mission?: ModelStringInput | null,
  and?: Array< ModelBrandFilterInput | null > | null,
  or?: Array< ModelBrandFilterInput | null > | null,
  not?: ModelBrandFilterInput | null,
};

export type ModelBrandConnection = {
  __typename: "ModelBrandConnection",
  items:  Array<Brand | null >,
  nextToken?: string | null,
};

export type ModelBrandMemberFilterInput = {
  id?: ModelIDInput | null,
  memberId?: ModelIDInput | null,
  brandId?: ModelIDInput | null,
  accessLevel?: ModelAccessLevelInput | null,
  and?: Array< ModelBrandMemberFilterInput | null > | null,
  or?: Array< ModelBrandMemberFilterInput | null > | null,
  not?: ModelBrandMemberFilterInput | null,
};

export type ModelConnectionsFilterInput = {
  id?: ModelIDInput | null,
  brandId?: ModelIDInput | null,
  youtubeChannelName?: ModelStringInput | null,
  youtubeChannelId?: ModelStringInput | null,
  youtubeUserId?: ModelStringInput | null,
  youtubeChannelPictureUrl?: ModelStringInput | null,
  tiktok?: ModelStringInput | null,
  instagramUserId?: ModelStringInput | null,
  instagramUsername?: ModelStringInput | null,
  instagramProfilePictureUrl?: ModelStringInput | null,
  instagramTokenId?: ModelStringInput | null,
  facebookPageId?: ModelStringInput | null,
  facebookPageName?: ModelStringInput | null,
  facebookPagePictureUrl?: ModelStringInput | null,
  facebookTokenId?: ModelStringInput | null,
  and?: Array< ModelConnectionsFilterInput | null > | null,
  or?: Array< ModelConnectionsFilterInput | null > | null,
  not?: ModelConnectionsFilterInput | null,
};

export type ModelConnectionsConnection = {
  __typename: "ModelConnectionsConnection",
  items:  Array<Connections | null >,
  nextToken?: string | null,
};

export type ModelContentStatusFilterInput = {
  id?: ModelIDInput | null,
  brandId?: ModelIDInput | null,
  contentIds?: ModelIDInput | null,
  name?: ModelStringInput | null,
  stage?: ModelContentStageInput | null,
  and?: Array< ModelContentStatusFilterInput | null > | null,
  or?: Array< ModelContentStatusFilterInput | null > | null,
  not?: ModelContentStatusFilterInput | null,
};

export type ModelContentFilterInput = {
  id?: ModelIDInput | null,
  assignedMemberId?: ModelIDInput | null,
  brandId?: ModelIDInput | null,
  categories?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  contentScheduleId?: ModelIDInput | null,
  dueDate?: ModelStringInput | null,
  publishDate?: ModelStringInput | null,
  published?: ModelBooleanInput | null,
  statusId?: ModelIDInput | null,
  markdown?: ModelStringInput | null,
  approvalStatus?: ModelApprovalStatusInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  caption?: ModelStringInput | null,
  and?: Array< ModelContentFilterInput | null > | null,
  or?: Array< ModelContentFilterInput | null > | null,
  not?: ModelContentFilterInput | null,
};

export type ModelContentCategoryFilterInput = {
  id?: ModelIDInput | null,
  contentId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  and?: Array< ModelContentCategoryFilterInput | null > | null,
  or?: Array< ModelContentCategoryFilterInput | null > | null,
  not?: ModelContentCategoryFilterInput | null,
};

export type ModelContentCategoryConnection = {
  __typename: "ModelContentCategoryConnection",
  items:  Array<ContentCategory | null >,
  nextToken?: string | null,
};

export type ModelCommentFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  contentId?: ModelIDInput | null,
  message?: ModelStringInput | null,
  memberId?: ModelIDInput | null,
  and?: Array< ModelCommentFilterInput | null > | null,
  or?: Array< ModelCommentFilterInput | null > | null,
  not?: ModelCommentFilterInput | null,
};

export type ModelContentScheduleFilterInput = {
  id?: ModelIDInput | null,
  publishDate?: ModelIntInput | null,
  status?: ModelScheduleStatusInput | null,
  contentId?: ModelStringInput | null,
  brandId?: ModelStringInput | null,
  and?: Array< ModelContentScheduleFilterInput | null > | null,
  or?: Array< ModelContentScheduleFilterInput | null > | null,
  not?: ModelContentScheduleFilterInput | null,
};

export type ModelContentScheduleConnection = {
  __typename: "ModelContentScheduleConnection",
  items:  Array<ContentSchedule | null >,
  nextToken?: string | null,
};

export type ModelMemberFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  email?: ModelStringInput | null,
  subscription?: ModelMemberPlanInput | null,
  stripeCustomerId?: ModelStringInput | null,
  lastLogin?: ModelStringInput | null,
  and?: Array< ModelMemberFilterInput | null > | null,
  or?: Array< ModelMemberFilterInput | null > | null,
  not?: ModelMemberFilterInput | null,
};

export type ModelMemberConnection = {
  __typename: "ModelMemberConnection",
  items:  Array<Member | null >,
  nextToken?: string | null,
};

export type ModelTokenFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  brandId?: ModelIDInput | null,
  accessToken?: ModelStringInput | null,
  refreshToken?: ModelStringInput | null,
  tokenType?: ModelStringInput | null,
  expiryDate?: ModelStringInput | null,
  platform?: ModelPlatformInput | null,
  and?: Array< ModelTokenFilterInput | null > | null,
  or?: Array< ModelTokenFilterInput | null > | null,
  not?: ModelTokenFilterInput | null,
};

export type ModelTokenConnection = {
  __typename: "ModelTokenConnection",
  items:  Array<Token | null >,
  nextToken?: string | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelSubscriptionAnalyticsValuesFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  analyticsId?: ModelSubscriptionIDInput | null,
  value?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionAnalyticsValuesFilterInput | null > | null,
  or?: Array< ModelSubscriptionAnalyticsValuesFilterInput | null > | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionAnalyticsFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  brandId?: ModelSubscriptionIDInput | null,
  platform?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionAnalyticsFilterInput | null > | null,
  or?: Array< ModelSubscriptionAnalyticsFilterInput | null > | null,
};

export type ModelSubscriptionBrandFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  connectionId?: ModelSubscriptionIDInput | null,
  categories?: ModelSubscriptionStringInput | null,
  statusOrder?: ModelSubscriptionStringInput | null,
  mission?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionBrandFilterInput | null > | null,
  or?: Array< ModelSubscriptionBrandFilterInput | null > | null,
};

export type ModelSubscriptionBrandMemberFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  memberId?: ModelSubscriptionIDInput | null,
  brandId?: ModelSubscriptionIDInput | null,
  accessLevel?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionBrandMemberFilterInput | null > | null,
  or?: Array< ModelSubscriptionBrandMemberFilterInput | null > | null,
};

export type ModelSubscriptionConnectionsFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  brandId?: ModelSubscriptionIDInput | null,
  youtubeChannelName?: ModelSubscriptionStringInput | null,
  youtubeChannelId?: ModelSubscriptionStringInput | null,
  youtubeUserId?: ModelSubscriptionStringInput | null,
  youtubeChannelPictureUrl?: ModelSubscriptionStringInput | null,
  tiktok?: ModelSubscriptionStringInput | null,
  instagramUserId?: ModelSubscriptionStringInput | null,
  instagramUsername?: ModelSubscriptionStringInput | null,
  instagramProfilePictureUrl?: ModelSubscriptionStringInput | null,
  instagramTokenId?: ModelSubscriptionStringInput | null,
  facebookPageId?: ModelSubscriptionStringInput | null,
  facebookPageName?: ModelSubscriptionStringInput | null,
  facebookPagePictureUrl?: ModelSubscriptionStringInput | null,
  facebookTokenId?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionConnectionsFilterInput | null > | null,
  or?: Array< ModelSubscriptionConnectionsFilterInput | null > | null,
};

export type ModelSubscriptionContentStatusFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  brandId?: ModelSubscriptionIDInput | null,
  contentIds?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  stage?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionContentStatusFilterInput | null > | null,
  or?: Array< ModelSubscriptionContentStatusFilterInput | null > | null,
};

export type ModelSubscriptionContentFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  assignedMemberId?: ModelSubscriptionIDInput | null,
  brandId?: ModelSubscriptionIDInput | null,
  categories?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdBy?: ModelSubscriptionStringInput | null,
  contentScheduleId?: ModelSubscriptionIDInput | null,
  dueDate?: ModelSubscriptionStringInput | null,
  publishDate?: ModelSubscriptionStringInput | null,
  published?: ModelSubscriptionBooleanInput | null,
  statusId?: ModelSubscriptionIDInput | null,
  markdown?: ModelSubscriptionStringInput | null,
  approvalStatus?: ModelSubscriptionStringInput | null,
  title?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  caption?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionContentFilterInput | null > | null,
  or?: Array< ModelSubscriptionContentFilterInput | null > | null,
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelSubscriptionContentCategoryFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  contentId?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionContentCategoryFilterInput | null > | null,
  or?: Array< ModelSubscriptionContentCategoryFilterInput | null > | null,
};

export type ModelSubscriptionCommentFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  contentId?: ModelSubscriptionIDInput | null,
  message?: ModelSubscriptionStringInput | null,
  memberId?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionCommentFilterInput | null > | null,
  or?: Array< ModelSubscriptionCommentFilterInput | null > | null,
};

export type ModelSubscriptionContentScheduleFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  publishDate?: ModelSubscriptionIntInput | null,
  status?: ModelSubscriptionStringInput | null,
  contentId?: ModelSubscriptionStringInput | null,
  brandId?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionContentScheduleFilterInput | null > | null,
  or?: Array< ModelSubscriptionContentScheduleFilterInput | null > | null,
};

export type ModelSubscriptionIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionMemberFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  email?: ModelSubscriptionStringInput | null,
  subscription?: ModelSubscriptionStringInput | null,
  stripeCustomerId?: ModelSubscriptionStringInput | null,
  lastLogin?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionMemberFilterInput | null > | null,
  or?: Array< ModelSubscriptionMemberFilterInput | null > | null,
};

export type ModelSubscriptionTokenFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  brandId?: ModelSubscriptionIDInput | null,
  accessToken?: ModelSubscriptionStringInput | null,
  refreshToken?: ModelSubscriptionStringInput | null,
  tokenType?: ModelSubscriptionStringInput | null,
  expiryDate?: ModelSubscriptionStringInput | null,
  platform?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionTokenFilterInput | null > | null,
  or?: Array< ModelSubscriptionTokenFilterInput | null > | null,
};

export type CreateAnalyticsValuesMutationVariables = {
  input: CreateAnalyticsValuesInput,
  condition?: ModelAnalyticsValuesConditionInput | null,
};

export type CreateAnalyticsValuesMutation = {
  createAnalyticsValues?:  {
    __typename: "AnalyticsValues",
    id: string,
    analyticsId: string,
    value: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateAnalyticsValuesMutationVariables = {
  input: UpdateAnalyticsValuesInput,
  condition?: ModelAnalyticsValuesConditionInput | null,
};

export type UpdateAnalyticsValuesMutation = {
  updateAnalyticsValues?:  {
    __typename: "AnalyticsValues",
    id: string,
    analyticsId: string,
    value: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteAnalyticsValuesMutationVariables = {
  input: DeleteAnalyticsValuesInput,
  condition?: ModelAnalyticsValuesConditionInput | null,
};

export type DeleteAnalyticsValuesMutation = {
  deleteAnalyticsValues?:  {
    __typename: "AnalyticsValues",
    id: string,
    analyticsId: string,
    value: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateAnalyticsMutationVariables = {
  input: CreateAnalyticsInput,
  condition?: ModelAnalyticsConditionInput | null,
};

export type CreateAnalyticsMutation = {
  createAnalytics?:  {
    __typename: "Analytics",
    id: string,
    brandId: string,
    platform: Platform,
    createdAt: string,
    values?:  Array< {
      __typename: "AnalyticsValues",
      id: string,
      analyticsId: string,
      value: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    updatedAt: string,
  } | null,
};

export type UpdateAnalyticsMutationVariables = {
  input: UpdateAnalyticsInput,
  condition?: ModelAnalyticsConditionInput | null,
};

export type UpdateAnalyticsMutation = {
  updateAnalytics?:  {
    __typename: "Analytics",
    id: string,
    brandId: string,
    platform: Platform,
    createdAt: string,
    values?:  Array< {
      __typename: "AnalyticsValues",
      id: string,
      analyticsId: string,
      value: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    updatedAt: string,
  } | null,
};

export type DeleteAnalyticsMutationVariables = {
  input: DeleteAnalyticsInput,
  condition?: ModelAnalyticsConditionInput | null,
};

export type DeleteAnalyticsMutation = {
  deleteAnalytics?:  {
    __typename: "Analytics",
    id: string,
    brandId: string,
    platform: Platform,
    createdAt: string,
    values?:  Array< {
      __typename: "AnalyticsValues",
      id: string,
      analyticsId: string,
      value: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    updatedAt: string,
  } | null,
};

export type CreateBrandMutationVariables = {
  input: CreateBrandInput,
  condition?: ModelBrandConditionInput | null,
};

export type CreateBrandMutation = {
  createBrand?:  {
    __typename: "Brand",
    id: string,
    name: string,
    connections?:  {
      __typename: "Connections",
      id: string,
      brandId: string,
      youtubeChannelName?: string | null,
      youtubeChannelId?: string | null,
      youtubeUserId?: string | null,
      youtubeChannelPictureUrl?: string | null,
      tiktok?: string | null,
      instagramUserId?: string | null,
      instagramUsername?: string | null,
      instagramProfilePictureUrl?: string | null,
      instagramTokenId?: string | null,
      facebookPageId?: string | null,
      facebookPageName?: string | null,
      facebookPagePictureUrl?: string | null,
      facebookTokenId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    connectionId?: string | null,
    categories?: Array< string | null > | null,
    status?:  {
      __typename: "ModelContentStatusConnection",
      items:  Array< {
        __typename: "ContentStatus",
        id: string,
        brandId: string,
        content?:  {
          __typename: "ModelContentConnection",
          items:  Array< {
            __typename: "Content",
            id: string,
            assignedMemberId?: string | null,
            brandId: string,
            categories?: Array< string | null > | null,
            createdAt: string,
            createdBy: string,
            contentScheduleId?: string | null,
            dueDate?: string | null,
            publishDate?: string | null,
            published?: boolean | null,
            statusId: string,
            markdown?: string | null,
            approvalStatus?: ApprovalStatus | null,
            title: string,
            description?: string | null,
            caption?: string | null,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        contentIds: Array< string | null >,
        name: string,
        stage: ContentStage,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    statusOrder?: Array< string | null > | null,
    brandProfileUrl?:  {
      __typename: "Media",
      fileName: string,
      objectKey: string,
      size: number,
      dateUploaded: string,
      uploadedBy: string,
      type: string,
      customPrefix?: string | null,
      blurhash?: string | null,
    } | null,
    goals?:  Array< {
      __typename: "BrandGoals",
      platform: Platform,
      start: number,
      goal: number,
    } | null > | null,
    mission?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateBrandMutationVariables = {
  input: UpdateBrandInput,
  condition?: ModelBrandConditionInput | null,
};

export type UpdateBrandMutation = {
  updateBrand?:  {
    __typename: "Brand",
    id: string,
    name: string,
    connections?:  {
      __typename: "Connections",
      id: string,
      brandId: string,
      youtubeChannelName?: string | null,
      youtubeChannelId?: string | null,
      youtubeUserId?: string | null,
      youtubeChannelPictureUrl?: string | null,
      tiktok?: string | null,
      instagramUserId?: string | null,
      instagramUsername?: string | null,
      instagramProfilePictureUrl?: string | null,
      instagramTokenId?: string | null,
      facebookPageId?: string | null,
      facebookPageName?: string | null,
      facebookPagePictureUrl?: string | null,
      facebookTokenId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    connectionId?: string | null,
    categories?: Array< string | null > | null,
    status?:  {
      __typename: "ModelContentStatusConnection",
      items:  Array< {
        __typename: "ContentStatus",
        id: string,
        brandId: string,
        content?:  {
          __typename: "ModelContentConnection",
          items:  Array< {
            __typename: "Content",
            id: string,
            assignedMemberId?: string | null,
            brandId: string,
            categories?: Array< string | null > | null,
            createdAt: string,
            createdBy: string,
            contentScheduleId?: string | null,
            dueDate?: string | null,
            publishDate?: string | null,
            published?: boolean | null,
            statusId: string,
            markdown?: string | null,
            approvalStatus?: ApprovalStatus | null,
            title: string,
            description?: string | null,
            caption?: string | null,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        contentIds: Array< string | null >,
        name: string,
        stage: ContentStage,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    statusOrder?: Array< string | null > | null,
    brandProfileUrl?:  {
      __typename: "Media",
      fileName: string,
      objectKey: string,
      size: number,
      dateUploaded: string,
      uploadedBy: string,
      type: string,
      customPrefix?: string | null,
      blurhash?: string | null,
    } | null,
    goals?:  Array< {
      __typename: "BrandGoals",
      platform: Platform,
      start: number,
      goal: number,
    } | null > | null,
    mission?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteBrandMutationVariables = {
  input: DeleteBrandInput,
  condition?: ModelBrandConditionInput | null,
};

export type DeleteBrandMutation = {
  deleteBrand?:  {
    __typename: "Brand",
    id: string,
    name: string,
    connections?:  {
      __typename: "Connections",
      id: string,
      brandId: string,
      youtubeChannelName?: string | null,
      youtubeChannelId?: string | null,
      youtubeUserId?: string | null,
      youtubeChannelPictureUrl?: string | null,
      tiktok?: string | null,
      instagramUserId?: string | null,
      instagramUsername?: string | null,
      instagramProfilePictureUrl?: string | null,
      instagramTokenId?: string | null,
      facebookPageId?: string | null,
      facebookPageName?: string | null,
      facebookPagePictureUrl?: string | null,
      facebookTokenId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    connectionId?: string | null,
    categories?: Array< string | null > | null,
    status?:  {
      __typename: "ModelContentStatusConnection",
      items:  Array< {
        __typename: "ContentStatus",
        id: string,
        brandId: string,
        content?:  {
          __typename: "ModelContentConnection",
          items:  Array< {
            __typename: "Content",
            id: string,
            assignedMemberId?: string | null,
            brandId: string,
            categories?: Array< string | null > | null,
            createdAt: string,
            createdBy: string,
            contentScheduleId?: string | null,
            dueDate?: string | null,
            publishDate?: string | null,
            published?: boolean | null,
            statusId: string,
            markdown?: string | null,
            approvalStatus?: ApprovalStatus | null,
            title: string,
            description?: string | null,
            caption?: string | null,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        contentIds: Array< string | null >,
        name: string,
        stage: ContentStage,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    statusOrder?: Array< string | null > | null,
    brandProfileUrl?:  {
      __typename: "Media",
      fileName: string,
      objectKey: string,
      size: number,
      dateUploaded: string,
      uploadedBy: string,
      type: string,
      customPrefix?: string | null,
      blurhash?: string | null,
    } | null,
    goals?:  Array< {
      __typename: "BrandGoals",
      platform: Platform,
      start: number,
      goal: number,
    } | null > | null,
    mission?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateBrandMemberMutationVariables = {
  input: CreateBrandMemberInput,
  condition?: ModelBrandMemberConditionInput | null,
};

export type CreateBrandMemberMutation = {
  createBrandMember?:  {
    __typename: "BrandMember",
    id: string,
    member?:  {
      __typename: "Member",
      id: string,
      name: string,
      email: string,
      brandMembers?:  {
        __typename: "ModelBrandMemberConnection",
        items:  Array< {
          __typename: "BrandMember",
          id: string,
          member?:  {
            __typename: "Member",
            id: string,
            name: string,
            email: string,
            subscription?: MemberPlan | null,
            stripeCustomerId?: string | null,
            lastLogin?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          memberId: string,
          brandId: string,
          brand:  {
            __typename: "Brand",
            id: string,
            name: string,
            connectionId?: string | null,
            categories?: Array< string | null > | null,
            statusOrder?: Array< string | null > | null,
            mission?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          accessLevel: AccessLevel,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      subscription?: MemberPlan | null,
      stripeCustomerId?: string | null,
      lastLogin?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    memberId: string,
    brandId: string,
    brand:  {
      __typename: "Brand",
      id: string,
      name: string,
      connections?:  {
        __typename: "Connections",
        id: string,
        brandId: string,
        youtubeChannelName?: string | null,
        youtubeChannelId?: string | null,
        youtubeUserId?: string | null,
        youtubeChannelPictureUrl?: string | null,
        tiktok?: string | null,
        instagramUserId?: string | null,
        instagramUsername?: string | null,
        instagramProfilePictureUrl?: string | null,
        instagramTokenId?: string | null,
        facebookPageId?: string | null,
        facebookPageName?: string | null,
        facebookPagePictureUrl?: string | null,
        facebookTokenId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      connectionId?: string | null,
      categories?: Array< string | null > | null,
      status?:  {
        __typename: "ModelContentStatusConnection",
        items:  Array< {
          __typename: "ContentStatus",
          id: string,
          brandId: string,
          content?:  {
            __typename: "ModelContentConnection",
            nextToken?: string | null,
          } | null,
          contentIds: Array< string | null >,
          name: string,
          stage: ContentStage,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      statusOrder?: Array< string | null > | null,
      brandProfileUrl?:  {
        __typename: "Media",
        fileName: string,
        objectKey: string,
        size: number,
        dateUploaded: string,
        uploadedBy: string,
        type: string,
        customPrefix?: string | null,
        blurhash?: string | null,
      } | null,
      goals?:  Array< {
        __typename: "BrandGoals",
        platform: Platform,
        start: number,
        goal: number,
      } | null > | null,
      mission?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    accessLevel: AccessLevel,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateBrandMemberMutationVariables = {
  input: UpdateBrandMemberInput,
  condition?: ModelBrandMemberConditionInput | null,
};

export type UpdateBrandMemberMutation = {
  updateBrandMember?:  {
    __typename: "BrandMember",
    id: string,
    member?:  {
      __typename: "Member",
      id: string,
      name: string,
      email: string,
      brandMembers?:  {
        __typename: "ModelBrandMemberConnection",
        items:  Array< {
          __typename: "BrandMember",
          id: string,
          member?:  {
            __typename: "Member",
            id: string,
            name: string,
            email: string,
            subscription?: MemberPlan | null,
            stripeCustomerId?: string | null,
            lastLogin?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          memberId: string,
          brandId: string,
          brand:  {
            __typename: "Brand",
            id: string,
            name: string,
            connectionId?: string | null,
            categories?: Array< string | null > | null,
            statusOrder?: Array< string | null > | null,
            mission?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          accessLevel: AccessLevel,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      subscription?: MemberPlan | null,
      stripeCustomerId?: string | null,
      lastLogin?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    memberId: string,
    brandId: string,
    brand:  {
      __typename: "Brand",
      id: string,
      name: string,
      connections?:  {
        __typename: "Connections",
        id: string,
        brandId: string,
        youtubeChannelName?: string | null,
        youtubeChannelId?: string | null,
        youtubeUserId?: string | null,
        youtubeChannelPictureUrl?: string | null,
        tiktok?: string | null,
        instagramUserId?: string | null,
        instagramUsername?: string | null,
        instagramProfilePictureUrl?: string | null,
        instagramTokenId?: string | null,
        facebookPageId?: string | null,
        facebookPageName?: string | null,
        facebookPagePictureUrl?: string | null,
        facebookTokenId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      connectionId?: string | null,
      categories?: Array< string | null > | null,
      status?:  {
        __typename: "ModelContentStatusConnection",
        items:  Array< {
          __typename: "ContentStatus",
          id: string,
          brandId: string,
          content?:  {
            __typename: "ModelContentConnection",
            nextToken?: string | null,
          } | null,
          contentIds: Array< string | null >,
          name: string,
          stage: ContentStage,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      statusOrder?: Array< string | null > | null,
      brandProfileUrl?:  {
        __typename: "Media",
        fileName: string,
        objectKey: string,
        size: number,
        dateUploaded: string,
        uploadedBy: string,
        type: string,
        customPrefix?: string | null,
        blurhash?: string | null,
      } | null,
      goals?:  Array< {
        __typename: "BrandGoals",
        platform: Platform,
        start: number,
        goal: number,
      } | null > | null,
      mission?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    accessLevel: AccessLevel,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteBrandMemberMutationVariables = {
  input: DeleteBrandMemberInput,
  condition?: ModelBrandMemberConditionInput | null,
};

export type DeleteBrandMemberMutation = {
  deleteBrandMember?:  {
    __typename: "BrandMember",
    id: string,
    member?:  {
      __typename: "Member",
      id: string,
      name: string,
      email: string,
      brandMembers?:  {
        __typename: "ModelBrandMemberConnection",
        items:  Array< {
          __typename: "BrandMember",
          id: string,
          member?:  {
            __typename: "Member",
            id: string,
            name: string,
            email: string,
            subscription?: MemberPlan | null,
            stripeCustomerId?: string | null,
            lastLogin?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          memberId: string,
          brandId: string,
          brand:  {
            __typename: "Brand",
            id: string,
            name: string,
            connectionId?: string | null,
            categories?: Array< string | null > | null,
            statusOrder?: Array< string | null > | null,
            mission?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          accessLevel: AccessLevel,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      subscription?: MemberPlan | null,
      stripeCustomerId?: string | null,
      lastLogin?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    memberId: string,
    brandId: string,
    brand:  {
      __typename: "Brand",
      id: string,
      name: string,
      connections?:  {
        __typename: "Connections",
        id: string,
        brandId: string,
        youtubeChannelName?: string | null,
        youtubeChannelId?: string | null,
        youtubeUserId?: string | null,
        youtubeChannelPictureUrl?: string | null,
        tiktok?: string | null,
        instagramUserId?: string | null,
        instagramUsername?: string | null,
        instagramProfilePictureUrl?: string | null,
        instagramTokenId?: string | null,
        facebookPageId?: string | null,
        facebookPageName?: string | null,
        facebookPagePictureUrl?: string | null,
        facebookTokenId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      connectionId?: string | null,
      categories?: Array< string | null > | null,
      status?:  {
        __typename: "ModelContentStatusConnection",
        items:  Array< {
          __typename: "ContentStatus",
          id: string,
          brandId: string,
          content?:  {
            __typename: "ModelContentConnection",
            nextToken?: string | null,
          } | null,
          contentIds: Array< string | null >,
          name: string,
          stage: ContentStage,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      statusOrder?: Array< string | null > | null,
      brandProfileUrl?:  {
        __typename: "Media",
        fileName: string,
        objectKey: string,
        size: number,
        dateUploaded: string,
        uploadedBy: string,
        type: string,
        customPrefix?: string | null,
        blurhash?: string | null,
      } | null,
      goals?:  Array< {
        __typename: "BrandGoals",
        platform: Platform,
        start: number,
        goal: number,
      } | null > | null,
      mission?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    accessLevel: AccessLevel,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateConnectionsMutationVariables = {
  input: CreateConnectionsInput,
  condition?: ModelConnectionsConditionInput | null,
};

export type CreateConnectionsMutation = {
  createConnections?:  {
    __typename: "Connections",
    id: string,
    brandId: string,
    youtubeChannelName?: string | null,
    youtubeChannelId?: string | null,
    youtubeUserId?: string | null,
    youtubeChannelPictureUrl?: string | null,
    tiktok?: string | null,
    instagramUserId?: string | null,
    instagramUsername?: string | null,
    instagramProfilePictureUrl?: string | null,
    instagramTokenId?: string | null,
    facebookPageId?: string | null,
    facebookPageName?: string | null,
    facebookPagePictureUrl?: string | null,
    facebookTokenId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateConnectionsMutationVariables = {
  input: UpdateConnectionsInput,
  condition?: ModelConnectionsConditionInput | null,
};

export type UpdateConnectionsMutation = {
  updateConnections?:  {
    __typename: "Connections",
    id: string,
    brandId: string,
    youtubeChannelName?: string | null,
    youtubeChannelId?: string | null,
    youtubeUserId?: string | null,
    youtubeChannelPictureUrl?: string | null,
    tiktok?: string | null,
    instagramUserId?: string | null,
    instagramUsername?: string | null,
    instagramProfilePictureUrl?: string | null,
    instagramTokenId?: string | null,
    facebookPageId?: string | null,
    facebookPageName?: string | null,
    facebookPagePictureUrl?: string | null,
    facebookTokenId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteConnectionsMutationVariables = {
  input: DeleteConnectionsInput,
  condition?: ModelConnectionsConditionInput | null,
};

export type DeleteConnectionsMutation = {
  deleteConnections?:  {
    __typename: "Connections",
    id: string,
    brandId: string,
    youtubeChannelName?: string | null,
    youtubeChannelId?: string | null,
    youtubeUserId?: string | null,
    youtubeChannelPictureUrl?: string | null,
    tiktok?: string | null,
    instagramUserId?: string | null,
    instagramUsername?: string | null,
    instagramProfilePictureUrl?: string | null,
    instagramTokenId?: string | null,
    facebookPageId?: string | null,
    facebookPageName?: string | null,
    facebookPagePictureUrl?: string | null,
    facebookTokenId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateContentStatusMutationVariables = {
  input: CreateContentStatusInput,
  condition?: ModelContentStatusConditionInput | null,
};

export type CreateContentStatusMutation = {
  createContentStatus?:  {
    __typename: "ContentStatus",
    id: string,
    brandId: string,
    content?:  {
      __typename: "ModelContentConnection",
      items:  Array< {
        __typename: "Content",
        id: string,
        assignedTo?:  {
          __typename: "Member",
          id: string,
          name: string,
          email: string,
          brandMembers?:  {
            __typename: "ModelBrandMemberConnection",
            nextToken?: string | null,
          } | null,
          subscription?: MemberPlan | null,
          stripeCustomerId?: string | null,
          lastLogin?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        assignedMemberId?: string | null,
        brandId: string,
        categories?: Array< string | null > | null,
        createdAt: string,
        createdBy: string,
        contentScheduleId?: string | null,
        dueDate?: string | null,
        media?:  Array< {
          __typename: "Media",
          fileName: string,
          objectKey: string,
          size: number,
          dateUploaded: string,
          uploadedBy: string,
          type: string,
          customPrefix?: string | null,
          blurhash?: string | null,
        } | null > | null,
        platforms?:  Array< {
          __typename: "PlatformData",
          platform: Platform,
          status: ContentPublishStatus,
          postUrl?: string | null,
          publishDate?: string | null,
        } | null > | null,
        publishDate?: string | null,
        published?: boolean | null,
        status:  {
          __typename: "ContentStatus",
          id: string,
          brandId: string,
          content?:  {
            __typename: "ModelContentConnection",
            nextToken?: string | null,
          } | null,
          contentIds: Array< string | null >,
          name: string,
          stage: ContentStage,
          createdAt: string,
          updatedAt: string,
        },
        statusId: string,
        markdown?: string | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          items:  Array< {
            __typename: "Comment",
            id: string,
            createdAt: string,
            contentId: string,
            message: string,
            memberId: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        checklist?:  Array< {
          __typename: "ContentChecklist",
          id: string,
          title: string,
          completed: boolean,
        } | null > | null,
        approvalStatus?: ApprovalStatus | null,
        thumbnail?:  {
          __typename: "Media",
          fileName: string,
          objectKey: string,
          size: number,
          dateUploaded: string,
          uploadedBy: string,
          type: string,
          customPrefix?: string | null,
          blurhash?: string | null,
        } | null,
        title: string,
        description?: string | null,
        caption?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    contentIds: Array< string | null >,
    name: string,
    stage: ContentStage,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateContentStatusMutationVariables = {
  input: UpdateContentStatusInput,
  condition?: ModelContentStatusConditionInput | null,
};

export type UpdateContentStatusMutation = {
  updateContentStatus?:  {
    __typename: "ContentStatus",
    id: string,
    brandId: string,
    content?:  {
      __typename: "ModelContentConnection",
      items:  Array< {
        __typename: "Content",
        id: string,
        assignedTo?:  {
          __typename: "Member",
          id: string,
          name: string,
          email: string,
          brandMembers?:  {
            __typename: "ModelBrandMemberConnection",
            nextToken?: string | null,
          } | null,
          subscription?: MemberPlan | null,
          stripeCustomerId?: string | null,
          lastLogin?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        assignedMemberId?: string | null,
        brandId: string,
        categories?: Array< string | null > | null,
        createdAt: string,
        createdBy: string,
        contentScheduleId?: string | null,
        dueDate?: string | null,
        media?:  Array< {
          __typename: "Media",
          fileName: string,
          objectKey: string,
          size: number,
          dateUploaded: string,
          uploadedBy: string,
          type: string,
          customPrefix?: string | null,
          blurhash?: string | null,
        } | null > | null,
        platforms?:  Array< {
          __typename: "PlatformData",
          platform: Platform,
          status: ContentPublishStatus,
          postUrl?: string | null,
          publishDate?: string | null,
        } | null > | null,
        publishDate?: string | null,
        published?: boolean | null,
        status:  {
          __typename: "ContentStatus",
          id: string,
          brandId: string,
          content?:  {
            __typename: "ModelContentConnection",
            nextToken?: string | null,
          } | null,
          contentIds: Array< string | null >,
          name: string,
          stage: ContentStage,
          createdAt: string,
          updatedAt: string,
        },
        statusId: string,
        markdown?: string | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          items:  Array< {
            __typename: "Comment",
            id: string,
            createdAt: string,
            contentId: string,
            message: string,
            memberId: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        checklist?:  Array< {
          __typename: "ContentChecklist",
          id: string,
          title: string,
          completed: boolean,
        } | null > | null,
        approvalStatus?: ApprovalStatus | null,
        thumbnail?:  {
          __typename: "Media",
          fileName: string,
          objectKey: string,
          size: number,
          dateUploaded: string,
          uploadedBy: string,
          type: string,
          customPrefix?: string | null,
          blurhash?: string | null,
        } | null,
        title: string,
        description?: string | null,
        caption?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    contentIds: Array< string | null >,
    name: string,
    stage: ContentStage,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteContentStatusMutationVariables = {
  input: DeleteContentStatusInput,
  condition?: ModelContentStatusConditionInput | null,
};

export type DeleteContentStatusMutation = {
  deleteContentStatus?:  {
    __typename: "ContentStatus",
    id: string,
    brandId: string,
    content?:  {
      __typename: "ModelContentConnection",
      items:  Array< {
        __typename: "Content",
        id: string,
        assignedTo?:  {
          __typename: "Member",
          id: string,
          name: string,
          email: string,
          brandMembers?:  {
            __typename: "ModelBrandMemberConnection",
            nextToken?: string | null,
          } | null,
          subscription?: MemberPlan | null,
          stripeCustomerId?: string | null,
          lastLogin?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        assignedMemberId?: string | null,
        brandId: string,
        categories?: Array< string | null > | null,
        createdAt: string,
        createdBy: string,
        contentScheduleId?: string | null,
        dueDate?: string | null,
        media?:  Array< {
          __typename: "Media",
          fileName: string,
          objectKey: string,
          size: number,
          dateUploaded: string,
          uploadedBy: string,
          type: string,
          customPrefix?: string | null,
          blurhash?: string | null,
        } | null > | null,
        platforms?:  Array< {
          __typename: "PlatformData",
          platform: Platform,
          status: ContentPublishStatus,
          postUrl?: string | null,
          publishDate?: string | null,
        } | null > | null,
        publishDate?: string | null,
        published?: boolean | null,
        status:  {
          __typename: "ContentStatus",
          id: string,
          brandId: string,
          content?:  {
            __typename: "ModelContentConnection",
            nextToken?: string | null,
          } | null,
          contentIds: Array< string | null >,
          name: string,
          stage: ContentStage,
          createdAt: string,
          updatedAt: string,
        },
        statusId: string,
        markdown?: string | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          items:  Array< {
            __typename: "Comment",
            id: string,
            createdAt: string,
            contentId: string,
            message: string,
            memberId: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        checklist?:  Array< {
          __typename: "ContentChecklist",
          id: string,
          title: string,
          completed: boolean,
        } | null > | null,
        approvalStatus?: ApprovalStatus | null,
        thumbnail?:  {
          __typename: "Media",
          fileName: string,
          objectKey: string,
          size: number,
          dateUploaded: string,
          uploadedBy: string,
          type: string,
          customPrefix?: string | null,
          blurhash?: string | null,
        } | null,
        title: string,
        description?: string | null,
        caption?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    contentIds: Array< string | null >,
    name: string,
    stage: ContentStage,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateContentMutationVariables = {
  input: CreateContentInput,
  condition?: ModelContentConditionInput | null,
};

export type CreateContentMutation = {
  createContent?:  {
    __typename: "Content",
    id: string,
    assignedTo?:  {
      __typename: "Member",
      id: string,
      name: string,
      email: string,
      brandMembers?:  {
        __typename: "ModelBrandMemberConnection",
        items:  Array< {
          __typename: "BrandMember",
          id: string,
          member?:  {
            __typename: "Member",
            id: string,
            name: string,
            email: string,
            subscription?: MemberPlan | null,
            stripeCustomerId?: string | null,
            lastLogin?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          memberId: string,
          brandId: string,
          brand:  {
            __typename: "Brand",
            id: string,
            name: string,
            connectionId?: string | null,
            categories?: Array< string | null > | null,
            statusOrder?: Array< string | null > | null,
            mission?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          accessLevel: AccessLevel,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      subscription?: MemberPlan | null,
      stripeCustomerId?: string | null,
      lastLogin?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    assignedMemberId?: string | null,
    brandId: string,
    categories?: Array< string | null > | null,
    createdAt: string,
    createdBy: string,
    contentScheduleId?: string | null,
    dueDate?: string | null,
    media?:  Array< {
      __typename: "Media",
      fileName: string,
      objectKey: string,
      size: number,
      dateUploaded: string,
      uploadedBy: string,
      type: string,
      customPrefix?: string | null,
      blurhash?: string | null,
    } | null > | null,
    platforms?:  Array< {
      __typename: "PlatformData",
      platform: Platform,
      status: ContentPublishStatus,
      postUrl?: string | null,
      publishDate?: string | null,
    } | null > | null,
    publishDate?: string | null,
    published?: boolean | null,
    status:  {
      __typename: "ContentStatus",
      id: string,
      brandId: string,
      content?:  {
        __typename: "ModelContentConnection",
        items:  Array< {
          __typename: "Content",
          id: string,
          assignedTo?:  {
            __typename: "Member",
            id: string,
            name: string,
            email: string,
            subscription?: MemberPlan | null,
            stripeCustomerId?: string | null,
            lastLogin?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          assignedMemberId?: string | null,
          brandId: string,
          categories?: Array< string | null > | null,
          createdAt: string,
          createdBy: string,
          contentScheduleId?: string | null,
          dueDate?: string | null,
          media?:  Array< {
            __typename: "Media",
            fileName: string,
            objectKey: string,
            size: number,
            dateUploaded: string,
            uploadedBy: string,
            type: string,
            customPrefix?: string | null,
            blurhash?: string | null,
          } | null > | null,
          platforms?:  Array< {
            __typename: "PlatformData",
            platform: Platform,
            status: ContentPublishStatus,
            postUrl?: string | null,
            publishDate?: string | null,
          } | null > | null,
          publishDate?: string | null,
          published?: boolean | null,
          status:  {
            __typename: "ContentStatus",
            id: string,
            brandId: string,
            contentIds: Array< string | null >,
            name: string,
            stage: ContentStage,
            createdAt: string,
            updatedAt: string,
          },
          statusId: string,
          markdown?: string | null,
          comments?:  {
            __typename: "ModelCommentConnection",
            nextToken?: string | null,
          } | null,
          checklist?:  Array< {
            __typename: "ContentChecklist",
            id: string,
            title: string,
            completed: boolean,
          } | null > | null,
          approvalStatus?: ApprovalStatus | null,
          thumbnail?:  {
            __typename: "Media",
            fileName: string,
            objectKey: string,
            size: number,
            dateUploaded: string,
            uploadedBy: string,
            type: string,
            customPrefix?: string | null,
            blurhash?: string | null,
          } | null,
          title: string,
          description?: string | null,
          caption?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contentIds: Array< string | null >,
      name: string,
      stage: ContentStage,
      createdAt: string,
      updatedAt: string,
    },
    statusId: string,
    markdown?: string | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        createdAt: string,
        contentId: string,
        message: string,
        memberId: string,
        member?:  {
          __typename: "Member",
          id: string,
          name: string,
          email: string,
          brandMembers?:  {
            __typename: "ModelBrandMemberConnection",
            nextToken?: string | null,
          } | null,
          subscription?: MemberPlan | null,
          stripeCustomerId?: string | null,
          lastLogin?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    checklist?:  Array< {
      __typename: "ContentChecklist",
      id: string,
      title: string,
      completed: boolean,
    } | null > | null,
    approvalStatus?: ApprovalStatus | null,
    thumbnail?:  {
      __typename: "Media",
      fileName: string,
      objectKey: string,
      size: number,
      dateUploaded: string,
      uploadedBy: string,
      type: string,
      customPrefix?: string | null,
      blurhash?: string | null,
    } | null,
    title: string,
    description?: string | null,
    caption?: string | null,
    updatedAt: string,
  } | null,
};

export type UpdateContentMutationVariables = {
  input: UpdateContentInput,
  condition?: ModelContentConditionInput | null,
};

export type UpdateContentMutation = {
  updateContent?:  {
    __typename: "Content",
    id: string,
    assignedTo?:  {
      __typename: "Member",
      id: string,
      name: string,
      email: string,
      brandMembers?:  {
        __typename: "ModelBrandMemberConnection",
        items:  Array< {
          __typename: "BrandMember",
          id: string,
          member?:  {
            __typename: "Member",
            id: string,
            name: string,
            email: string,
            subscription?: MemberPlan | null,
            stripeCustomerId?: string | null,
            lastLogin?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          memberId: string,
          brandId: string,
          brand:  {
            __typename: "Brand",
            id: string,
            name: string,
            connectionId?: string | null,
            categories?: Array< string | null > | null,
            statusOrder?: Array< string | null > | null,
            mission?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          accessLevel: AccessLevel,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      subscription?: MemberPlan | null,
      stripeCustomerId?: string | null,
      lastLogin?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    assignedMemberId?: string | null,
    brandId: string,
    categories?: Array< string | null > | null,
    createdAt: string,
    createdBy: string,
    contentScheduleId?: string | null,
    dueDate?: string | null,
    media?:  Array< {
      __typename: "Media",
      fileName: string,
      objectKey: string,
      size: number,
      dateUploaded: string,
      uploadedBy: string,
      type: string,
      customPrefix?: string | null,
      blurhash?: string | null,
    } | null > | null,
    platforms?:  Array< {
      __typename: "PlatformData",
      platform: Platform,
      status: ContentPublishStatus,
      postUrl?: string | null,
      publishDate?: string | null,
    } | null > | null,
    publishDate?: string | null,
    published?: boolean | null,
    status:  {
      __typename: "ContentStatus",
      id: string,
      brandId: string,
      content?:  {
        __typename: "ModelContentConnection",
        items:  Array< {
          __typename: "Content",
          id: string,
          assignedTo?:  {
            __typename: "Member",
            id: string,
            name: string,
            email: string,
            subscription?: MemberPlan | null,
            stripeCustomerId?: string | null,
            lastLogin?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          assignedMemberId?: string | null,
          brandId: string,
          categories?: Array< string | null > | null,
          createdAt: string,
          createdBy: string,
          contentScheduleId?: string | null,
          dueDate?: string | null,
          media?:  Array< {
            __typename: "Media",
            fileName: string,
            objectKey: string,
            size: number,
            dateUploaded: string,
            uploadedBy: string,
            type: string,
            customPrefix?: string | null,
            blurhash?: string | null,
          } | null > | null,
          platforms?:  Array< {
            __typename: "PlatformData",
            platform: Platform,
            status: ContentPublishStatus,
            postUrl?: string | null,
            publishDate?: string | null,
          } | null > | null,
          publishDate?: string | null,
          published?: boolean | null,
          status:  {
            __typename: "ContentStatus",
            id: string,
            brandId: string,
            contentIds: Array< string | null >,
            name: string,
            stage: ContentStage,
            createdAt: string,
            updatedAt: string,
          },
          statusId: string,
          markdown?: string | null,
          comments?:  {
            __typename: "ModelCommentConnection",
            nextToken?: string | null,
          } | null,
          checklist?:  Array< {
            __typename: "ContentChecklist",
            id: string,
            title: string,
            completed: boolean,
          } | null > | null,
          approvalStatus?: ApprovalStatus | null,
          thumbnail?:  {
            __typename: "Media",
            fileName: string,
            objectKey: string,
            size: number,
            dateUploaded: string,
            uploadedBy: string,
            type: string,
            customPrefix?: string | null,
            blurhash?: string | null,
          } | null,
          title: string,
          description?: string | null,
          caption?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contentIds: Array< string | null >,
      name: string,
      stage: ContentStage,
      createdAt: string,
      updatedAt: string,
    },
    statusId: string,
    markdown?: string | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        createdAt: string,
        contentId: string,
        message: string,
        memberId: string,
        member?:  {
          __typename: "Member",
          id: string,
          name: string,
          email: string,
          brandMembers?:  {
            __typename: "ModelBrandMemberConnection",
            nextToken?: string | null,
          } | null,
          subscription?: MemberPlan | null,
          stripeCustomerId?: string | null,
          lastLogin?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    checklist?:  Array< {
      __typename: "ContentChecklist",
      id: string,
      title: string,
      completed: boolean,
    } | null > | null,
    approvalStatus?: ApprovalStatus | null,
    thumbnail?:  {
      __typename: "Media",
      fileName: string,
      objectKey: string,
      size: number,
      dateUploaded: string,
      uploadedBy: string,
      type: string,
      customPrefix?: string | null,
      blurhash?: string | null,
    } | null,
    title: string,
    description?: string | null,
    caption?: string | null,
    updatedAt: string,
  } | null,
};

export type DeleteContentMutationVariables = {
  input: DeleteContentInput,
  condition?: ModelContentConditionInput | null,
};

export type DeleteContentMutation = {
  deleteContent?:  {
    __typename: "Content",
    id: string,
    assignedTo?:  {
      __typename: "Member",
      id: string,
      name: string,
      email: string,
      brandMembers?:  {
        __typename: "ModelBrandMemberConnection",
        items:  Array< {
          __typename: "BrandMember",
          id: string,
          member?:  {
            __typename: "Member",
            id: string,
            name: string,
            email: string,
            subscription?: MemberPlan | null,
            stripeCustomerId?: string | null,
            lastLogin?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          memberId: string,
          brandId: string,
          brand:  {
            __typename: "Brand",
            id: string,
            name: string,
            connectionId?: string | null,
            categories?: Array< string | null > | null,
            statusOrder?: Array< string | null > | null,
            mission?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          accessLevel: AccessLevel,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      subscription?: MemberPlan | null,
      stripeCustomerId?: string | null,
      lastLogin?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    assignedMemberId?: string | null,
    brandId: string,
    categories?: Array< string | null > | null,
    createdAt: string,
    createdBy: string,
    contentScheduleId?: string | null,
    dueDate?: string | null,
    media?:  Array< {
      __typename: "Media",
      fileName: string,
      objectKey: string,
      size: number,
      dateUploaded: string,
      uploadedBy: string,
      type: string,
      customPrefix?: string | null,
      blurhash?: string | null,
    } | null > | null,
    platforms?:  Array< {
      __typename: "PlatformData",
      platform: Platform,
      status: ContentPublishStatus,
      postUrl?: string | null,
      publishDate?: string | null,
    } | null > | null,
    publishDate?: string | null,
    published?: boolean | null,
    status:  {
      __typename: "ContentStatus",
      id: string,
      brandId: string,
      content?:  {
        __typename: "ModelContentConnection",
        items:  Array< {
          __typename: "Content",
          id: string,
          assignedTo?:  {
            __typename: "Member",
            id: string,
            name: string,
            email: string,
            subscription?: MemberPlan | null,
            stripeCustomerId?: string | null,
            lastLogin?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          assignedMemberId?: string | null,
          brandId: string,
          categories?: Array< string | null > | null,
          createdAt: string,
          createdBy: string,
          contentScheduleId?: string | null,
          dueDate?: string | null,
          media?:  Array< {
            __typename: "Media",
            fileName: string,
            objectKey: string,
            size: number,
            dateUploaded: string,
            uploadedBy: string,
            type: string,
            customPrefix?: string | null,
            blurhash?: string | null,
          } | null > | null,
          platforms?:  Array< {
            __typename: "PlatformData",
            platform: Platform,
            status: ContentPublishStatus,
            postUrl?: string | null,
            publishDate?: string | null,
          } | null > | null,
          publishDate?: string | null,
          published?: boolean | null,
          status:  {
            __typename: "ContentStatus",
            id: string,
            brandId: string,
            contentIds: Array< string | null >,
            name: string,
            stage: ContentStage,
            createdAt: string,
            updatedAt: string,
          },
          statusId: string,
          markdown?: string | null,
          comments?:  {
            __typename: "ModelCommentConnection",
            nextToken?: string | null,
          } | null,
          checklist?:  Array< {
            __typename: "ContentChecklist",
            id: string,
            title: string,
            completed: boolean,
          } | null > | null,
          approvalStatus?: ApprovalStatus | null,
          thumbnail?:  {
            __typename: "Media",
            fileName: string,
            objectKey: string,
            size: number,
            dateUploaded: string,
            uploadedBy: string,
            type: string,
            customPrefix?: string | null,
            blurhash?: string | null,
          } | null,
          title: string,
          description?: string | null,
          caption?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contentIds: Array< string | null >,
      name: string,
      stage: ContentStage,
      createdAt: string,
      updatedAt: string,
    },
    statusId: string,
    markdown?: string | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        createdAt: string,
        contentId: string,
        message: string,
        memberId: string,
        member?:  {
          __typename: "Member",
          id: string,
          name: string,
          email: string,
          brandMembers?:  {
            __typename: "ModelBrandMemberConnection",
            nextToken?: string | null,
          } | null,
          subscription?: MemberPlan | null,
          stripeCustomerId?: string | null,
          lastLogin?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    checklist?:  Array< {
      __typename: "ContentChecklist",
      id: string,
      title: string,
      completed: boolean,
    } | null > | null,
    approvalStatus?: ApprovalStatus | null,
    thumbnail?:  {
      __typename: "Media",
      fileName: string,
      objectKey: string,
      size: number,
      dateUploaded: string,
      uploadedBy: string,
      type: string,
      customPrefix?: string | null,
      blurhash?: string | null,
    } | null,
    title: string,
    description?: string | null,
    caption?: string | null,
    updatedAt: string,
  } | null,
};

export type CreateContentCategoryMutationVariables = {
  input: CreateContentCategoryInput,
  condition?: ModelContentCategoryConditionInput | null,
};

export type CreateContentCategoryMutation = {
  createContentCategory?:  {
    __typename: "ContentCategory",
    id: string,
    contentId: string,
    name: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateContentCategoryMutationVariables = {
  input: UpdateContentCategoryInput,
  condition?: ModelContentCategoryConditionInput | null,
};

export type UpdateContentCategoryMutation = {
  updateContentCategory?:  {
    __typename: "ContentCategory",
    id: string,
    contentId: string,
    name: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteContentCategoryMutationVariables = {
  input: DeleteContentCategoryInput,
  condition?: ModelContentCategoryConditionInput | null,
};

export type DeleteContentCategoryMutation = {
  deleteContentCategory?:  {
    __typename: "ContentCategory",
    id: string,
    contentId: string,
    name: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateCommentMutationVariables = {
  input: CreateCommentInput,
  condition?: ModelCommentConditionInput | null,
};

export type CreateCommentMutation = {
  createComment?:  {
    __typename: "Comment",
    id: string,
    createdAt: string,
    contentId: string,
    message: string,
    memberId: string,
    member?:  {
      __typename: "Member",
      id: string,
      name: string,
      email: string,
      brandMembers?:  {
        __typename: "ModelBrandMemberConnection",
        items:  Array< {
          __typename: "BrandMember",
          id: string,
          member?:  {
            __typename: "Member",
            id: string,
            name: string,
            email: string,
            subscription?: MemberPlan | null,
            stripeCustomerId?: string | null,
            lastLogin?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          memberId: string,
          brandId: string,
          brand:  {
            __typename: "Brand",
            id: string,
            name: string,
            connectionId?: string | null,
            categories?: Array< string | null > | null,
            statusOrder?: Array< string | null > | null,
            mission?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          accessLevel: AccessLevel,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      subscription?: MemberPlan | null,
      stripeCustomerId?: string | null,
      lastLogin?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    updatedAt: string,
  } | null,
};

export type UpdateCommentMutationVariables = {
  input: UpdateCommentInput,
  condition?: ModelCommentConditionInput | null,
};

export type UpdateCommentMutation = {
  updateComment?:  {
    __typename: "Comment",
    id: string,
    createdAt: string,
    contentId: string,
    message: string,
    memberId: string,
    member?:  {
      __typename: "Member",
      id: string,
      name: string,
      email: string,
      brandMembers?:  {
        __typename: "ModelBrandMemberConnection",
        items:  Array< {
          __typename: "BrandMember",
          id: string,
          member?:  {
            __typename: "Member",
            id: string,
            name: string,
            email: string,
            subscription?: MemberPlan | null,
            stripeCustomerId?: string | null,
            lastLogin?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          memberId: string,
          brandId: string,
          brand:  {
            __typename: "Brand",
            id: string,
            name: string,
            connectionId?: string | null,
            categories?: Array< string | null > | null,
            statusOrder?: Array< string | null > | null,
            mission?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          accessLevel: AccessLevel,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      subscription?: MemberPlan | null,
      stripeCustomerId?: string | null,
      lastLogin?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    updatedAt: string,
  } | null,
};

export type DeleteCommentMutationVariables = {
  input: DeleteCommentInput,
  condition?: ModelCommentConditionInput | null,
};

export type DeleteCommentMutation = {
  deleteComment?:  {
    __typename: "Comment",
    id: string,
    createdAt: string,
    contentId: string,
    message: string,
    memberId: string,
    member?:  {
      __typename: "Member",
      id: string,
      name: string,
      email: string,
      brandMembers?:  {
        __typename: "ModelBrandMemberConnection",
        items:  Array< {
          __typename: "BrandMember",
          id: string,
          member?:  {
            __typename: "Member",
            id: string,
            name: string,
            email: string,
            subscription?: MemberPlan | null,
            stripeCustomerId?: string | null,
            lastLogin?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          memberId: string,
          brandId: string,
          brand:  {
            __typename: "Brand",
            id: string,
            name: string,
            connectionId?: string | null,
            categories?: Array< string | null > | null,
            statusOrder?: Array< string | null > | null,
            mission?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          accessLevel: AccessLevel,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      subscription?: MemberPlan | null,
      stripeCustomerId?: string | null,
      lastLogin?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    updatedAt: string,
  } | null,
};

export type CreateContentScheduleMutationVariables = {
  input: CreateContentScheduleInput,
  condition?: ModelContentScheduleConditionInput | null,
};

export type CreateContentScheduleMutation = {
  createContentSchedule?:  {
    __typename: "ContentSchedule",
    id: string,
    publishDate: number,
    status: ScheduleStatus,
    contentId: string,
    brandId?: string | null,
    platformData:  Array< {
      __typename: "ContentSchedulePlatformData",
      platform: Platform,
      accountId: string,
      tokenId: string,
    } >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateContentScheduleMutationVariables = {
  input: UpdateContentScheduleInput,
  condition?: ModelContentScheduleConditionInput | null,
};

export type UpdateContentScheduleMutation = {
  updateContentSchedule?:  {
    __typename: "ContentSchedule",
    id: string,
    publishDate: number,
    status: ScheduleStatus,
    contentId: string,
    brandId?: string | null,
    platformData:  Array< {
      __typename: "ContentSchedulePlatformData",
      platform: Platform,
      accountId: string,
      tokenId: string,
    } >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteContentScheduleMutationVariables = {
  input: DeleteContentScheduleInput,
  condition?: ModelContentScheduleConditionInput | null,
};

export type DeleteContentScheduleMutation = {
  deleteContentSchedule?:  {
    __typename: "ContentSchedule",
    id: string,
    publishDate: number,
    status: ScheduleStatus,
    contentId: string,
    brandId?: string | null,
    platformData:  Array< {
      __typename: "ContentSchedulePlatformData",
      platform: Platform,
      accountId: string,
      tokenId: string,
    } >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateMemberMutationVariables = {
  input: CreateMemberInput,
  condition?: ModelMemberConditionInput | null,
};

export type CreateMemberMutation = {
  createMember?:  {
    __typename: "Member",
    id: string,
    name: string,
    email: string,
    brandMembers?:  {
      __typename: "ModelBrandMemberConnection",
      items:  Array< {
        __typename: "BrandMember",
        id: string,
        member?:  {
          __typename: "Member",
          id: string,
          name: string,
          email: string,
          brandMembers?:  {
            __typename: "ModelBrandMemberConnection",
            nextToken?: string | null,
          } | null,
          subscription?: MemberPlan | null,
          stripeCustomerId?: string | null,
          lastLogin?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        memberId: string,
        brandId: string,
        brand:  {
          __typename: "Brand",
          id: string,
          name: string,
          connections?:  {
            __typename: "Connections",
            id: string,
            brandId: string,
            youtubeChannelName?: string | null,
            youtubeChannelId?: string | null,
            youtubeUserId?: string | null,
            youtubeChannelPictureUrl?: string | null,
            tiktok?: string | null,
            instagramUserId?: string | null,
            instagramUsername?: string | null,
            instagramProfilePictureUrl?: string | null,
            instagramTokenId?: string | null,
            facebookPageId?: string | null,
            facebookPageName?: string | null,
            facebookPagePictureUrl?: string | null,
            facebookTokenId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          connectionId?: string | null,
          categories?: Array< string | null > | null,
          status?:  {
            __typename: "ModelContentStatusConnection",
            nextToken?: string | null,
          } | null,
          statusOrder?: Array< string | null > | null,
          brandProfileUrl?:  {
            __typename: "Media",
            fileName: string,
            objectKey: string,
            size: number,
            dateUploaded: string,
            uploadedBy: string,
            type: string,
            customPrefix?: string | null,
            blurhash?: string | null,
          } | null,
          goals?:  Array< {
            __typename: "BrandGoals",
            platform: Platform,
            start: number,
            goal: number,
          } | null > | null,
          mission?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        accessLevel: AccessLevel,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    subscription?: MemberPlan | null,
    stripeCustomerId?: string | null,
    lastLogin?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateMemberMutationVariables = {
  input: UpdateMemberInput,
  condition?: ModelMemberConditionInput | null,
};

export type UpdateMemberMutation = {
  updateMember?:  {
    __typename: "Member",
    id: string,
    name: string,
    email: string,
    brandMembers?:  {
      __typename: "ModelBrandMemberConnection",
      items:  Array< {
        __typename: "BrandMember",
        id: string,
        member?:  {
          __typename: "Member",
          id: string,
          name: string,
          email: string,
          brandMembers?:  {
            __typename: "ModelBrandMemberConnection",
            nextToken?: string | null,
          } | null,
          subscription?: MemberPlan | null,
          stripeCustomerId?: string | null,
          lastLogin?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        memberId: string,
        brandId: string,
        brand:  {
          __typename: "Brand",
          id: string,
          name: string,
          connections?:  {
            __typename: "Connections",
            id: string,
            brandId: string,
            youtubeChannelName?: string | null,
            youtubeChannelId?: string | null,
            youtubeUserId?: string | null,
            youtubeChannelPictureUrl?: string | null,
            tiktok?: string | null,
            instagramUserId?: string | null,
            instagramUsername?: string | null,
            instagramProfilePictureUrl?: string | null,
            instagramTokenId?: string | null,
            facebookPageId?: string | null,
            facebookPageName?: string | null,
            facebookPagePictureUrl?: string | null,
            facebookTokenId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          connectionId?: string | null,
          categories?: Array< string | null > | null,
          status?:  {
            __typename: "ModelContentStatusConnection",
            nextToken?: string | null,
          } | null,
          statusOrder?: Array< string | null > | null,
          brandProfileUrl?:  {
            __typename: "Media",
            fileName: string,
            objectKey: string,
            size: number,
            dateUploaded: string,
            uploadedBy: string,
            type: string,
            customPrefix?: string | null,
            blurhash?: string | null,
          } | null,
          goals?:  Array< {
            __typename: "BrandGoals",
            platform: Platform,
            start: number,
            goal: number,
          } | null > | null,
          mission?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        accessLevel: AccessLevel,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    subscription?: MemberPlan | null,
    stripeCustomerId?: string | null,
    lastLogin?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteMemberMutationVariables = {
  input: DeleteMemberInput,
  condition?: ModelMemberConditionInput | null,
};

export type DeleteMemberMutation = {
  deleteMember?:  {
    __typename: "Member",
    id: string,
    name: string,
    email: string,
    brandMembers?:  {
      __typename: "ModelBrandMemberConnection",
      items:  Array< {
        __typename: "BrandMember",
        id: string,
        member?:  {
          __typename: "Member",
          id: string,
          name: string,
          email: string,
          brandMembers?:  {
            __typename: "ModelBrandMemberConnection",
            nextToken?: string | null,
          } | null,
          subscription?: MemberPlan | null,
          stripeCustomerId?: string | null,
          lastLogin?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        memberId: string,
        brandId: string,
        brand:  {
          __typename: "Brand",
          id: string,
          name: string,
          connections?:  {
            __typename: "Connections",
            id: string,
            brandId: string,
            youtubeChannelName?: string | null,
            youtubeChannelId?: string | null,
            youtubeUserId?: string | null,
            youtubeChannelPictureUrl?: string | null,
            tiktok?: string | null,
            instagramUserId?: string | null,
            instagramUsername?: string | null,
            instagramProfilePictureUrl?: string | null,
            instagramTokenId?: string | null,
            facebookPageId?: string | null,
            facebookPageName?: string | null,
            facebookPagePictureUrl?: string | null,
            facebookTokenId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          connectionId?: string | null,
          categories?: Array< string | null > | null,
          status?:  {
            __typename: "ModelContentStatusConnection",
            nextToken?: string | null,
          } | null,
          statusOrder?: Array< string | null > | null,
          brandProfileUrl?:  {
            __typename: "Media",
            fileName: string,
            objectKey: string,
            size: number,
            dateUploaded: string,
            uploadedBy: string,
            type: string,
            customPrefix?: string | null,
            blurhash?: string | null,
          } | null,
          goals?:  Array< {
            __typename: "BrandGoals",
            platform: Platform,
            start: number,
            goal: number,
          } | null > | null,
          mission?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        accessLevel: AccessLevel,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    subscription?: MemberPlan | null,
    stripeCustomerId?: string | null,
    lastLogin?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateTokenMutationVariables = {
  input: CreateTokenInput,
  condition?: ModelTokenConditionInput | null,
};

export type CreateTokenMutation = {
  createToken?:  {
    __typename: "Token",
    id: string,
    createdAt: string,
    brandId: string,
    accessToken: string,
    refreshToken?: string | null,
    tokenType?: string | null,
    expiryDate: string,
    platform: Platform,
    updatedAt: string,
  } | null,
};

export type UpdateTokenMutationVariables = {
  input: UpdateTokenInput,
  condition?: ModelTokenConditionInput | null,
};

export type UpdateTokenMutation = {
  updateToken?:  {
    __typename: "Token",
    id: string,
    createdAt: string,
    brandId: string,
    accessToken: string,
    refreshToken?: string | null,
    tokenType?: string | null,
    expiryDate: string,
    platform: Platform,
    updatedAt: string,
  } | null,
};

export type DeleteTokenMutationVariables = {
  input: DeleteTokenInput,
  condition?: ModelTokenConditionInput | null,
};

export type DeleteTokenMutation = {
  deleteToken?:  {
    __typename: "Token",
    id: string,
    createdAt: string,
    brandId: string,
    accessToken: string,
    refreshToken?: string | null,
    tokenType?: string | null,
    expiryDate: string,
    platform: Platform,
    updatedAt: string,
  } | null,
};

export type GetAnalyticsValuesQueryVariables = {
  id: string,
};

export type GetAnalyticsValuesQuery = {
  getAnalyticsValues?:  {
    __typename: "AnalyticsValues",
    id: string,
    analyticsId: string,
    value: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListAnalyticsValuesQueryVariables = {
  id?: string | null,
  filter?: ModelAnalyticsValuesFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListAnalyticsValuesQuery = {
  listAnalyticsValues?:  {
    __typename: "ModelAnalyticsValuesConnection",
    items:  Array< {
      __typename: "AnalyticsValues",
      id: string,
      analyticsId: string,
      value: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAnalyticsQueryVariables = {
  id: string,
};

export type GetAnalyticsQuery = {
  getAnalytics?:  {
    __typename: "Analytics",
    id: string,
    brandId: string,
    platform: Platform,
    createdAt: string,
    values?:  Array< {
      __typename: "AnalyticsValues",
      id: string,
      analyticsId: string,
      value: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    updatedAt: string,
  } | null,
};

export type ListAnalyticsQueryVariables = {
  id?: string | null,
  filter?: ModelAnalyticsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListAnalyticsQuery = {
  listAnalytics?:  {
    __typename: "ModelAnalyticsConnection",
    items:  Array< {
      __typename: "Analytics",
      id: string,
      brandId: string,
      platform: Platform,
      createdAt: string,
      values?:  Array< {
        __typename: "AnalyticsValues",
        id: string,
        analyticsId: string,
        value: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetBrandQueryVariables = {
  id: string,
};

export type GetBrandQuery = {
  getBrand?:  {
    __typename: "Brand",
    id: string,
    name: string,
    connections?:  {
      __typename: "Connections",
      id: string,
      brandId: string,
      youtubeChannelName?: string | null,
      youtubeChannelId?: string | null,
      youtubeUserId?: string | null,
      youtubeChannelPictureUrl?: string | null,
      tiktok?: string | null,
      instagramUserId?: string | null,
      instagramUsername?: string | null,
      instagramProfilePictureUrl?: string | null,
      instagramTokenId?: string | null,
      facebookPageId?: string | null,
      facebookPageName?: string | null,
      facebookPagePictureUrl?: string | null,
      facebookTokenId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    connectionId?: string | null,
    categories?: Array< string | null > | null,
    status?:  {
      __typename: "ModelContentStatusConnection",
      items:  Array< {
        __typename: "ContentStatus",
        id: string,
        brandId: string,
        content?:  {
          __typename: "ModelContentConnection",
          items:  Array< {
            __typename: "Content",
            id: string,
            assignedMemberId?: string | null,
            brandId: string,
            categories?: Array< string | null > | null,
            createdAt: string,
            createdBy: string,
            contentScheduleId?: string | null,
            dueDate?: string | null,
            publishDate?: string | null,
            published?: boolean | null,
            statusId: string,
            markdown?: string | null,
            approvalStatus?: ApprovalStatus | null,
            title: string,
            description?: string | null,
            caption?: string | null,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        contentIds: Array< string | null >,
        name: string,
        stage: ContentStage,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    statusOrder?: Array< string | null > | null,
    brandProfileUrl?:  {
      __typename: "Media",
      fileName: string,
      objectKey: string,
      size: number,
      dateUploaded: string,
      uploadedBy: string,
      type: string,
      customPrefix?: string | null,
      blurhash?: string | null,
    } | null,
    goals?:  Array< {
      __typename: "BrandGoals",
      platform: Platform,
      start: number,
      goal: number,
    } | null > | null,
    mission?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListBrandsQueryVariables = {
  id?: string | null,
  filter?: ModelBrandFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListBrandsQuery = {
  listBrands?:  {
    __typename: "ModelBrandConnection",
    items:  Array< {
      __typename: "Brand",
      id: string,
      name: string,
      connections?:  {
        __typename: "Connections",
        id: string,
        brandId: string,
        youtubeChannelName?: string | null,
        youtubeChannelId?: string | null,
        youtubeUserId?: string | null,
        youtubeChannelPictureUrl?: string | null,
        tiktok?: string | null,
        instagramUserId?: string | null,
        instagramUsername?: string | null,
        instagramProfilePictureUrl?: string | null,
        instagramTokenId?: string | null,
        facebookPageId?: string | null,
        facebookPageName?: string | null,
        facebookPagePictureUrl?: string | null,
        facebookTokenId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      connectionId?: string | null,
      categories?: Array< string | null > | null,
      status?:  {
        __typename: "ModelContentStatusConnection",
        items:  Array< {
          __typename: "ContentStatus",
          id: string,
          brandId: string,
          content?:  {
            __typename: "ModelContentConnection",
            nextToken?: string | null,
          } | null,
          contentIds: Array< string | null >,
          name: string,
          stage: ContentStage,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      statusOrder?: Array< string | null > | null,
      brandProfileUrl?:  {
        __typename: "Media",
        fileName: string,
        objectKey: string,
        size: number,
        dateUploaded: string,
        uploadedBy: string,
        type: string,
        customPrefix?: string | null,
        blurhash?: string | null,
      } | null,
      goals?:  Array< {
        __typename: "BrandGoals",
        platform: Platform,
        start: number,
        goal: number,
      } | null > | null,
      mission?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetBrandMemberQueryVariables = {
  id: string,
};

export type GetBrandMemberQuery = {
  getBrandMember?:  {
    __typename: "BrandMember",
    id: string,
    member?:  {
      __typename: "Member",
      id: string,
      name: string,
      email: string,
      brandMembers?:  {
        __typename: "ModelBrandMemberConnection",
        items:  Array< {
          __typename: "BrandMember",
          id: string,
          member?:  {
            __typename: "Member",
            id: string,
            name: string,
            email: string,
            subscription?: MemberPlan | null,
            stripeCustomerId?: string | null,
            lastLogin?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          memberId: string,
          brandId: string,
          brand:  {
            __typename: "Brand",
            id: string,
            name: string,
            connectionId?: string | null,
            categories?: Array< string | null > | null,
            statusOrder?: Array< string | null > | null,
            mission?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          accessLevel: AccessLevel,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      subscription?: MemberPlan | null,
      stripeCustomerId?: string | null,
      lastLogin?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    memberId: string,
    brandId: string,
    brand:  {
      __typename: "Brand",
      id: string,
      name: string,
      connections?:  {
        __typename: "Connections",
        id: string,
        brandId: string,
        youtubeChannelName?: string | null,
        youtubeChannelId?: string | null,
        youtubeUserId?: string | null,
        youtubeChannelPictureUrl?: string | null,
        tiktok?: string | null,
        instagramUserId?: string | null,
        instagramUsername?: string | null,
        instagramProfilePictureUrl?: string | null,
        instagramTokenId?: string | null,
        facebookPageId?: string | null,
        facebookPageName?: string | null,
        facebookPagePictureUrl?: string | null,
        facebookTokenId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      connectionId?: string | null,
      categories?: Array< string | null > | null,
      status?:  {
        __typename: "ModelContentStatusConnection",
        items:  Array< {
          __typename: "ContentStatus",
          id: string,
          brandId: string,
          content?:  {
            __typename: "ModelContentConnection",
            nextToken?: string | null,
          } | null,
          contentIds: Array< string | null >,
          name: string,
          stage: ContentStage,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      statusOrder?: Array< string | null > | null,
      brandProfileUrl?:  {
        __typename: "Media",
        fileName: string,
        objectKey: string,
        size: number,
        dateUploaded: string,
        uploadedBy: string,
        type: string,
        customPrefix?: string | null,
        blurhash?: string | null,
      } | null,
      goals?:  Array< {
        __typename: "BrandGoals",
        platform: Platform,
        start: number,
        goal: number,
      } | null > | null,
      mission?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    accessLevel: AccessLevel,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListBrandMembersQueryVariables = {
  id?: string | null,
  filter?: ModelBrandMemberFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListBrandMembersQuery = {
  listBrandMembers?:  {
    __typename: "ModelBrandMemberConnection",
    items:  Array< {
      __typename: "BrandMember",
      id: string,
      member?:  {
        __typename: "Member",
        id: string,
        name: string,
        email: string,
        brandMembers?:  {
          __typename: "ModelBrandMemberConnection",
          items:  Array< {
            __typename: "BrandMember",
            id: string,
            memberId: string,
            brandId: string,
            accessLevel: AccessLevel,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        subscription?: MemberPlan | null,
        stripeCustomerId?: string | null,
        lastLogin?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      memberId: string,
      brandId: string,
      brand:  {
        __typename: "Brand",
        id: string,
        name: string,
        connections?:  {
          __typename: "Connections",
          id: string,
          brandId: string,
          youtubeChannelName?: string | null,
          youtubeChannelId?: string | null,
          youtubeUserId?: string | null,
          youtubeChannelPictureUrl?: string | null,
          tiktok?: string | null,
          instagramUserId?: string | null,
          instagramUsername?: string | null,
          instagramProfilePictureUrl?: string | null,
          instagramTokenId?: string | null,
          facebookPageId?: string | null,
          facebookPageName?: string | null,
          facebookPagePictureUrl?: string | null,
          facebookTokenId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        connectionId?: string | null,
        categories?: Array< string | null > | null,
        status?:  {
          __typename: "ModelContentStatusConnection",
          items:  Array< {
            __typename: "ContentStatus",
            id: string,
            brandId: string,
            contentIds: Array< string | null >,
            name: string,
            stage: ContentStage,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        statusOrder?: Array< string | null > | null,
        brandProfileUrl?:  {
          __typename: "Media",
          fileName: string,
          objectKey: string,
          size: number,
          dateUploaded: string,
          uploadedBy: string,
          type: string,
          customPrefix?: string | null,
          blurhash?: string | null,
        } | null,
        goals?:  Array< {
          __typename: "BrandGoals",
          platform: Platform,
          start: number,
          goal: number,
        } | null > | null,
        mission?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      accessLevel: AccessLevel,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetConnectionsQueryVariables = {
  id: string,
};

export type GetConnectionsQuery = {
  getConnections?:  {
    __typename: "Connections",
    id: string,
    brandId: string,
    youtubeChannelName?: string | null,
    youtubeChannelId?: string | null,
    youtubeUserId?: string | null,
    youtubeChannelPictureUrl?: string | null,
    tiktok?: string | null,
    instagramUserId?: string | null,
    instagramUsername?: string | null,
    instagramProfilePictureUrl?: string | null,
    instagramTokenId?: string | null,
    facebookPageId?: string | null,
    facebookPageName?: string | null,
    facebookPagePictureUrl?: string | null,
    facebookTokenId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListConnectionsQueryVariables = {
  id?: string | null,
  filter?: ModelConnectionsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListConnectionsQuery = {
  listConnections?:  {
    __typename: "ModelConnectionsConnection",
    items:  Array< {
      __typename: "Connections",
      id: string,
      brandId: string,
      youtubeChannelName?: string | null,
      youtubeChannelId?: string | null,
      youtubeUserId?: string | null,
      youtubeChannelPictureUrl?: string | null,
      tiktok?: string | null,
      instagramUserId?: string | null,
      instagramUsername?: string | null,
      instagramProfilePictureUrl?: string | null,
      instagramTokenId?: string | null,
      facebookPageId?: string | null,
      facebookPageName?: string | null,
      facebookPagePictureUrl?: string | null,
      facebookTokenId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetContentStatusQueryVariables = {
  id: string,
};

export type GetContentStatusQuery = {
  getContentStatus?:  {
    __typename: "ContentStatus",
    id: string,
    brandId: string,
    content?:  {
      __typename: "ModelContentConnection",
      items:  Array< {
        __typename: "Content",
        id: string,
        assignedTo?:  {
          __typename: "Member",
          id: string,
          name: string,
          email: string,
          brandMembers?:  {
            __typename: "ModelBrandMemberConnection",
            nextToken?: string | null,
          } | null,
          subscription?: MemberPlan | null,
          stripeCustomerId?: string | null,
          lastLogin?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        assignedMemberId?: string | null,
        brandId: string,
        categories?: Array< string | null > | null,
        createdAt: string,
        createdBy: string,
        contentScheduleId?: string | null,
        dueDate?: string | null,
        media?:  Array< {
          __typename: "Media",
          fileName: string,
          objectKey: string,
          size: number,
          dateUploaded: string,
          uploadedBy: string,
          type: string,
          customPrefix?: string | null,
          blurhash?: string | null,
        } | null > | null,
        platforms?:  Array< {
          __typename: "PlatformData",
          platform: Platform,
          status: ContentPublishStatus,
          postUrl?: string | null,
          publishDate?: string | null,
        } | null > | null,
        publishDate?: string | null,
        published?: boolean | null,
        status:  {
          __typename: "ContentStatus",
          id: string,
          brandId: string,
          content?:  {
            __typename: "ModelContentConnection",
            nextToken?: string | null,
          } | null,
          contentIds: Array< string | null >,
          name: string,
          stage: ContentStage,
          createdAt: string,
          updatedAt: string,
        },
        statusId: string,
        markdown?: string | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          items:  Array< {
            __typename: "Comment",
            id: string,
            createdAt: string,
            contentId: string,
            message: string,
            memberId: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        checklist?:  Array< {
          __typename: "ContentChecklist",
          id: string,
          title: string,
          completed: boolean,
        } | null > | null,
        approvalStatus?: ApprovalStatus | null,
        thumbnail?:  {
          __typename: "Media",
          fileName: string,
          objectKey: string,
          size: number,
          dateUploaded: string,
          uploadedBy: string,
          type: string,
          customPrefix?: string | null,
          blurhash?: string | null,
        } | null,
        title: string,
        description?: string | null,
        caption?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    contentIds: Array< string | null >,
    name: string,
    stage: ContentStage,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListContentStatusesQueryVariables = {
  id?: string | null,
  filter?: ModelContentStatusFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListContentStatusesQuery = {
  listContentStatuses?:  {
    __typename: "ModelContentStatusConnection",
    items:  Array< {
      __typename: "ContentStatus",
      id: string,
      brandId: string,
      content?:  {
        __typename: "ModelContentConnection",
        items:  Array< {
          __typename: "Content",
          id: string,
          assignedTo?:  {
            __typename: "Member",
            id: string,
            name: string,
            email: string,
            subscription?: MemberPlan | null,
            stripeCustomerId?: string | null,
            lastLogin?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          assignedMemberId?: string | null,
          brandId: string,
          categories?: Array< string | null > | null,
          createdAt: string,
          createdBy: string,
          contentScheduleId?: string | null,
          dueDate?: string | null,
          media?:  Array< {
            __typename: "Media",
            fileName: string,
            objectKey: string,
            size: number,
            dateUploaded: string,
            uploadedBy: string,
            type: string,
            customPrefix?: string | null,
            blurhash?: string | null,
          } | null > | null,
          platforms?:  Array< {
            __typename: "PlatformData",
            platform: Platform,
            status: ContentPublishStatus,
            postUrl?: string | null,
            publishDate?: string | null,
          } | null > | null,
          publishDate?: string | null,
          published?: boolean | null,
          status:  {
            __typename: "ContentStatus",
            id: string,
            brandId: string,
            contentIds: Array< string | null >,
            name: string,
            stage: ContentStage,
            createdAt: string,
            updatedAt: string,
          },
          statusId: string,
          markdown?: string | null,
          comments?:  {
            __typename: "ModelCommentConnection",
            nextToken?: string | null,
          } | null,
          checklist?:  Array< {
            __typename: "ContentChecklist",
            id: string,
            title: string,
            completed: boolean,
          } | null > | null,
          approvalStatus?: ApprovalStatus | null,
          thumbnail?:  {
            __typename: "Media",
            fileName: string,
            objectKey: string,
            size: number,
            dateUploaded: string,
            uploadedBy: string,
            type: string,
            customPrefix?: string | null,
            blurhash?: string | null,
          } | null,
          title: string,
          description?: string | null,
          caption?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contentIds: Array< string | null >,
      name: string,
      stage: ContentStage,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetContentQueryVariables = {
  id: string,
};

export type GetContentQuery = {
  getContent?:  {
    __typename: "Content",
    id: string,
    assignedTo?:  {
      __typename: "Member",
      id: string,
      name: string,
      email: string,
      brandMembers?:  {
        __typename: "ModelBrandMemberConnection",
        items:  Array< {
          __typename: "BrandMember",
          id: string,
          member?:  {
            __typename: "Member",
            id: string,
            name: string,
            email: string,
            subscription?: MemberPlan | null,
            stripeCustomerId?: string | null,
            lastLogin?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          memberId: string,
          brandId: string,
          brand:  {
            __typename: "Brand",
            id: string,
            name: string,
            connectionId?: string | null,
            categories?: Array< string | null > | null,
            statusOrder?: Array< string | null > | null,
            mission?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          accessLevel: AccessLevel,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      subscription?: MemberPlan | null,
      stripeCustomerId?: string | null,
      lastLogin?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    assignedMemberId?: string | null,
    brandId: string,
    categories?: Array< string | null > | null,
    createdAt: string,
    createdBy: string,
    contentScheduleId?: string | null,
    dueDate?: string | null,
    media?:  Array< {
      __typename: "Media",
      fileName: string,
      objectKey: string,
      size: number,
      dateUploaded: string,
      uploadedBy: string,
      type: string,
      customPrefix?: string | null,
      blurhash?: string | null,
    } | null > | null,
    platforms?:  Array< {
      __typename: "PlatformData",
      platform: Platform,
      status: ContentPublishStatus,
      postUrl?: string | null,
      publishDate?: string | null,
    } | null > | null,
    publishDate?: string | null,
    published?: boolean | null,
    status:  {
      __typename: "ContentStatus",
      id: string,
      brandId: string,
      content?:  {
        __typename: "ModelContentConnection",
        items:  Array< {
          __typename: "Content",
          id: string,
          assignedTo?:  {
            __typename: "Member",
            id: string,
            name: string,
            email: string,
            subscription?: MemberPlan | null,
            stripeCustomerId?: string | null,
            lastLogin?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          assignedMemberId?: string | null,
          brandId: string,
          categories?: Array< string | null > | null,
          createdAt: string,
          createdBy: string,
          contentScheduleId?: string | null,
          dueDate?: string | null,
          media?:  Array< {
            __typename: "Media",
            fileName: string,
            objectKey: string,
            size: number,
            dateUploaded: string,
            uploadedBy: string,
            type: string,
            customPrefix?: string | null,
            blurhash?: string | null,
          } | null > | null,
          platforms?:  Array< {
            __typename: "PlatformData",
            platform: Platform,
            status: ContentPublishStatus,
            postUrl?: string | null,
            publishDate?: string | null,
          } | null > | null,
          publishDate?: string | null,
          published?: boolean | null,
          status:  {
            __typename: "ContentStatus",
            id: string,
            brandId: string,
            contentIds: Array< string | null >,
            name: string,
            stage: ContentStage,
            createdAt: string,
            updatedAt: string,
          },
          statusId: string,
          markdown?: string | null,
          comments?:  {
            __typename: "ModelCommentConnection",
            nextToken?: string | null,
          } | null,
          checklist?:  Array< {
            __typename: "ContentChecklist",
            id: string,
            title: string,
            completed: boolean,
          } | null > | null,
          approvalStatus?: ApprovalStatus | null,
          thumbnail?:  {
            __typename: "Media",
            fileName: string,
            objectKey: string,
            size: number,
            dateUploaded: string,
            uploadedBy: string,
            type: string,
            customPrefix?: string | null,
            blurhash?: string | null,
          } | null,
          title: string,
          description?: string | null,
          caption?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contentIds: Array< string | null >,
      name: string,
      stage: ContentStage,
      createdAt: string,
      updatedAt: string,
    },
    statusId: string,
    markdown?: string | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        createdAt: string,
        contentId: string,
        message: string,
        memberId: string,
        member?:  {
          __typename: "Member",
          id: string,
          name: string,
          email: string,
          brandMembers?:  {
            __typename: "ModelBrandMemberConnection",
            nextToken?: string | null,
          } | null,
          subscription?: MemberPlan | null,
          stripeCustomerId?: string | null,
          lastLogin?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    checklist?:  Array< {
      __typename: "ContentChecklist",
      id: string,
      title: string,
      completed: boolean,
    } | null > | null,
    approvalStatus?: ApprovalStatus | null,
    thumbnail?:  {
      __typename: "Media",
      fileName: string,
      objectKey: string,
      size: number,
      dateUploaded: string,
      uploadedBy: string,
      type: string,
      customPrefix?: string | null,
      blurhash?: string | null,
    } | null,
    title: string,
    description?: string | null,
    caption?: string | null,
    updatedAt: string,
  } | null,
};

export type ListContentsQueryVariables = {
  filter?: ModelContentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListContentsQuery = {
  listContents?:  {
    __typename: "ModelContentConnection",
    items:  Array< {
      __typename: "Content",
      id: string,
      assignedTo?:  {
        __typename: "Member",
        id: string,
        name: string,
        email: string,
        brandMembers?:  {
          __typename: "ModelBrandMemberConnection",
          items:  Array< {
            __typename: "BrandMember",
            id: string,
            memberId: string,
            brandId: string,
            accessLevel: AccessLevel,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        subscription?: MemberPlan | null,
        stripeCustomerId?: string | null,
        lastLogin?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      assignedMemberId?: string | null,
      brandId: string,
      categories?: Array< string | null > | null,
      createdAt: string,
      createdBy: string,
      contentScheduleId?: string | null,
      dueDate?: string | null,
      media?:  Array< {
        __typename: "Media",
        fileName: string,
        objectKey: string,
        size: number,
        dateUploaded: string,
        uploadedBy: string,
        type: string,
        customPrefix?: string | null,
        blurhash?: string | null,
      } | null > | null,
      platforms?:  Array< {
        __typename: "PlatformData",
        platform: Platform,
        status: ContentPublishStatus,
        postUrl?: string | null,
        publishDate?: string | null,
      } | null > | null,
      publishDate?: string | null,
      published?: boolean | null,
      status:  {
        __typename: "ContentStatus",
        id: string,
        brandId: string,
        content?:  {
          __typename: "ModelContentConnection",
          items:  Array< {
            __typename: "Content",
            id: string,
            assignedMemberId?: string | null,
            brandId: string,
            categories?: Array< string | null > | null,
            createdAt: string,
            createdBy: string,
            contentScheduleId?: string | null,
            dueDate?: string | null,
            publishDate?: string | null,
            published?: boolean | null,
            statusId: string,
            markdown?: string | null,
            approvalStatus?: ApprovalStatus | null,
            title: string,
            description?: string | null,
            caption?: string | null,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        contentIds: Array< string | null >,
        name: string,
        stage: ContentStage,
        createdAt: string,
        updatedAt: string,
      },
      statusId: string,
      markdown?: string | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          createdAt: string,
          contentId: string,
          message: string,
          memberId: string,
          member?:  {
            __typename: "Member",
            id: string,
            name: string,
            email: string,
            subscription?: MemberPlan | null,
            stripeCustomerId?: string | null,
            lastLogin?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      checklist?:  Array< {
        __typename: "ContentChecklist",
        id: string,
        title: string,
        completed: boolean,
      } | null > | null,
      approvalStatus?: ApprovalStatus | null,
      thumbnail?:  {
        __typename: "Media",
        fileName: string,
        objectKey: string,
        size: number,
        dateUploaded: string,
        uploadedBy: string,
        type: string,
        customPrefix?: string | null,
        blurhash?: string | null,
      } | null,
      title: string,
      description?: string | null,
      caption?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetContentCategoryQueryVariables = {
  id: string,
};

export type GetContentCategoryQuery = {
  getContentCategory?:  {
    __typename: "ContentCategory",
    id: string,
    contentId: string,
    name: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListContentCategoriesQueryVariables = {
  filter?: ModelContentCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListContentCategoriesQuery = {
  listContentCategories?:  {
    __typename: "ModelContentCategoryConnection",
    items:  Array< {
      __typename: "ContentCategory",
      id: string,
      contentId: string,
      name: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCommentQueryVariables = {
  id: string,
};

export type GetCommentQuery = {
  getComment?:  {
    __typename: "Comment",
    id: string,
    createdAt: string,
    contentId: string,
    message: string,
    memberId: string,
    member?:  {
      __typename: "Member",
      id: string,
      name: string,
      email: string,
      brandMembers?:  {
        __typename: "ModelBrandMemberConnection",
        items:  Array< {
          __typename: "BrandMember",
          id: string,
          member?:  {
            __typename: "Member",
            id: string,
            name: string,
            email: string,
            subscription?: MemberPlan | null,
            stripeCustomerId?: string | null,
            lastLogin?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          memberId: string,
          brandId: string,
          brand:  {
            __typename: "Brand",
            id: string,
            name: string,
            connectionId?: string | null,
            categories?: Array< string | null > | null,
            statusOrder?: Array< string | null > | null,
            mission?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          accessLevel: AccessLevel,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      subscription?: MemberPlan | null,
      stripeCustomerId?: string | null,
      lastLogin?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    updatedAt: string,
  } | null,
};

export type ListCommentsQueryVariables = {
  filter?: ModelCommentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCommentsQuery = {
  listComments?:  {
    __typename: "ModelCommentConnection",
    items:  Array< {
      __typename: "Comment",
      id: string,
      createdAt: string,
      contentId: string,
      message: string,
      memberId: string,
      member?:  {
        __typename: "Member",
        id: string,
        name: string,
        email: string,
        brandMembers?:  {
          __typename: "ModelBrandMemberConnection",
          items:  Array< {
            __typename: "BrandMember",
            id: string,
            memberId: string,
            brandId: string,
            accessLevel: AccessLevel,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        subscription?: MemberPlan | null,
        stripeCustomerId?: string | null,
        lastLogin?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetContentScheduleQueryVariables = {
  id: string,
};

export type GetContentScheduleQuery = {
  getContentSchedule?:  {
    __typename: "ContentSchedule",
    id: string,
    publishDate: number,
    status: ScheduleStatus,
    contentId: string,
    brandId?: string | null,
    platformData:  Array< {
      __typename: "ContentSchedulePlatformData",
      platform: Platform,
      accountId: string,
      tokenId: string,
    } >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListContentSchedulesQueryVariables = {
  id?: string | null,
  filter?: ModelContentScheduleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListContentSchedulesQuery = {
  listContentSchedules?:  {
    __typename: "ModelContentScheduleConnection",
    items:  Array< {
      __typename: "ContentSchedule",
      id: string,
      publishDate: number,
      status: ScheduleStatus,
      contentId: string,
      brandId?: string | null,
      platformData:  Array< {
        __typename: "ContentSchedulePlatformData",
        platform: Platform,
        accountId: string,
        tokenId: string,
      } >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetMemberQueryVariables = {
  id: string,
};

export type GetMemberQuery = {
  getMember?:  {
    __typename: "Member",
    id: string,
    name: string,
    email: string,
    brandMembers?:  {
      __typename: "ModelBrandMemberConnection",
      items:  Array< {
        __typename: "BrandMember",
        id: string,
        member?:  {
          __typename: "Member",
          id: string,
          name: string,
          email: string,
          brandMembers?:  {
            __typename: "ModelBrandMemberConnection",
            nextToken?: string | null,
          } | null,
          subscription?: MemberPlan | null,
          stripeCustomerId?: string | null,
          lastLogin?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        memberId: string,
        brandId: string,
        brand:  {
          __typename: "Brand",
          id: string,
          name: string,
          connections?:  {
            __typename: "Connections",
            id: string,
            brandId: string,
            youtubeChannelName?: string | null,
            youtubeChannelId?: string | null,
            youtubeUserId?: string | null,
            youtubeChannelPictureUrl?: string | null,
            tiktok?: string | null,
            instagramUserId?: string | null,
            instagramUsername?: string | null,
            instagramProfilePictureUrl?: string | null,
            instagramTokenId?: string | null,
            facebookPageId?: string | null,
            facebookPageName?: string | null,
            facebookPagePictureUrl?: string | null,
            facebookTokenId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          connectionId?: string | null,
          categories?: Array< string | null > | null,
          status?:  {
            __typename: "ModelContentStatusConnection",
            nextToken?: string | null,
          } | null,
          statusOrder?: Array< string | null > | null,
          brandProfileUrl?:  {
            __typename: "Media",
            fileName: string,
            objectKey: string,
            size: number,
            dateUploaded: string,
            uploadedBy: string,
            type: string,
            customPrefix?: string | null,
            blurhash?: string | null,
          } | null,
          goals?:  Array< {
            __typename: "BrandGoals",
            platform: Platform,
            start: number,
            goal: number,
          } | null > | null,
          mission?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        accessLevel: AccessLevel,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    subscription?: MemberPlan | null,
    stripeCustomerId?: string | null,
    lastLogin?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListMembersQueryVariables = {
  id?: string | null,
  filter?: ModelMemberFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListMembersQuery = {
  listMembers?:  {
    __typename: "ModelMemberConnection",
    items:  Array< {
      __typename: "Member",
      id: string,
      name: string,
      email: string,
      brandMembers?:  {
        __typename: "ModelBrandMemberConnection",
        items:  Array< {
          __typename: "BrandMember",
          id: string,
          member?:  {
            __typename: "Member",
            id: string,
            name: string,
            email: string,
            subscription?: MemberPlan | null,
            stripeCustomerId?: string | null,
            lastLogin?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          memberId: string,
          brandId: string,
          brand:  {
            __typename: "Brand",
            id: string,
            name: string,
            connectionId?: string | null,
            categories?: Array< string | null > | null,
            statusOrder?: Array< string | null > | null,
            mission?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          accessLevel: AccessLevel,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      subscription?: MemberPlan | null,
      stripeCustomerId?: string | null,
      lastLogin?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTokenQueryVariables = {
  id: string,
};

export type GetTokenQuery = {
  getToken?:  {
    __typename: "Token",
    id: string,
    createdAt: string,
    brandId: string,
    accessToken: string,
    refreshToken?: string | null,
    tokenType?: string | null,
    expiryDate: string,
    platform: Platform,
    updatedAt: string,
  } | null,
};

export type ListTokensQueryVariables = {
  id?: string | null,
  filter?: ModelTokenFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListTokensQuery = {
  listTokens?:  {
    __typename: "ModelTokenConnection",
    items:  Array< {
      __typename: "Token",
      id: string,
      createdAt: string,
      brandId: string,
      accessToken: string,
      refreshToken?: string | null,
      tokenType?: string | null,
      expiryDate: string,
      platform: Platform,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BrandMembersByMemberIdQueryVariables = {
  memberId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBrandMemberFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BrandMembersByMemberIdQuery = {
  brandMembersByMemberId?:  {
    __typename: "ModelBrandMemberConnection",
    items:  Array< {
      __typename: "BrandMember",
      id: string,
      member?:  {
        __typename: "Member",
        id: string,
        name: string,
        email: string,
        brandMembers?:  {
          __typename: "ModelBrandMemberConnection",
          items:  Array< {
            __typename: "BrandMember",
            id: string,
            memberId: string,
            brandId: string,
            accessLevel: AccessLevel,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        subscription?: MemberPlan | null,
        stripeCustomerId?: string | null,
        lastLogin?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      memberId: string,
      brandId: string,
      brand:  {
        __typename: "Brand",
        id: string,
        name: string,
        connections?:  {
          __typename: "Connections",
          id: string,
          brandId: string,
          youtubeChannelName?: string | null,
          youtubeChannelId?: string | null,
          youtubeUserId?: string | null,
          youtubeChannelPictureUrl?: string | null,
          tiktok?: string | null,
          instagramUserId?: string | null,
          instagramUsername?: string | null,
          instagramProfilePictureUrl?: string | null,
          instagramTokenId?: string | null,
          facebookPageId?: string | null,
          facebookPageName?: string | null,
          facebookPagePictureUrl?: string | null,
          facebookTokenId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        connectionId?: string | null,
        categories?: Array< string | null > | null,
        status?:  {
          __typename: "ModelContentStatusConnection",
          items:  Array< {
            __typename: "ContentStatus",
            id: string,
            brandId: string,
            contentIds: Array< string | null >,
            name: string,
            stage: ContentStage,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        statusOrder?: Array< string | null > | null,
        brandProfileUrl?:  {
          __typename: "Media",
          fileName: string,
          objectKey: string,
          size: number,
          dateUploaded: string,
          uploadedBy: string,
          type: string,
          customPrefix?: string | null,
          blurhash?: string | null,
        } | null,
        goals?:  Array< {
          __typename: "BrandGoals",
          platform: Platform,
          start: number,
          goal: number,
        } | null > | null,
        mission?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      accessLevel: AccessLevel,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BrandMembersByBrandIdQueryVariables = {
  brandId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBrandMemberFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BrandMembersByBrandIdQuery = {
  brandMembersByBrandId?:  {
    __typename: "ModelBrandMemberConnection",
    items:  Array< {
      __typename: "BrandMember",
      id: string,
      member?:  {
        __typename: "Member",
        id: string,
        name: string,
        email: string,
        brandMembers?:  {
          __typename: "ModelBrandMemberConnection",
          items:  Array< {
            __typename: "BrandMember",
            id: string,
            memberId: string,
            brandId: string,
            accessLevel: AccessLevel,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        subscription?: MemberPlan | null,
        stripeCustomerId?: string | null,
        lastLogin?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      memberId: string,
      brandId: string,
      brand:  {
        __typename: "Brand",
        id: string,
        name: string,
        connections?:  {
          __typename: "Connections",
          id: string,
          brandId: string,
          youtubeChannelName?: string | null,
          youtubeChannelId?: string | null,
          youtubeUserId?: string | null,
          youtubeChannelPictureUrl?: string | null,
          tiktok?: string | null,
          instagramUserId?: string | null,
          instagramUsername?: string | null,
          instagramProfilePictureUrl?: string | null,
          instagramTokenId?: string | null,
          facebookPageId?: string | null,
          facebookPageName?: string | null,
          facebookPagePictureUrl?: string | null,
          facebookTokenId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        connectionId?: string | null,
        categories?: Array< string | null > | null,
        status?:  {
          __typename: "ModelContentStatusConnection",
          items:  Array< {
            __typename: "ContentStatus",
            id: string,
            brandId: string,
            contentIds: Array< string | null >,
            name: string,
            stage: ContentStage,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        statusOrder?: Array< string | null > | null,
        brandProfileUrl?:  {
          __typename: "Media",
          fileName: string,
          objectKey: string,
          size: number,
          dateUploaded: string,
          uploadedBy: string,
          type: string,
          customPrefix?: string | null,
          blurhash?: string | null,
        } | null,
        goals?:  Array< {
          __typename: "BrandGoals",
          platform: Platform,
          start: number,
          goal: number,
        } | null > | null,
        mission?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      accessLevel: AccessLevel,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListBrandMembersByBrandIdQueryVariables = {
  brandId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBrandMemberFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBrandMembersByBrandIdQuery = {
  listBrandMembersByBrandId?:  {
    __typename: "ModelBrandMemberConnection",
    items:  Array< {
      __typename: "BrandMember",
      id: string,
      member?:  {
        __typename: "Member",
        id: string,
        name: string,
        email: string,
        brandMembers?:  {
          __typename: "ModelBrandMemberConnection",
          items:  Array< {
            __typename: "BrandMember",
            id: string,
            memberId: string,
            brandId: string,
            accessLevel: AccessLevel,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        subscription?: MemberPlan | null,
        stripeCustomerId?: string | null,
        lastLogin?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      memberId: string,
      brandId: string,
      brand:  {
        __typename: "Brand",
        id: string,
        name: string,
        connections?:  {
          __typename: "Connections",
          id: string,
          brandId: string,
          youtubeChannelName?: string | null,
          youtubeChannelId?: string | null,
          youtubeUserId?: string | null,
          youtubeChannelPictureUrl?: string | null,
          tiktok?: string | null,
          instagramUserId?: string | null,
          instagramUsername?: string | null,
          instagramProfilePictureUrl?: string | null,
          instagramTokenId?: string | null,
          facebookPageId?: string | null,
          facebookPageName?: string | null,
          facebookPagePictureUrl?: string | null,
          facebookTokenId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        connectionId?: string | null,
        categories?: Array< string | null > | null,
        status?:  {
          __typename: "ModelContentStatusConnection",
          items:  Array< {
            __typename: "ContentStatus",
            id: string,
            brandId: string,
            contentIds: Array< string | null >,
            name: string,
            stage: ContentStage,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        statusOrder?: Array< string | null > | null,
        brandProfileUrl?:  {
          __typename: "Media",
          fileName: string,
          objectKey: string,
          size: number,
          dateUploaded: string,
          uploadedBy: string,
          type: string,
          customPrefix?: string | null,
          blurhash?: string | null,
        } | null,
        goals?:  Array< {
          __typename: "BrandGoals",
          platform: Platform,
          start: number,
          goal: number,
        } | null > | null,
        mission?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      accessLevel: AccessLevel,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ConnectionsByBrandIdQueryVariables = {
  brandId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelConnectionsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ConnectionsByBrandIdQuery = {
  connectionsByBrandId?:  {
    __typename: "ModelConnectionsConnection",
    items:  Array< {
      __typename: "Connections",
      id: string,
      brandId: string,
      youtubeChannelName?: string | null,
      youtubeChannelId?: string | null,
      youtubeUserId?: string | null,
      youtubeChannelPictureUrl?: string | null,
      tiktok?: string | null,
      instagramUserId?: string | null,
      instagramUsername?: string | null,
      instagramProfilePictureUrl?: string | null,
      instagramTokenId?: string | null,
      facebookPageId?: string | null,
      facebookPageName?: string | null,
      facebookPagePictureUrl?: string | null,
      facebookTokenId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ContentStatusesByBrandIdQueryVariables = {
  brandId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelContentStatusFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ContentStatusesByBrandIdQuery = {
  contentStatusesByBrandId?:  {
    __typename: "ModelContentStatusConnection",
    items:  Array< {
      __typename: "ContentStatus",
      id: string,
      brandId: string,
      content?:  {
        __typename: "ModelContentConnection",
        items:  Array< {
          __typename: "Content",
          id: string,
          assignedTo?:  {
            __typename: "Member",
            id: string,
            name: string,
            email: string,
            subscription?: MemberPlan | null,
            stripeCustomerId?: string | null,
            lastLogin?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          assignedMemberId?: string | null,
          brandId: string,
          categories?: Array< string | null > | null,
          createdAt: string,
          createdBy: string,
          contentScheduleId?: string | null,
          dueDate?: string | null,
          media?:  Array< {
            __typename: "Media",
            fileName: string,
            objectKey: string,
            size: number,
            dateUploaded: string,
            uploadedBy: string,
            type: string,
            customPrefix?: string | null,
            blurhash?: string | null,
          } | null > | null,
          platforms?:  Array< {
            __typename: "PlatformData",
            platform: Platform,
            status: ContentPublishStatus,
            postUrl?: string | null,
            publishDate?: string | null,
          } | null > | null,
          publishDate?: string | null,
          published?: boolean | null,
          status:  {
            __typename: "ContentStatus",
            id: string,
            brandId: string,
            contentIds: Array< string | null >,
            name: string,
            stage: ContentStage,
            createdAt: string,
            updatedAt: string,
          },
          statusId: string,
          markdown?: string | null,
          comments?:  {
            __typename: "ModelCommentConnection",
            nextToken?: string | null,
          } | null,
          checklist?:  Array< {
            __typename: "ContentChecklist",
            id: string,
            title: string,
            completed: boolean,
          } | null > | null,
          approvalStatus?: ApprovalStatus | null,
          thumbnail?:  {
            __typename: "Media",
            fileName: string,
            objectKey: string,
            size: number,
            dateUploaded: string,
            uploadedBy: string,
            type: string,
            customPrefix?: string | null,
            blurhash?: string | null,
          } | null,
          title: string,
          description?: string | null,
          caption?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contentIds: Array< string | null >,
      name: string,
      stage: ContentStage,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ContentsByIdQueryVariables = {
  id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelContentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ContentsByIdQuery = {
  contentsById?:  {
    __typename: "ModelContentConnection",
    items:  Array< {
      __typename: "Content",
      id: string,
      assignedTo?:  {
        __typename: "Member",
        id: string,
        name: string,
        email: string,
        brandMembers?:  {
          __typename: "ModelBrandMemberConnection",
          items:  Array< {
            __typename: "BrandMember",
            id: string,
            memberId: string,
            brandId: string,
            accessLevel: AccessLevel,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        subscription?: MemberPlan | null,
        stripeCustomerId?: string | null,
        lastLogin?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      assignedMemberId?: string | null,
      brandId: string,
      categories?: Array< string | null > | null,
      createdAt: string,
      createdBy: string,
      contentScheduleId?: string | null,
      dueDate?: string | null,
      media?:  Array< {
        __typename: "Media",
        fileName: string,
        objectKey: string,
        size: number,
        dateUploaded: string,
        uploadedBy: string,
        type: string,
        customPrefix?: string | null,
        blurhash?: string | null,
      } | null > | null,
      platforms?:  Array< {
        __typename: "PlatformData",
        platform: Platform,
        status: ContentPublishStatus,
        postUrl?: string | null,
        publishDate?: string | null,
      } | null > | null,
      publishDate?: string | null,
      published?: boolean | null,
      status:  {
        __typename: "ContentStatus",
        id: string,
        brandId: string,
        content?:  {
          __typename: "ModelContentConnection",
          items:  Array< {
            __typename: "Content",
            id: string,
            assignedMemberId?: string | null,
            brandId: string,
            categories?: Array< string | null > | null,
            createdAt: string,
            createdBy: string,
            contentScheduleId?: string | null,
            dueDate?: string | null,
            publishDate?: string | null,
            published?: boolean | null,
            statusId: string,
            markdown?: string | null,
            approvalStatus?: ApprovalStatus | null,
            title: string,
            description?: string | null,
            caption?: string | null,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        contentIds: Array< string | null >,
        name: string,
        stage: ContentStage,
        createdAt: string,
        updatedAt: string,
      },
      statusId: string,
      markdown?: string | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          createdAt: string,
          contentId: string,
          message: string,
          memberId: string,
          member?:  {
            __typename: "Member",
            id: string,
            name: string,
            email: string,
            subscription?: MemberPlan | null,
            stripeCustomerId?: string | null,
            lastLogin?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      checklist?:  Array< {
        __typename: "ContentChecklist",
        id: string,
        title: string,
        completed: boolean,
      } | null > | null,
      approvalStatus?: ApprovalStatus | null,
      thumbnail?:  {
        __typename: "Media",
        fileName: string,
        objectKey: string,
        size: number,
        dateUploaded: string,
        uploadedBy: string,
        type: string,
        customPrefix?: string | null,
        blurhash?: string | null,
      } | null,
      title: string,
      description?: string | null,
      caption?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ContentsByBrandIdQueryVariables = {
  brandId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelContentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ContentsByBrandIdQuery = {
  contentsByBrandId?:  {
    __typename: "ModelContentConnection",
    items:  Array< {
      __typename: "Content",
      id: string,
      assignedTo?:  {
        __typename: "Member",
        id: string,
        name: string,
        email: string,
        brandMembers?:  {
          __typename: "ModelBrandMemberConnection",
          items:  Array< {
            __typename: "BrandMember",
            id: string,
            memberId: string,
            brandId: string,
            accessLevel: AccessLevel,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        subscription?: MemberPlan | null,
        stripeCustomerId?: string | null,
        lastLogin?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      assignedMemberId?: string | null,
      brandId: string,
      categories?: Array< string | null > | null,
      createdAt: string,
      createdBy: string,
      contentScheduleId?: string | null,
      dueDate?: string | null,
      media?:  Array< {
        __typename: "Media",
        fileName: string,
        objectKey: string,
        size: number,
        dateUploaded: string,
        uploadedBy: string,
        type: string,
        customPrefix?: string | null,
        blurhash?: string | null,
      } | null > | null,
      platforms?:  Array< {
        __typename: "PlatformData",
        platform: Platform,
        status: ContentPublishStatus,
        postUrl?: string | null,
        publishDate?: string | null,
      } | null > | null,
      publishDate?: string | null,
      published?: boolean | null,
      status:  {
        __typename: "ContentStatus",
        id: string,
        brandId: string,
        content?:  {
          __typename: "ModelContentConnection",
          items:  Array< {
            __typename: "Content",
            id: string,
            assignedMemberId?: string | null,
            brandId: string,
            categories?: Array< string | null > | null,
            createdAt: string,
            createdBy: string,
            contentScheduleId?: string | null,
            dueDate?: string | null,
            publishDate?: string | null,
            published?: boolean | null,
            statusId: string,
            markdown?: string | null,
            approvalStatus?: ApprovalStatus | null,
            title: string,
            description?: string | null,
            caption?: string | null,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        contentIds: Array< string | null >,
        name: string,
        stage: ContentStage,
        createdAt: string,
        updatedAt: string,
      },
      statusId: string,
      markdown?: string | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          createdAt: string,
          contentId: string,
          message: string,
          memberId: string,
          member?:  {
            __typename: "Member",
            id: string,
            name: string,
            email: string,
            subscription?: MemberPlan | null,
            stripeCustomerId?: string | null,
            lastLogin?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      checklist?:  Array< {
        __typename: "ContentChecklist",
        id: string,
        title: string,
        completed: boolean,
      } | null > | null,
      approvalStatus?: ApprovalStatus | null,
      thumbnail?:  {
        __typename: "Media",
        fileName: string,
        objectKey: string,
        size: number,
        dateUploaded: string,
        uploadedBy: string,
        type: string,
        customPrefix?: string | null,
        blurhash?: string | null,
      } | null,
      title: string,
      description?: string | null,
      caption?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListContentByBrandIdQueryVariables = {
  brandId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelContentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListContentByBrandIdQuery = {
  listContentByBrandId?:  {
    __typename: "ModelContentConnection",
    items:  Array< {
      __typename: "Content",
      id: string,
      assignedTo?:  {
        __typename: "Member",
        id: string,
        name: string,
        email: string,
        brandMembers?:  {
          __typename: "ModelBrandMemberConnection",
          items:  Array< {
            __typename: "BrandMember",
            id: string,
            memberId: string,
            brandId: string,
            accessLevel: AccessLevel,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        subscription?: MemberPlan | null,
        stripeCustomerId?: string | null,
        lastLogin?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      assignedMemberId?: string | null,
      brandId: string,
      categories?: Array< string | null > | null,
      createdAt: string,
      createdBy: string,
      contentScheduleId?: string | null,
      dueDate?: string | null,
      media?:  Array< {
        __typename: "Media",
        fileName: string,
        objectKey: string,
        size: number,
        dateUploaded: string,
        uploadedBy: string,
        type: string,
        customPrefix?: string | null,
        blurhash?: string | null,
      } | null > | null,
      platforms?:  Array< {
        __typename: "PlatformData",
        platform: Platform,
        status: ContentPublishStatus,
        postUrl?: string | null,
        publishDate?: string | null,
      } | null > | null,
      publishDate?: string | null,
      published?: boolean | null,
      status:  {
        __typename: "ContentStatus",
        id: string,
        brandId: string,
        content?:  {
          __typename: "ModelContentConnection",
          items:  Array< {
            __typename: "Content",
            id: string,
            assignedMemberId?: string | null,
            brandId: string,
            categories?: Array< string | null > | null,
            createdAt: string,
            createdBy: string,
            contentScheduleId?: string | null,
            dueDate?: string | null,
            publishDate?: string | null,
            published?: boolean | null,
            statusId: string,
            markdown?: string | null,
            approvalStatus?: ApprovalStatus | null,
            title: string,
            description?: string | null,
            caption?: string | null,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        contentIds: Array< string | null >,
        name: string,
        stage: ContentStage,
        createdAt: string,
        updatedAt: string,
      },
      statusId: string,
      markdown?: string | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          createdAt: string,
          contentId: string,
          message: string,
          memberId: string,
          member?:  {
            __typename: "Member",
            id: string,
            name: string,
            email: string,
            subscription?: MemberPlan | null,
            stripeCustomerId?: string | null,
            lastLogin?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      checklist?:  Array< {
        __typename: "ContentChecklist",
        id: string,
        title: string,
        completed: boolean,
      } | null > | null,
      approvalStatus?: ApprovalStatus | null,
      thumbnail?:  {
        __typename: "Media",
        fileName: string,
        objectKey: string,
        size: number,
        dateUploaded: string,
        uploadedBy: string,
        type: string,
        customPrefix?: string | null,
        blurhash?: string | null,
      } | null,
      title: string,
      description?: string | null,
      caption?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListContentByBrandIdAndCreatedAtQueryVariables = {
  brandId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelContentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListContentByBrandIdAndCreatedAtQuery = {
  listContentByBrandIdAndCreatedAt?:  {
    __typename: "ModelContentConnection",
    items:  Array< {
      __typename: "Content",
      id: string,
      assignedTo?:  {
        __typename: "Member",
        id: string,
        name: string,
        email: string,
        brandMembers?:  {
          __typename: "ModelBrandMemberConnection",
          items:  Array< {
            __typename: "BrandMember",
            id: string,
            memberId: string,
            brandId: string,
            accessLevel: AccessLevel,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        subscription?: MemberPlan | null,
        stripeCustomerId?: string | null,
        lastLogin?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      assignedMemberId?: string | null,
      brandId: string,
      categories?: Array< string | null > | null,
      createdAt: string,
      createdBy: string,
      contentScheduleId?: string | null,
      dueDate?: string | null,
      media?:  Array< {
        __typename: "Media",
        fileName: string,
        objectKey: string,
        size: number,
        dateUploaded: string,
        uploadedBy: string,
        type: string,
        customPrefix?: string | null,
        blurhash?: string | null,
      } | null > | null,
      platforms?:  Array< {
        __typename: "PlatformData",
        platform: Platform,
        status: ContentPublishStatus,
        postUrl?: string | null,
        publishDate?: string | null,
      } | null > | null,
      publishDate?: string | null,
      published?: boolean | null,
      status:  {
        __typename: "ContentStatus",
        id: string,
        brandId: string,
        content?:  {
          __typename: "ModelContentConnection",
          items:  Array< {
            __typename: "Content",
            id: string,
            assignedMemberId?: string | null,
            brandId: string,
            categories?: Array< string | null > | null,
            createdAt: string,
            createdBy: string,
            contentScheduleId?: string | null,
            dueDate?: string | null,
            publishDate?: string | null,
            published?: boolean | null,
            statusId: string,
            markdown?: string | null,
            approvalStatus?: ApprovalStatus | null,
            title: string,
            description?: string | null,
            caption?: string | null,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        contentIds: Array< string | null >,
        name: string,
        stage: ContentStage,
        createdAt: string,
        updatedAt: string,
      },
      statusId: string,
      markdown?: string | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          createdAt: string,
          contentId: string,
          message: string,
          memberId: string,
          member?:  {
            __typename: "Member",
            id: string,
            name: string,
            email: string,
            subscription?: MemberPlan | null,
            stripeCustomerId?: string | null,
            lastLogin?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      checklist?:  Array< {
        __typename: "ContentChecklist",
        id: string,
        title: string,
        completed: boolean,
      } | null > | null,
      approvalStatus?: ApprovalStatus | null,
      thumbnail?:  {
        __typename: "Media",
        fileName: string,
        objectKey: string,
        size: number,
        dateUploaded: string,
        uploadedBy: string,
        type: string,
        customPrefix?: string | null,
        blurhash?: string | null,
      } | null,
      title: string,
      description?: string | null,
      caption?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListContentByBrandIdAndPublishDateQueryVariables = {
  brandId: string,
  publishDate?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelContentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListContentByBrandIdAndPublishDateQuery = {
  listContentByBrandIdAndPublishDate?:  {
    __typename: "ModelContentConnection",
    items:  Array< {
      __typename: "Content",
      id: string,
      assignedTo?:  {
        __typename: "Member",
        id: string,
        name: string,
        email: string,
        brandMembers?:  {
          __typename: "ModelBrandMemberConnection",
          items:  Array< {
            __typename: "BrandMember",
            id: string,
            memberId: string,
            brandId: string,
            accessLevel: AccessLevel,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        subscription?: MemberPlan | null,
        stripeCustomerId?: string | null,
        lastLogin?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      assignedMemberId?: string | null,
      brandId: string,
      categories?: Array< string | null > | null,
      createdAt: string,
      createdBy: string,
      contentScheduleId?: string | null,
      dueDate?: string | null,
      media?:  Array< {
        __typename: "Media",
        fileName: string,
        objectKey: string,
        size: number,
        dateUploaded: string,
        uploadedBy: string,
        type: string,
        customPrefix?: string | null,
        blurhash?: string | null,
      } | null > | null,
      platforms?:  Array< {
        __typename: "PlatformData",
        platform: Platform,
        status: ContentPublishStatus,
        postUrl?: string | null,
        publishDate?: string | null,
      } | null > | null,
      publishDate?: string | null,
      published?: boolean | null,
      status:  {
        __typename: "ContentStatus",
        id: string,
        brandId: string,
        content?:  {
          __typename: "ModelContentConnection",
          items:  Array< {
            __typename: "Content",
            id: string,
            assignedMemberId?: string | null,
            brandId: string,
            categories?: Array< string | null > | null,
            createdAt: string,
            createdBy: string,
            contentScheduleId?: string | null,
            dueDate?: string | null,
            publishDate?: string | null,
            published?: boolean | null,
            statusId: string,
            markdown?: string | null,
            approvalStatus?: ApprovalStatus | null,
            title: string,
            description?: string | null,
            caption?: string | null,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        contentIds: Array< string | null >,
        name: string,
        stage: ContentStage,
        createdAt: string,
        updatedAt: string,
      },
      statusId: string,
      markdown?: string | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          createdAt: string,
          contentId: string,
          message: string,
          memberId: string,
          member?:  {
            __typename: "Member",
            id: string,
            name: string,
            email: string,
            subscription?: MemberPlan | null,
            stripeCustomerId?: string | null,
            lastLogin?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      checklist?:  Array< {
        __typename: "ContentChecklist",
        id: string,
        title: string,
        completed: boolean,
      } | null > | null,
      approvalStatus?: ApprovalStatus | null,
      thumbnail?:  {
        __typename: "Media",
        fileName: string,
        objectKey: string,
        size: number,
        dateUploaded: string,
        uploadedBy: string,
        type: string,
        customPrefix?: string | null,
        blurhash?: string | null,
      } | null,
      title: string,
      description?: string | null,
      caption?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListContentByBrandIdAndApprovalStatusQueryVariables = {
  brandId: string,
  approvalStatus?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelContentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListContentByBrandIdAndApprovalStatusQuery = {
  listContentByBrandIdAndApprovalStatus?:  {
    __typename: "ModelContentConnection",
    items:  Array< {
      __typename: "Content",
      id: string,
      assignedTo?:  {
        __typename: "Member",
        id: string,
        name: string,
        email: string,
        brandMembers?:  {
          __typename: "ModelBrandMemberConnection",
          items:  Array< {
            __typename: "BrandMember",
            id: string,
            memberId: string,
            brandId: string,
            accessLevel: AccessLevel,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        subscription?: MemberPlan | null,
        stripeCustomerId?: string | null,
        lastLogin?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      assignedMemberId?: string | null,
      brandId: string,
      categories?: Array< string | null > | null,
      createdAt: string,
      createdBy: string,
      contentScheduleId?: string | null,
      dueDate?: string | null,
      media?:  Array< {
        __typename: "Media",
        fileName: string,
        objectKey: string,
        size: number,
        dateUploaded: string,
        uploadedBy: string,
        type: string,
        customPrefix?: string | null,
        blurhash?: string | null,
      } | null > | null,
      platforms?:  Array< {
        __typename: "PlatformData",
        platform: Platform,
        status: ContentPublishStatus,
        postUrl?: string | null,
        publishDate?: string | null,
      } | null > | null,
      publishDate?: string | null,
      published?: boolean | null,
      status:  {
        __typename: "ContentStatus",
        id: string,
        brandId: string,
        content?:  {
          __typename: "ModelContentConnection",
          items:  Array< {
            __typename: "Content",
            id: string,
            assignedMemberId?: string | null,
            brandId: string,
            categories?: Array< string | null > | null,
            createdAt: string,
            createdBy: string,
            contentScheduleId?: string | null,
            dueDate?: string | null,
            publishDate?: string | null,
            published?: boolean | null,
            statusId: string,
            markdown?: string | null,
            approvalStatus?: ApprovalStatus | null,
            title: string,
            description?: string | null,
            caption?: string | null,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        contentIds: Array< string | null >,
        name: string,
        stage: ContentStage,
        createdAt: string,
        updatedAt: string,
      },
      statusId: string,
      markdown?: string | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          createdAt: string,
          contentId: string,
          message: string,
          memberId: string,
          member?:  {
            __typename: "Member",
            id: string,
            name: string,
            email: string,
            subscription?: MemberPlan | null,
            stripeCustomerId?: string | null,
            lastLogin?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      checklist?:  Array< {
        __typename: "ContentChecklist",
        id: string,
        title: string,
        completed: boolean,
      } | null > | null,
      approvalStatus?: ApprovalStatus | null,
      thumbnail?:  {
        __typename: "Media",
        fileName: string,
        objectKey: string,
        size: number,
        dateUploaded: string,
        uploadedBy: string,
        type: string,
        customPrefix?: string | null,
        blurhash?: string | null,
      } | null,
      title: string,
      description?: string | null,
      caption?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListContentByStatusIdQueryVariables = {
  statusId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelContentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListContentByStatusIdQuery = {
  listContentByStatusId?:  {
    __typename: "ModelContentConnection",
    items:  Array< {
      __typename: "Content",
      id: string,
      assignedTo?:  {
        __typename: "Member",
        id: string,
        name: string,
        email: string,
        brandMembers?:  {
          __typename: "ModelBrandMemberConnection",
          items:  Array< {
            __typename: "BrandMember",
            id: string,
            memberId: string,
            brandId: string,
            accessLevel: AccessLevel,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        subscription?: MemberPlan | null,
        stripeCustomerId?: string | null,
        lastLogin?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      assignedMemberId?: string | null,
      brandId: string,
      categories?: Array< string | null > | null,
      createdAt: string,
      createdBy: string,
      contentScheduleId?: string | null,
      dueDate?: string | null,
      media?:  Array< {
        __typename: "Media",
        fileName: string,
        objectKey: string,
        size: number,
        dateUploaded: string,
        uploadedBy: string,
        type: string,
        customPrefix?: string | null,
        blurhash?: string | null,
      } | null > | null,
      platforms?:  Array< {
        __typename: "PlatformData",
        platform: Platform,
        status: ContentPublishStatus,
        postUrl?: string | null,
        publishDate?: string | null,
      } | null > | null,
      publishDate?: string | null,
      published?: boolean | null,
      status:  {
        __typename: "ContentStatus",
        id: string,
        brandId: string,
        content?:  {
          __typename: "ModelContentConnection",
          items:  Array< {
            __typename: "Content",
            id: string,
            assignedMemberId?: string | null,
            brandId: string,
            categories?: Array< string | null > | null,
            createdAt: string,
            createdBy: string,
            contentScheduleId?: string | null,
            dueDate?: string | null,
            publishDate?: string | null,
            published?: boolean | null,
            statusId: string,
            markdown?: string | null,
            approvalStatus?: ApprovalStatus | null,
            title: string,
            description?: string | null,
            caption?: string | null,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        contentIds: Array< string | null >,
        name: string,
        stage: ContentStage,
        createdAt: string,
        updatedAt: string,
      },
      statusId: string,
      markdown?: string | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          createdAt: string,
          contentId: string,
          message: string,
          memberId: string,
          member?:  {
            __typename: "Member",
            id: string,
            name: string,
            email: string,
            subscription?: MemberPlan | null,
            stripeCustomerId?: string | null,
            lastLogin?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      checklist?:  Array< {
        __typename: "ContentChecklist",
        id: string,
        title: string,
        completed: boolean,
      } | null > | null,
      approvalStatus?: ApprovalStatus | null,
      thumbnail?:  {
        __typename: "Media",
        fileName: string,
        objectKey: string,
        size: number,
        dateUploaded: string,
        uploadedBy: string,
        type: string,
        customPrefix?: string | null,
        blurhash?: string | null,
      } | null,
      title: string,
      description?: string | null,
      caption?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CommentsByContentIdQueryVariables = {
  contentId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCommentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CommentsByContentIdQuery = {
  commentsByContentId?:  {
    __typename: "ModelCommentConnection",
    items:  Array< {
      __typename: "Comment",
      id: string,
      createdAt: string,
      contentId: string,
      message: string,
      memberId: string,
      member?:  {
        __typename: "Member",
        id: string,
        name: string,
        email: string,
        brandMembers?:  {
          __typename: "ModelBrandMemberConnection",
          items:  Array< {
            __typename: "BrandMember",
            id: string,
            memberId: string,
            brandId: string,
            accessLevel: AccessLevel,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        subscription?: MemberPlan | null,
        stripeCustomerId?: string | null,
        lastLogin?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListMembersByEmailQueryVariables = {
  email: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMemberFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMembersByEmailQuery = {
  listMembersByEmail?:  {
    __typename: "ModelMemberConnection",
    items:  Array< {
      __typename: "Member",
      id: string,
      name: string,
      email: string,
      brandMembers?:  {
        __typename: "ModelBrandMemberConnection",
        items:  Array< {
          __typename: "BrandMember",
          id: string,
          member?:  {
            __typename: "Member",
            id: string,
            name: string,
            email: string,
            subscription?: MemberPlan | null,
            stripeCustomerId?: string | null,
            lastLogin?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          memberId: string,
          brandId: string,
          brand:  {
            __typename: "Brand",
            id: string,
            name: string,
            connectionId?: string | null,
            categories?: Array< string | null > | null,
            statusOrder?: Array< string | null > | null,
            mission?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          accessLevel: AccessLevel,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      subscription?: MemberPlan | null,
      stripeCustomerId?: string | null,
      lastLogin?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListTokensByBrandIdQueryVariables = {
  brandId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTokenFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTokensByBrandIdQuery = {
  listTokensByBrandId?:  {
    __typename: "ModelTokenConnection",
    items:  Array< {
      __typename: "Token",
      id: string,
      createdAt: string,
      brandId: string,
      accessToken: string,
      refreshToken?: string | null,
      tokenType?: string | null,
      expiryDate: string,
      platform: Platform,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnCreateAnalyticsValuesSubscriptionVariables = {
  filter?: ModelSubscriptionAnalyticsValuesFilterInput | null,
};

export type OnCreateAnalyticsValuesSubscription = {
  onCreateAnalyticsValues?:  {
    __typename: "AnalyticsValues",
    id: string,
    analyticsId: string,
    value: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateAnalyticsValuesSubscriptionVariables = {
  filter?: ModelSubscriptionAnalyticsValuesFilterInput | null,
};

export type OnUpdateAnalyticsValuesSubscription = {
  onUpdateAnalyticsValues?:  {
    __typename: "AnalyticsValues",
    id: string,
    analyticsId: string,
    value: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteAnalyticsValuesSubscriptionVariables = {
  filter?: ModelSubscriptionAnalyticsValuesFilterInput | null,
};

export type OnDeleteAnalyticsValuesSubscription = {
  onDeleteAnalyticsValues?:  {
    __typename: "AnalyticsValues",
    id: string,
    analyticsId: string,
    value: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateAnalyticsSubscriptionVariables = {
  filter?: ModelSubscriptionAnalyticsFilterInput | null,
};

export type OnCreateAnalyticsSubscription = {
  onCreateAnalytics?:  {
    __typename: "Analytics",
    id: string,
    brandId: string,
    platform: Platform,
    createdAt: string,
    values?:  Array< {
      __typename: "AnalyticsValues",
      id: string,
      analyticsId: string,
      value: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateAnalyticsSubscriptionVariables = {
  filter?: ModelSubscriptionAnalyticsFilterInput | null,
};

export type OnUpdateAnalyticsSubscription = {
  onUpdateAnalytics?:  {
    __typename: "Analytics",
    id: string,
    brandId: string,
    platform: Platform,
    createdAt: string,
    values?:  Array< {
      __typename: "AnalyticsValues",
      id: string,
      analyticsId: string,
      value: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    updatedAt: string,
  } | null,
};

export type OnDeleteAnalyticsSubscriptionVariables = {
  filter?: ModelSubscriptionAnalyticsFilterInput | null,
};

export type OnDeleteAnalyticsSubscription = {
  onDeleteAnalytics?:  {
    __typename: "Analytics",
    id: string,
    brandId: string,
    platform: Platform,
    createdAt: string,
    values?:  Array< {
      __typename: "AnalyticsValues",
      id: string,
      analyticsId: string,
      value: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    updatedAt: string,
  } | null,
};

export type OnCreateBrandSubscriptionVariables = {
  filter?: ModelSubscriptionBrandFilterInput | null,
};

export type OnCreateBrandSubscription = {
  onCreateBrand?:  {
    __typename: "Brand",
    id: string,
    name: string,
    connections?:  {
      __typename: "Connections",
      id: string,
      brandId: string,
      youtubeChannelName?: string | null,
      youtubeChannelId?: string | null,
      youtubeUserId?: string | null,
      youtubeChannelPictureUrl?: string | null,
      tiktok?: string | null,
      instagramUserId?: string | null,
      instagramUsername?: string | null,
      instagramProfilePictureUrl?: string | null,
      instagramTokenId?: string | null,
      facebookPageId?: string | null,
      facebookPageName?: string | null,
      facebookPagePictureUrl?: string | null,
      facebookTokenId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    connectionId?: string | null,
    categories?: Array< string | null > | null,
    status?:  {
      __typename: "ModelContentStatusConnection",
      items:  Array< {
        __typename: "ContentStatus",
        id: string,
        brandId: string,
        content?:  {
          __typename: "ModelContentConnection",
          items:  Array< {
            __typename: "Content",
            id: string,
            assignedMemberId?: string | null,
            brandId: string,
            categories?: Array< string | null > | null,
            createdAt: string,
            createdBy: string,
            contentScheduleId?: string | null,
            dueDate?: string | null,
            publishDate?: string | null,
            published?: boolean | null,
            statusId: string,
            markdown?: string | null,
            approvalStatus?: ApprovalStatus | null,
            title: string,
            description?: string | null,
            caption?: string | null,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        contentIds: Array< string | null >,
        name: string,
        stage: ContentStage,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    statusOrder?: Array< string | null > | null,
    brandProfileUrl?:  {
      __typename: "Media",
      fileName: string,
      objectKey: string,
      size: number,
      dateUploaded: string,
      uploadedBy: string,
      type: string,
      customPrefix?: string | null,
      blurhash?: string | null,
    } | null,
    goals?:  Array< {
      __typename: "BrandGoals",
      platform: Platform,
      start: number,
      goal: number,
    } | null > | null,
    mission?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateBrandSubscriptionVariables = {
  filter?: ModelSubscriptionBrandFilterInput | null,
};

export type OnUpdateBrandSubscription = {
  onUpdateBrand?:  {
    __typename: "Brand",
    id: string,
    name: string,
    connections?:  {
      __typename: "Connections",
      id: string,
      brandId: string,
      youtubeChannelName?: string | null,
      youtubeChannelId?: string | null,
      youtubeUserId?: string | null,
      youtubeChannelPictureUrl?: string | null,
      tiktok?: string | null,
      instagramUserId?: string | null,
      instagramUsername?: string | null,
      instagramProfilePictureUrl?: string | null,
      instagramTokenId?: string | null,
      facebookPageId?: string | null,
      facebookPageName?: string | null,
      facebookPagePictureUrl?: string | null,
      facebookTokenId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    connectionId?: string | null,
    categories?: Array< string | null > | null,
    status?:  {
      __typename: "ModelContentStatusConnection",
      items:  Array< {
        __typename: "ContentStatus",
        id: string,
        brandId: string,
        content?:  {
          __typename: "ModelContentConnection",
          items:  Array< {
            __typename: "Content",
            id: string,
            assignedMemberId?: string | null,
            brandId: string,
            categories?: Array< string | null > | null,
            createdAt: string,
            createdBy: string,
            contentScheduleId?: string | null,
            dueDate?: string | null,
            publishDate?: string | null,
            published?: boolean | null,
            statusId: string,
            markdown?: string | null,
            approvalStatus?: ApprovalStatus | null,
            title: string,
            description?: string | null,
            caption?: string | null,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        contentIds: Array< string | null >,
        name: string,
        stage: ContentStage,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    statusOrder?: Array< string | null > | null,
    brandProfileUrl?:  {
      __typename: "Media",
      fileName: string,
      objectKey: string,
      size: number,
      dateUploaded: string,
      uploadedBy: string,
      type: string,
      customPrefix?: string | null,
      blurhash?: string | null,
    } | null,
    goals?:  Array< {
      __typename: "BrandGoals",
      platform: Platform,
      start: number,
      goal: number,
    } | null > | null,
    mission?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteBrandSubscriptionVariables = {
  filter?: ModelSubscriptionBrandFilterInput | null,
};

export type OnDeleteBrandSubscription = {
  onDeleteBrand?:  {
    __typename: "Brand",
    id: string,
    name: string,
    connections?:  {
      __typename: "Connections",
      id: string,
      brandId: string,
      youtubeChannelName?: string | null,
      youtubeChannelId?: string | null,
      youtubeUserId?: string | null,
      youtubeChannelPictureUrl?: string | null,
      tiktok?: string | null,
      instagramUserId?: string | null,
      instagramUsername?: string | null,
      instagramProfilePictureUrl?: string | null,
      instagramTokenId?: string | null,
      facebookPageId?: string | null,
      facebookPageName?: string | null,
      facebookPagePictureUrl?: string | null,
      facebookTokenId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    connectionId?: string | null,
    categories?: Array< string | null > | null,
    status?:  {
      __typename: "ModelContentStatusConnection",
      items:  Array< {
        __typename: "ContentStatus",
        id: string,
        brandId: string,
        content?:  {
          __typename: "ModelContentConnection",
          items:  Array< {
            __typename: "Content",
            id: string,
            assignedMemberId?: string | null,
            brandId: string,
            categories?: Array< string | null > | null,
            createdAt: string,
            createdBy: string,
            contentScheduleId?: string | null,
            dueDate?: string | null,
            publishDate?: string | null,
            published?: boolean | null,
            statusId: string,
            markdown?: string | null,
            approvalStatus?: ApprovalStatus | null,
            title: string,
            description?: string | null,
            caption?: string | null,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        contentIds: Array< string | null >,
        name: string,
        stage: ContentStage,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    statusOrder?: Array< string | null > | null,
    brandProfileUrl?:  {
      __typename: "Media",
      fileName: string,
      objectKey: string,
      size: number,
      dateUploaded: string,
      uploadedBy: string,
      type: string,
      customPrefix?: string | null,
      blurhash?: string | null,
    } | null,
    goals?:  Array< {
      __typename: "BrandGoals",
      platform: Platform,
      start: number,
      goal: number,
    } | null > | null,
    mission?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateBrandMemberSubscriptionVariables = {
  filter?: ModelSubscriptionBrandMemberFilterInput | null,
};

export type OnCreateBrandMemberSubscription = {
  onCreateBrandMember?:  {
    __typename: "BrandMember",
    id: string,
    member?:  {
      __typename: "Member",
      id: string,
      name: string,
      email: string,
      brandMembers?:  {
        __typename: "ModelBrandMemberConnection",
        items:  Array< {
          __typename: "BrandMember",
          id: string,
          member?:  {
            __typename: "Member",
            id: string,
            name: string,
            email: string,
            subscription?: MemberPlan | null,
            stripeCustomerId?: string | null,
            lastLogin?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          memberId: string,
          brandId: string,
          brand:  {
            __typename: "Brand",
            id: string,
            name: string,
            connectionId?: string | null,
            categories?: Array< string | null > | null,
            statusOrder?: Array< string | null > | null,
            mission?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          accessLevel: AccessLevel,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      subscription?: MemberPlan | null,
      stripeCustomerId?: string | null,
      lastLogin?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    memberId: string,
    brandId: string,
    brand:  {
      __typename: "Brand",
      id: string,
      name: string,
      connections?:  {
        __typename: "Connections",
        id: string,
        brandId: string,
        youtubeChannelName?: string | null,
        youtubeChannelId?: string | null,
        youtubeUserId?: string | null,
        youtubeChannelPictureUrl?: string | null,
        tiktok?: string | null,
        instagramUserId?: string | null,
        instagramUsername?: string | null,
        instagramProfilePictureUrl?: string | null,
        instagramTokenId?: string | null,
        facebookPageId?: string | null,
        facebookPageName?: string | null,
        facebookPagePictureUrl?: string | null,
        facebookTokenId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      connectionId?: string | null,
      categories?: Array< string | null > | null,
      status?:  {
        __typename: "ModelContentStatusConnection",
        items:  Array< {
          __typename: "ContentStatus",
          id: string,
          brandId: string,
          content?:  {
            __typename: "ModelContentConnection",
            nextToken?: string | null,
          } | null,
          contentIds: Array< string | null >,
          name: string,
          stage: ContentStage,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      statusOrder?: Array< string | null > | null,
      brandProfileUrl?:  {
        __typename: "Media",
        fileName: string,
        objectKey: string,
        size: number,
        dateUploaded: string,
        uploadedBy: string,
        type: string,
        customPrefix?: string | null,
        blurhash?: string | null,
      } | null,
      goals?:  Array< {
        __typename: "BrandGoals",
        platform: Platform,
        start: number,
        goal: number,
      } | null > | null,
      mission?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    accessLevel: AccessLevel,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateBrandMemberSubscriptionVariables = {
  filter?: ModelSubscriptionBrandMemberFilterInput | null,
};

export type OnUpdateBrandMemberSubscription = {
  onUpdateBrandMember?:  {
    __typename: "BrandMember",
    id: string,
    member?:  {
      __typename: "Member",
      id: string,
      name: string,
      email: string,
      brandMembers?:  {
        __typename: "ModelBrandMemberConnection",
        items:  Array< {
          __typename: "BrandMember",
          id: string,
          member?:  {
            __typename: "Member",
            id: string,
            name: string,
            email: string,
            subscription?: MemberPlan | null,
            stripeCustomerId?: string | null,
            lastLogin?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          memberId: string,
          brandId: string,
          brand:  {
            __typename: "Brand",
            id: string,
            name: string,
            connectionId?: string | null,
            categories?: Array< string | null > | null,
            statusOrder?: Array< string | null > | null,
            mission?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          accessLevel: AccessLevel,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      subscription?: MemberPlan | null,
      stripeCustomerId?: string | null,
      lastLogin?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    memberId: string,
    brandId: string,
    brand:  {
      __typename: "Brand",
      id: string,
      name: string,
      connections?:  {
        __typename: "Connections",
        id: string,
        brandId: string,
        youtubeChannelName?: string | null,
        youtubeChannelId?: string | null,
        youtubeUserId?: string | null,
        youtubeChannelPictureUrl?: string | null,
        tiktok?: string | null,
        instagramUserId?: string | null,
        instagramUsername?: string | null,
        instagramProfilePictureUrl?: string | null,
        instagramTokenId?: string | null,
        facebookPageId?: string | null,
        facebookPageName?: string | null,
        facebookPagePictureUrl?: string | null,
        facebookTokenId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      connectionId?: string | null,
      categories?: Array< string | null > | null,
      status?:  {
        __typename: "ModelContentStatusConnection",
        items:  Array< {
          __typename: "ContentStatus",
          id: string,
          brandId: string,
          content?:  {
            __typename: "ModelContentConnection",
            nextToken?: string | null,
          } | null,
          contentIds: Array< string | null >,
          name: string,
          stage: ContentStage,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      statusOrder?: Array< string | null > | null,
      brandProfileUrl?:  {
        __typename: "Media",
        fileName: string,
        objectKey: string,
        size: number,
        dateUploaded: string,
        uploadedBy: string,
        type: string,
        customPrefix?: string | null,
        blurhash?: string | null,
      } | null,
      goals?:  Array< {
        __typename: "BrandGoals",
        platform: Platform,
        start: number,
        goal: number,
      } | null > | null,
      mission?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    accessLevel: AccessLevel,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteBrandMemberSubscriptionVariables = {
  filter?: ModelSubscriptionBrandMemberFilterInput | null,
};

export type OnDeleteBrandMemberSubscription = {
  onDeleteBrandMember?:  {
    __typename: "BrandMember",
    id: string,
    member?:  {
      __typename: "Member",
      id: string,
      name: string,
      email: string,
      brandMembers?:  {
        __typename: "ModelBrandMemberConnection",
        items:  Array< {
          __typename: "BrandMember",
          id: string,
          member?:  {
            __typename: "Member",
            id: string,
            name: string,
            email: string,
            subscription?: MemberPlan | null,
            stripeCustomerId?: string | null,
            lastLogin?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          memberId: string,
          brandId: string,
          brand:  {
            __typename: "Brand",
            id: string,
            name: string,
            connectionId?: string | null,
            categories?: Array< string | null > | null,
            statusOrder?: Array< string | null > | null,
            mission?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          accessLevel: AccessLevel,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      subscription?: MemberPlan | null,
      stripeCustomerId?: string | null,
      lastLogin?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    memberId: string,
    brandId: string,
    brand:  {
      __typename: "Brand",
      id: string,
      name: string,
      connections?:  {
        __typename: "Connections",
        id: string,
        brandId: string,
        youtubeChannelName?: string | null,
        youtubeChannelId?: string | null,
        youtubeUserId?: string | null,
        youtubeChannelPictureUrl?: string | null,
        tiktok?: string | null,
        instagramUserId?: string | null,
        instagramUsername?: string | null,
        instagramProfilePictureUrl?: string | null,
        instagramTokenId?: string | null,
        facebookPageId?: string | null,
        facebookPageName?: string | null,
        facebookPagePictureUrl?: string | null,
        facebookTokenId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      connectionId?: string | null,
      categories?: Array< string | null > | null,
      status?:  {
        __typename: "ModelContentStatusConnection",
        items:  Array< {
          __typename: "ContentStatus",
          id: string,
          brandId: string,
          content?:  {
            __typename: "ModelContentConnection",
            nextToken?: string | null,
          } | null,
          contentIds: Array< string | null >,
          name: string,
          stage: ContentStage,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      statusOrder?: Array< string | null > | null,
      brandProfileUrl?:  {
        __typename: "Media",
        fileName: string,
        objectKey: string,
        size: number,
        dateUploaded: string,
        uploadedBy: string,
        type: string,
        customPrefix?: string | null,
        blurhash?: string | null,
      } | null,
      goals?:  Array< {
        __typename: "BrandGoals",
        platform: Platform,
        start: number,
        goal: number,
      } | null > | null,
      mission?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    accessLevel: AccessLevel,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateConnectionsSubscriptionVariables = {
  filter?: ModelSubscriptionConnectionsFilterInput | null,
};

export type OnCreateConnectionsSubscription = {
  onCreateConnections?:  {
    __typename: "Connections",
    id: string,
    brandId: string,
    youtubeChannelName?: string | null,
    youtubeChannelId?: string | null,
    youtubeUserId?: string | null,
    youtubeChannelPictureUrl?: string | null,
    tiktok?: string | null,
    instagramUserId?: string | null,
    instagramUsername?: string | null,
    instagramProfilePictureUrl?: string | null,
    instagramTokenId?: string | null,
    facebookPageId?: string | null,
    facebookPageName?: string | null,
    facebookPagePictureUrl?: string | null,
    facebookTokenId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateConnectionsSubscriptionVariables = {
  filter?: ModelSubscriptionConnectionsFilterInput | null,
};

export type OnUpdateConnectionsSubscription = {
  onUpdateConnections?:  {
    __typename: "Connections",
    id: string,
    brandId: string,
    youtubeChannelName?: string | null,
    youtubeChannelId?: string | null,
    youtubeUserId?: string | null,
    youtubeChannelPictureUrl?: string | null,
    tiktok?: string | null,
    instagramUserId?: string | null,
    instagramUsername?: string | null,
    instagramProfilePictureUrl?: string | null,
    instagramTokenId?: string | null,
    facebookPageId?: string | null,
    facebookPageName?: string | null,
    facebookPagePictureUrl?: string | null,
    facebookTokenId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteConnectionsSubscriptionVariables = {
  filter?: ModelSubscriptionConnectionsFilterInput | null,
};

export type OnDeleteConnectionsSubscription = {
  onDeleteConnections?:  {
    __typename: "Connections",
    id: string,
    brandId: string,
    youtubeChannelName?: string | null,
    youtubeChannelId?: string | null,
    youtubeUserId?: string | null,
    youtubeChannelPictureUrl?: string | null,
    tiktok?: string | null,
    instagramUserId?: string | null,
    instagramUsername?: string | null,
    instagramProfilePictureUrl?: string | null,
    instagramTokenId?: string | null,
    facebookPageId?: string | null,
    facebookPageName?: string | null,
    facebookPagePictureUrl?: string | null,
    facebookTokenId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateContentStatusSubscriptionVariables = {
  filter?: ModelSubscriptionContentStatusFilterInput | null,
};

export type OnCreateContentStatusSubscription = {
  onCreateContentStatus?:  {
    __typename: "ContentStatus",
    id: string,
    brandId: string,
    content?:  {
      __typename: "ModelContentConnection",
      items:  Array< {
        __typename: "Content",
        id: string,
        assignedTo?:  {
          __typename: "Member",
          id: string,
          name: string,
          email: string,
          brandMembers?:  {
            __typename: "ModelBrandMemberConnection",
            nextToken?: string | null,
          } | null,
          subscription?: MemberPlan | null,
          stripeCustomerId?: string | null,
          lastLogin?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        assignedMemberId?: string | null,
        brandId: string,
        categories?: Array< string | null > | null,
        createdAt: string,
        createdBy: string,
        contentScheduleId?: string | null,
        dueDate?: string | null,
        media?:  Array< {
          __typename: "Media",
          fileName: string,
          objectKey: string,
          size: number,
          dateUploaded: string,
          uploadedBy: string,
          type: string,
          customPrefix?: string | null,
          blurhash?: string | null,
        } | null > | null,
        platforms?:  Array< {
          __typename: "PlatformData",
          platform: Platform,
          status: ContentPublishStatus,
          postUrl?: string | null,
          publishDate?: string | null,
        } | null > | null,
        publishDate?: string | null,
        published?: boolean | null,
        status:  {
          __typename: "ContentStatus",
          id: string,
          brandId: string,
          content?:  {
            __typename: "ModelContentConnection",
            nextToken?: string | null,
          } | null,
          contentIds: Array< string | null >,
          name: string,
          stage: ContentStage,
          createdAt: string,
          updatedAt: string,
        },
        statusId: string,
        markdown?: string | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          items:  Array< {
            __typename: "Comment",
            id: string,
            createdAt: string,
            contentId: string,
            message: string,
            memberId: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        checklist?:  Array< {
          __typename: "ContentChecklist",
          id: string,
          title: string,
          completed: boolean,
        } | null > | null,
        approvalStatus?: ApprovalStatus | null,
        thumbnail?:  {
          __typename: "Media",
          fileName: string,
          objectKey: string,
          size: number,
          dateUploaded: string,
          uploadedBy: string,
          type: string,
          customPrefix?: string | null,
          blurhash?: string | null,
        } | null,
        title: string,
        description?: string | null,
        caption?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    contentIds: Array< string | null >,
    name: string,
    stage: ContentStage,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateContentStatusSubscriptionVariables = {
  filter?: ModelSubscriptionContentStatusFilterInput | null,
};

export type OnUpdateContentStatusSubscription = {
  onUpdateContentStatus?:  {
    __typename: "ContentStatus",
    id: string,
    brandId: string,
    content?:  {
      __typename: "ModelContentConnection",
      items:  Array< {
        __typename: "Content",
        id: string,
        assignedTo?:  {
          __typename: "Member",
          id: string,
          name: string,
          email: string,
          brandMembers?:  {
            __typename: "ModelBrandMemberConnection",
            nextToken?: string | null,
          } | null,
          subscription?: MemberPlan | null,
          stripeCustomerId?: string | null,
          lastLogin?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        assignedMemberId?: string | null,
        brandId: string,
        categories?: Array< string | null > | null,
        createdAt: string,
        createdBy: string,
        contentScheduleId?: string | null,
        dueDate?: string | null,
        media?:  Array< {
          __typename: "Media",
          fileName: string,
          objectKey: string,
          size: number,
          dateUploaded: string,
          uploadedBy: string,
          type: string,
          customPrefix?: string | null,
          blurhash?: string | null,
        } | null > | null,
        platforms?:  Array< {
          __typename: "PlatformData",
          platform: Platform,
          status: ContentPublishStatus,
          postUrl?: string | null,
          publishDate?: string | null,
        } | null > | null,
        publishDate?: string | null,
        published?: boolean | null,
        status:  {
          __typename: "ContentStatus",
          id: string,
          brandId: string,
          content?:  {
            __typename: "ModelContentConnection",
            nextToken?: string | null,
          } | null,
          contentIds: Array< string | null >,
          name: string,
          stage: ContentStage,
          createdAt: string,
          updatedAt: string,
        },
        statusId: string,
        markdown?: string | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          items:  Array< {
            __typename: "Comment",
            id: string,
            createdAt: string,
            contentId: string,
            message: string,
            memberId: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        checklist?:  Array< {
          __typename: "ContentChecklist",
          id: string,
          title: string,
          completed: boolean,
        } | null > | null,
        approvalStatus?: ApprovalStatus | null,
        thumbnail?:  {
          __typename: "Media",
          fileName: string,
          objectKey: string,
          size: number,
          dateUploaded: string,
          uploadedBy: string,
          type: string,
          customPrefix?: string | null,
          blurhash?: string | null,
        } | null,
        title: string,
        description?: string | null,
        caption?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    contentIds: Array< string | null >,
    name: string,
    stage: ContentStage,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteContentStatusSubscriptionVariables = {
  filter?: ModelSubscriptionContentStatusFilterInput | null,
};

export type OnDeleteContentStatusSubscription = {
  onDeleteContentStatus?:  {
    __typename: "ContentStatus",
    id: string,
    brandId: string,
    content?:  {
      __typename: "ModelContentConnection",
      items:  Array< {
        __typename: "Content",
        id: string,
        assignedTo?:  {
          __typename: "Member",
          id: string,
          name: string,
          email: string,
          brandMembers?:  {
            __typename: "ModelBrandMemberConnection",
            nextToken?: string | null,
          } | null,
          subscription?: MemberPlan | null,
          stripeCustomerId?: string | null,
          lastLogin?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        assignedMemberId?: string | null,
        brandId: string,
        categories?: Array< string | null > | null,
        createdAt: string,
        createdBy: string,
        contentScheduleId?: string | null,
        dueDate?: string | null,
        media?:  Array< {
          __typename: "Media",
          fileName: string,
          objectKey: string,
          size: number,
          dateUploaded: string,
          uploadedBy: string,
          type: string,
          customPrefix?: string | null,
          blurhash?: string | null,
        } | null > | null,
        platforms?:  Array< {
          __typename: "PlatformData",
          platform: Platform,
          status: ContentPublishStatus,
          postUrl?: string | null,
          publishDate?: string | null,
        } | null > | null,
        publishDate?: string | null,
        published?: boolean | null,
        status:  {
          __typename: "ContentStatus",
          id: string,
          brandId: string,
          content?:  {
            __typename: "ModelContentConnection",
            nextToken?: string | null,
          } | null,
          contentIds: Array< string | null >,
          name: string,
          stage: ContentStage,
          createdAt: string,
          updatedAt: string,
        },
        statusId: string,
        markdown?: string | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          items:  Array< {
            __typename: "Comment",
            id: string,
            createdAt: string,
            contentId: string,
            message: string,
            memberId: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        checklist?:  Array< {
          __typename: "ContentChecklist",
          id: string,
          title: string,
          completed: boolean,
        } | null > | null,
        approvalStatus?: ApprovalStatus | null,
        thumbnail?:  {
          __typename: "Media",
          fileName: string,
          objectKey: string,
          size: number,
          dateUploaded: string,
          uploadedBy: string,
          type: string,
          customPrefix?: string | null,
          blurhash?: string | null,
        } | null,
        title: string,
        description?: string | null,
        caption?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    contentIds: Array< string | null >,
    name: string,
    stage: ContentStage,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateContentSubscriptionVariables = {
  filter?: ModelSubscriptionContentFilterInput | null,
};

export type OnCreateContentSubscription = {
  onCreateContent?:  {
    __typename: "Content",
    id: string,
    assignedTo?:  {
      __typename: "Member",
      id: string,
      name: string,
      email: string,
      brandMembers?:  {
        __typename: "ModelBrandMemberConnection",
        items:  Array< {
          __typename: "BrandMember",
          id: string,
          member?:  {
            __typename: "Member",
            id: string,
            name: string,
            email: string,
            subscription?: MemberPlan | null,
            stripeCustomerId?: string | null,
            lastLogin?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          memberId: string,
          brandId: string,
          brand:  {
            __typename: "Brand",
            id: string,
            name: string,
            connectionId?: string | null,
            categories?: Array< string | null > | null,
            statusOrder?: Array< string | null > | null,
            mission?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          accessLevel: AccessLevel,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      subscription?: MemberPlan | null,
      stripeCustomerId?: string | null,
      lastLogin?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    assignedMemberId?: string | null,
    brandId: string,
    categories?: Array< string | null > | null,
    createdAt: string,
    createdBy: string,
    contentScheduleId?: string | null,
    dueDate?: string | null,
    media?:  Array< {
      __typename: "Media",
      fileName: string,
      objectKey: string,
      size: number,
      dateUploaded: string,
      uploadedBy: string,
      type: string,
      customPrefix?: string | null,
      blurhash?: string | null,
    } | null > | null,
    platforms?:  Array< {
      __typename: "PlatformData",
      platform: Platform,
      status: ContentPublishStatus,
      postUrl?: string | null,
      publishDate?: string | null,
    } | null > | null,
    publishDate?: string | null,
    published?: boolean | null,
    status:  {
      __typename: "ContentStatus",
      id: string,
      brandId: string,
      content?:  {
        __typename: "ModelContentConnection",
        items:  Array< {
          __typename: "Content",
          id: string,
          assignedTo?:  {
            __typename: "Member",
            id: string,
            name: string,
            email: string,
            subscription?: MemberPlan | null,
            stripeCustomerId?: string | null,
            lastLogin?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          assignedMemberId?: string | null,
          brandId: string,
          categories?: Array< string | null > | null,
          createdAt: string,
          createdBy: string,
          contentScheduleId?: string | null,
          dueDate?: string | null,
          media?:  Array< {
            __typename: "Media",
            fileName: string,
            objectKey: string,
            size: number,
            dateUploaded: string,
            uploadedBy: string,
            type: string,
            customPrefix?: string | null,
            blurhash?: string | null,
          } | null > | null,
          platforms?:  Array< {
            __typename: "PlatformData",
            platform: Platform,
            status: ContentPublishStatus,
            postUrl?: string | null,
            publishDate?: string | null,
          } | null > | null,
          publishDate?: string | null,
          published?: boolean | null,
          status:  {
            __typename: "ContentStatus",
            id: string,
            brandId: string,
            contentIds: Array< string | null >,
            name: string,
            stage: ContentStage,
            createdAt: string,
            updatedAt: string,
          },
          statusId: string,
          markdown?: string | null,
          comments?:  {
            __typename: "ModelCommentConnection",
            nextToken?: string | null,
          } | null,
          checklist?:  Array< {
            __typename: "ContentChecklist",
            id: string,
            title: string,
            completed: boolean,
          } | null > | null,
          approvalStatus?: ApprovalStatus | null,
          thumbnail?:  {
            __typename: "Media",
            fileName: string,
            objectKey: string,
            size: number,
            dateUploaded: string,
            uploadedBy: string,
            type: string,
            customPrefix?: string | null,
            blurhash?: string | null,
          } | null,
          title: string,
          description?: string | null,
          caption?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contentIds: Array< string | null >,
      name: string,
      stage: ContentStage,
      createdAt: string,
      updatedAt: string,
    },
    statusId: string,
    markdown?: string | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        createdAt: string,
        contentId: string,
        message: string,
        memberId: string,
        member?:  {
          __typename: "Member",
          id: string,
          name: string,
          email: string,
          brandMembers?:  {
            __typename: "ModelBrandMemberConnection",
            nextToken?: string | null,
          } | null,
          subscription?: MemberPlan | null,
          stripeCustomerId?: string | null,
          lastLogin?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    checklist?:  Array< {
      __typename: "ContentChecklist",
      id: string,
      title: string,
      completed: boolean,
    } | null > | null,
    approvalStatus?: ApprovalStatus | null,
    thumbnail?:  {
      __typename: "Media",
      fileName: string,
      objectKey: string,
      size: number,
      dateUploaded: string,
      uploadedBy: string,
      type: string,
      customPrefix?: string | null,
      blurhash?: string | null,
    } | null,
    title: string,
    description?: string | null,
    caption?: string | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateContentSubscriptionVariables = {
  filter?: ModelSubscriptionContentFilterInput | null,
};

export type OnUpdateContentSubscription = {
  onUpdateContent?:  {
    __typename: "Content",
    id: string,
    assignedTo?:  {
      __typename: "Member",
      id: string,
      name: string,
      email: string,
      brandMembers?:  {
        __typename: "ModelBrandMemberConnection",
        items:  Array< {
          __typename: "BrandMember",
          id: string,
          member?:  {
            __typename: "Member",
            id: string,
            name: string,
            email: string,
            subscription?: MemberPlan | null,
            stripeCustomerId?: string | null,
            lastLogin?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          memberId: string,
          brandId: string,
          brand:  {
            __typename: "Brand",
            id: string,
            name: string,
            connectionId?: string | null,
            categories?: Array< string | null > | null,
            statusOrder?: Array< string | null > | null,
            mission?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          accessLevel: AccessLevel,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      subscription?: MemberPlan | null,
      stripeCustomerId?: string | null,
      lastLogin?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    assignedMemberId?: string | null,
    brandId: string,
    categories?: Array< string | null > | null,
    createdAt: string,
    createdBy: string,
    contentScheduleId?: string | null,
    dueDate?: string | null,
    media?:  Array< {
      __typename: "Media",
      fileName: string,
      objectKey: string,
      size: number,
      dateUploaded: string,
      uploadedBy: string,
      type: string,
      customPrefix?: string | null,
      blurhash?: string | null,
    } | null > | null,
    platforms?:  Array< {
      __typename: "PlatformData",
      platform: Platform,
      status: ContentPublishStatus,
      postUrl?: string | null,
      publishDate?: string | null,
    } | null > | null,
    publishDate?: string | null,
    published?: boolean | null,
    status:  {
      __typename: "ContentStatus",
      id: string,
      brandId: string,
      content?:  {
        __typename: "ModelContentConnection",
        items:  Array< {
          __typename: "Content",
          id: string,
          assignedTo?:  {
            __typename: "Member",
            id: string,
            name: string,
            email: string,
            subscription?: MemberPlan | null,
            stripeCustomerId?: string | null,
            lastLogin?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          assignedMemberId?: string | null,
          brandId: string,
          categories?: Array< string | null > | null,
          createdAt: string,
          createdBy: string,
          contentScheduleId?: string | null,
          dueDate?: string | null,
          media?:  Array< {
            __typename: "Media",
            fileName: string,
            objectKey: string,
            size: number,
            dateUploaded: string,
            uploadedBy: string,
            type: string,
            customPrefix?: string | null,
            blurhash?: string | null,
          } | null > | null,
          platforms?:  Array< {
            __typename: "PlatformData",
            platform: Platform,
            status: ContentPublishStatus,
            postUrl?: string | null,
            publishDate?: string | null,
          } | null > | null,
          publishDate?: string | null,
          published?: boolean | null,
          status:  {
            __typename: "ContentStatus",
            id: string,
            brandId: string,
            contentIds: Array< string | null >,
            name: string,
            stage: ContentStage,
            createdAt: string,
            updatedAt: string,
          },
          statusId: string,
          markdown?: string | null,
          comments?:  {
            __typename: "ModelCommentConnection",
            nextToken?: string | null,
          } | null,
          checklist?:  Array< {
            __typename: "ContentChecklist",
            id: string,
            title: string,
            completed: boolean,
          } | null > | null,
          approvalStatus?: ApprovalStatus | null,
          thumbnail?:  {
            __typename: "Media",
            fileName: string,
            objectKey: string,
            size: number,
            dateUploaded: string,
            uploadedBy: string,
            type: string,
            customPrefix?: string | null,
            blurhash?: string | null,
          } | null,
          title: string,
          description?: string | null,
          caption?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contentIds: Array< string | null >,
      name: string,
      stage: ContentStage,
      createdAt: string,
      updatedAt: string,
    },
    statusId: string,
    markdown?: string | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        createdAt: string,
        contentId: string,
        message: string,
        memberId: string,
        member?:  {
          __typename: "Member",
          id: string,
          name: string,
          email: string,
          brandMembers?:  {
            __typename: "ModelBrandMemberConnection",
            nextToken?: string | null,
          } | null,
          subscription?: MemberPlan | null,
          stripeCustomerId?: string | null,
          lastLogin?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    checklist?:  Array< {
      __typename: "ContentChecklist",
      id: string,
      title: string,
      completed: boolean,
    } | null > | null,
    approvalStatus?: ApprovalStatus | null,
    thumbnail?:  {
      __typename: "Media",
      fileName: string,
      objectKey: string,
      size: number,
      dateUploaded: string,
      uploadedBy: string,
      type: string,
      customPrefix?: string | null,
      blurhash?: string | null,
    } | null,
    title: string,
    description?: string | null,
    caption?: string | null,
    updatedAt: string,
  } | null,
};

export type OnDeleteContentSubscriptionVariables = {
  filter?: ModelSubscriptionContentFilterInput | null,
};

export type OnDeleteContentSubscription = {
  onDeleteContent?:  {
    __typename: "Content",
    id: string,
    assignedTo?:  {
      __typename: "Member",
      id: string,
      name: string,
      email: string,
      brandMembers?:  {
        __typename: "ModelBrandMemberConnection",
        items:  Array< {
          __typename: "BrandMember",
          id: string,
          member?:  {
            __typename: "Member",
            id: string,
            name: string,
            email: string,
            subscription?: MemberPlan | null,
            stripeCustomerId?: string | null,
            lastLogin?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          memberId: string,
          brandId: string,
          brand:  {
            __typename: "Brand",
            id: string,
            name: string,
            connectionId?: string | null,
            categories?: Array< string | null > | null,
            statusOrder?: Array< string | null > | null,
            mission?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          accessLevel: AccessLevel,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      subscription?: MemberPlan | null,
      stripeCustomerId?: string | null,
      lastLogin?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    assignedMemberId?: string | null,
    brandId: string,
    categories?: Array< string | null > | null,
    createdAt: string,
    createdBy: string,
    contentScheduleId?: string | null,
    dueDate?: string | null,
    media?:  Array< {
      __typename: "Media",
      fileName: string,
      objectKey: string,
      size: number,
      dateUploaded: string,
      uploadedBy: string,
      type: string,
      customPrefix?: string | null,
      blurhash?: string | null,
    } | null > | null,
    platforms?:  Array< {
      __typename: "PlatformData",
      platform: Platform,
      status: ContentPublishStatus,
      postUrl?: string | null,
      publishDate?: string | null,
    } | null > | null,
    publishDate?: string | null,
    published?: boolean | null,
    status:  {
      __typename: "ContentStatus",
      id: string,
      brandId: string,
      content?:  {
        __typename: "ModelContentConnection",
        items:  Array< {
          __typename: "Content",
          id: string,
          assignedTo?:  {
            __typename: "Member",
            id: string,
            name: string,
            email: string,
            subscription?: MemberPlan | null,
            stripeCustomerId?: string | null,
            lastLogin?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          assignedMemberId?: string | null,
          brandId: string,
          categories?: Array< string | null > | null,
          createdAt: string,
          createdBy: string,
          contentScheduleId?: string | null,
          dueDate?: string | null,
          media?:  Array< {
            __typename: "Media",
            fileName: string,
            objectKey: string,
            size: number,
            dateUploaded: string,
            uploadedBy: string,
            type: string,
            customPrefix?: string | null,
            blurhash?: string | null,
          } | null > | null,
          platforms?:  Array< {
            __typename: "PlatformData",
            platform: Platform,
            status: ContentPublishStatus,
            postUrl?: string | null,
            publishDate?: string | null,
          } | null > | null,
          publishDate?: string | null,
          published?: boolean | null,
          status:  {
            __typename: "ContentStatus",
            id: string,
            brandId: string,
            contentIds: Array< string | null >,
            name: string,
            stage: ContentStage,
            createdAt: string,
            updatedAt: string,
          },
          statusId: string,
          markdown?: string | null,
          comments?:  {
            __typename: "ModelCommentConnection",
            nextToken?: string | null,
          } | null,
          checklist?:  Array< {
            __typename: "ContentChecklist",
            id: string,
            title: string,
            completed: boolean,
          } | null > | null,
          approvalStatus?: ApprovalStatus | null,
          thumbnail?:  {
            __typename: "Media",
            fileName: string,
            objectKey: string,
            size: number,
            dateUploaded: string,
            uploadedBy: string,
            type: string,
            customPrefix?: string | null,
            blurhash?: string | null,
          } | null,
          title: string,
          description?: string | null,
          caption?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contentIds: Array< string | null >,
      name: string,
      stage: ContentStage,
      createdAt: string,
      updatedAt: string,
    },
    statusId: string,
    markdown?: string | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        createdAt: string,
        contentId: string,
        message: string,
        memberId: string,
        member?:  {
          __typename: "Member",
          id: string,
          name: string,
          email: string,
          brandMembers?:  {
            __typename: "ModelBrandMemberConnection",
            nextToken?: string | null,
          } | null,
          subscription?: MemberPlan | null,
          stripeCustomerId?: string | null,
          lastLogin?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    checklist?:  Array< {
      __typename: "ContentChecklist",
      id: string,
      title: string,
      completed: boolean,
    } | null > | null,
    approvalStatus?: ApprovalStatus | null,
    thumbnail?:  {
      __typename: "Media",
      fileName: string,
      objectKey: string,
      size: number,
      dateUploaded: string,
      uploadedBy: string,
      type: string,
      customPrefix?: string | null,
      blurhash?: string | null,
    } | null,
    title: string,
    description?: string | null,
    caption?: string | null,
    updatedAt: string,
  } | null,
};

export type OnCreateContentCategorySubscriptionVariables = {
  filter?: ModelSubscriptionContentCategoryFilterInput | null,
};

export type OnCreateContentCategorySubscription = {
  onCreateContentCategory?:  {
    __typename: "ContentCategory",
    id: string,
    contentId: string,
    name: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateContentCategorySubscriptionVariables = {
  filter?: ModelSubscriptionContentCategoryFilterInput | null,
};

export type OnUpdateContentCategorySubscription = {
  onUpdateContentCategory?:  {
    __typename: "ContentCategory",
    id: string,
    contentId: string,
    name: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteContentCategorySubscriptionVariables = {
  filter?: ModelSubscriptionContentCategoryFilterInput | null,
};

export type OnDeleteContentCategorySubscription = {
  onDeleteContentCategory?:  {
    __typename: "ContentCategory",
    id: string,
    contentId: string,
    name: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateCommentSubscriptionVariables = {
  filter?: ModelSubscriptionCommentFilterInput | null,
};

export type OnCreateCommentSubscription = {
  onCreateComment?:  {
    __typename: "Comment",
    id: string,
    createdAt: string,
    contentId: string,
    message: string,
    memberId: string,
    member?:  {
      __typename: "Member",
      id: string,
      name: string,
      email: string,
      brandMembers?:  {
        __typename: "ModelBrandMemberConnection",
        items:  Array< {
          __typename: "BrandMember",
          id: string,
          member?:  {
            __typename: "Member",
            id: string,
            name: string,
            email: string,
            subscription?: MemberPlan | null,
            stripeCustomerId?: string | null,
            lastLogin?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          memberId: string,
          brandId: string,
          brand:  {
            __typename: "Brand",
            id: string,
            name: string,
            connectionId?: string | null,
            categories?: Array< string | null > | null,
            statusOrder?: Array< string | null > | null,
            mission?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          accessLevel: AccessLevel,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      subscription?: MemberPlan | null,
      stripeCustomerId?: string | null,
      lastLogin?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateCommentSubscriptionVariables = {
  filter?: ModelSubscriptionCommentFilterInput | null,
};

export type OnUpdateCommentSubscription = {
  onUpdateComment?:  {
    __typename: "Comment",
    id: string,
    createdAt: string,
    contentId: string,
    message: string,
    memberId: string,
    member?:  {
      __typename: "Member",
      id: string,
      name: string,
      email: string,
      brandMembers?:  {
        __typename: "ModelBrandMemberConnection",
        items:  Array< {
          __typename: "BrandMember",
          id: string,
          member?:  {
            __typename: "Member",
            id: string,
            name: string,
            email: string,
            subscription?: MemberPlan | null,
            stripeCustomerId?: string | null,
            lastLogin?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          memberId: string,
          brandId: string,
          brand:  {
            __typename: "Brand",
            id: string,
            name: string,
            connectionId?: string | null,
            categories?: Array< string | null > | null,
            statusOrder?: Array< string | null > | null,
            mission?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          accessLevel: AccessLevel,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      subscription?: MemberPlan | null,
      stripeCustomerId?: string | null,
      lastLogin?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    updatedAt: string,
  } | null,
};

export type OnDeleteCommentSubscriptionVariables = {
  filter?: ModelSubscriptionCommentFilterInput | null,
};

export type OnDeleteCommentSubscription = {
  onDeleteComment?:  {
    __typename: "Comment",
    id: string,
    createdAt: string,
    contentId: string,
    message: string,
    memberId: string,
    member?:  {
      __typename: "Member",
      id: string,
      name: string,
      email: string,
      brandMembers?:  {
        __typename: "ModelBrandMemberConnection",
        items:  Array< {
          __typename: "BrandMember",
          id: string,
          member?:  {
            __typename: "Member",
            id: string,
            name: string,
            email: string,
            subscription?: MemberPlan | null,
            stripeCustomerId?: string | null,
            lastLogin?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          memberId: string,
          brandId: string,
          brand:  {
            __typename: "Brand",
            id: string,
            name: string,
            connectionId?: string | null,
            categories?: Array< string | null > | null,
            statusOrder?: Array< string | null > | null,
            mission?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          accessLevel: AccessLevel,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      subscription?: MemberPlan | null,
      stripeCustomerId?: string | null,
      lastLogin?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    updatedAt: string,
  } | null,
};

export type OnCreateContentScheduleSubscriptionVariables = {
  filter?: ModelSubscriptionContentScheduleFilterInput | null,
};

export type OnCreateContentScheduleSubscription = {
  onCreateContentSchedule?:  {
    __typename: "ContentSchedule",
    id: string,
    publishDate: number,
    status: ScheduleStatus,
    contentId: string,
    brandId?: string | null,
    platformData:  Array< {
      __typename: "ContentSchedulePlatformData",
      platform: Platform,
      accountId: string,
      tokenId: string,
    } >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateContentScheduleSubscriptionVariables = {
  filter?: ModelSubscriptionContentScheduleFilterInput | null,
};

export type OnUpdateContentScheduleSubscription = {
  onUpdateContentSchedule?:  {
    __typename: "ContentSchedule",
    id: string,
    publishDate: number,
    status: ScheduleStatus,
    contentId: string,
    brandId?: string | null,
    platformData:  Array< {
      __typename: "ContentSchedulePlatformData",
      platform: Platform,
      accountId: string,
      tokenId: string,
    } >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteContentScheduleSubscriptionVariables = {
  filter?: ModelSubscriptionContentScheduleFilterInput | null,
};

export type OnDeleteContentScheduleSubscription = {
  onDeleteContentSchedule?:  {
    __typename: "ContentSchedule",
    id: string,
    publishDate: number,
    status: ScheduleStatus,
    contentId: string,
    brandId?: string | null,
    platformData:  Array< {
      __typename: "ContentSchedulePlatformData",
      platform: Platform,
      accountId: string,
      tokenId: string,
    } >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateMemberSubscriptionVariables = {
  filter?: ModelSubscriptionMemberFilterInput | null,
};

export type OnCreateMemberSubscription = {
  onCreateMember?:  {
    __typename: "Member",
    id: string,
    name: string,
    email: string,
    brandMembers?:  {
      __typename: "ModelBrandMemberConnection",
      items:  Array< {
        __typename: "BrandMember",
        id: string,
        member?:  {
          __typename: "Member",
          id: string,
          name: string,
          email: string,
          brandMembers?:  {
            __typename: "ModelBrandMemberConnection",
            nextToken?: string | null,
          } | null,
          subscription?: MemberPlan | null,
          stripeCustomerId?: string | null,
          lastLogin?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        memberId: string,
        brandId: string,
        brand:  {
          __typename: "Brand",
          id: string,
          name: string,
          connections?:  {
            __typename: "Connections",
            id: string,
            brandId: string,
            youtubeChannelName?: string | null,
            youtubeChannelId?: string | null,
            youtubeUserId?: string | null,
            youtubeChannelPictureUrl?: string | null,
            tiktok?: string | null,
            instagramUserId?: string | null,
            instagramUsername?: string | null,
            instagramProfilePictureUrl?: string | null,
            instagramTokenId?: string | null,
            facebookPageId?: string | null,
            facebookPageName?: string | null,
            facebookPagePictureUrl?: string | null,
            facebookTokenId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          connectionId?: string | null,
          categories?: Array< string | null > | null,
          status?:  {
            __typename: "ModelContentStatusConnection",
            nextToken?: string | null,
          } | null,
          statusOrder?: Array< string | null > | null,
          brandProfileUrl?:  {
            __typename: "Media",
            fileName: string,
            objectKey: string,
            size: number,
            dateUploaded: string,
            uploadedBy: string,
            type: string,
            customPrefix?: string | null,
            blurhash?: string | null,
          } | null,
          goals?:  Array< {
            __typename: "BrandGoals",
            platform: Platform,
            start: number,
            goal: number,
          } | null > | null,
          mission?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        accessLevel: AccessLevel,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    subscription?: MemberPlan | null,
    stripeCustomerId?: string | null,
    lastLogin?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateMemberSubscriptionVariables = {
  filter?: ModelSubscriptionMemberFilterInput | null,
};

export type OnUpdateMemberSubscription = {
  onUpdateMember?:  {
    __typename: "Member",
    id: string,
    name: string,
    email: string,
    brandMembers?:  {
      __typename: "ModelBrandMemberConnection",
      items:  Array< {
        __typename: "BrandMember",
        id: string,
        member?:  {
          __typename: "Member",
          id: string,
          name: string,
          email: string,
          brandMembers?:  {
            __typename: "ModelBrandMemberConnection",
            nextToken?: string | null,
          } | null,
          subscription?: MemberPlan | null,
          stripeCustomerId?: string | null,
          lastLogin?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        memberId: string,
        brandId: string,
        brand:  {
          __typename: "Brand",
          id: string,
          name: string,
          connections?:  {
            __typename: "Connections",
            id: string,
            brandId: string,
            youtubeChannelName?: string | null,
            youtubeChannelId?: string | null,
            youtubeUserId?: string | null,
            youtubeChannelPictureUrl?: string | null,
            tiktok?: string | null,
            instagramUserId?: string | null,
            instagramUsername?: string | null,
            instagramProfilePictureUrl?: string | null,
            instagramTokenId?: string | null,
            facebookPageId?: string | null,
            facebookPageName?: string | null,
            facebookPagePictureUrl?: string | null,
            facebookTokenId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          connectionId?: string | null,
          categories?: Array< string | null > | null,
          status?:  {
            __typename: "ModelContentStatusConnection",
            nextToken?: string | null,
          } | null,
          statusOrder?: Array< string | null > | null,
          brandProfileUrl?:  {
            __typename: "Media",
            fileName: string,
            objectKey: string,
            size: number,
            dateUploaded: string,
            uploadedBy: string,
            type: string,
            customPrefix?: string | null,
            blurhash?: string | null,
          } | null,
          goals?:  Array< {
            __typename: "BrandGoals",
            platform: Platform,
            start: number,
            goal: number,
          } | null > | null,
          mission?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        accessLevel: AccessLevel,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    subscription?: MemberPlan | null,
    stripeCustomerId?: string | null,
    lastLogin?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteMemberSubscriptionVariables = {
  filter?: ModelSubscriptionMemberFilterInput | null,
};

export type OnDeleteMemberSubscription = {
  onDeleteMember?:  {
    __typename: "Member",
    id: string,
    name: string,
    email: string,
    brandMembers?:  {
      __typename: "ModelBrandMemberConnection",
      items:  Array< {
        __typename: "BrandMember",
        id: string,
        member?:  {
          __typename: "Member",
          id: string,
          name: string,
          email: string,
          brandMembers?:  {
            __typename: "ModelBrandMemberConnection",
            nextToken?: string | null,
          } | null,
          subscription?: MemberPlan | null,
          stripeCustomerId?: string | null,
          lastLogin?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        memberId: string,
        brandId: string,
        brand:  {
          __typename: "Brand",
          id: string,
          name: string,
          connections?:  {
            __typename: "Connections",
            id: string,
            brandId: string,
            youtubeChannelName?: string | null,
            youtubeChannelId?: string | null,
            youtubeUserId?: string | null,
            youtubeChannelPictureUrl?: string | null,
            tiktok?: string | null,
            instagramUserId?: string | null,
            instagramUsername?: string | null,
            instagramProfilePictureUrl?: string | null,
            instagramTokenId?: string | null,
            facebookPageId?: string | null,
            facebookPageName?: string | null,
            facebookPagePictureUrl?: string | null,
            facebookTokenId?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          connectionId?: string | null,
          categories?: Array< string | null > | null,
          status?:  {
            __typename: "ModelContentStatusConnection",
            nextToken?: string | null,
          } | null,
          statusOrder?: Array< string | null > | null,
          brandProfileUrl?:  {
            __typename: "Media",
            fileName: string,
            objectKey: string,
            size: number,
            dateUploaded: string,
            uploadedBy: string,
            type: string,
            customPrefix?: string | null,
            blurhash?: string | null,
          } | null,
          goals?:  Array< {
            __typename: "BrandGoals",
            platform: Platform,
            start: number,
            goal: number,
          } | null > | null,
          mission?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        accessLevel: AccessLevel,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    subscription?: MemberPlan | null,
    stripeCustomerId?: string | null,
    lastLogin?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateTokenSubscriptionVariables = {
  filter?: ModelSubscriptionTokenFilterInput | null,
};

export type OnCreateTokenSubscription = {
  onCreateToken?:  {
    __typename: "Token",
    id: string,
    createdAt: string,
    brandId: string,
    accessToken: string,
    refreshToken?: string | null,
    tokenType?: string | null,
    expiryDate: string,
    platform: Platform,
    updatedAt: string,
  } | null,
};

export type OnUpdateTokenSubscriptionVariables = {
  filter?: ModelSubscriptionTokenFilterInput | null,
};

export type OnUpdateTokenSubscription = {
  onUpdateToken?:  {
    __typename: "Token",
    id: string,
    createdAt: string,
    brandId: string,
    accessToken: string,
    refreshToken?: string | null,
    tokenType?: string | null,
    expiryDate: string,
    platform: Platform,
    updatedAt: string,
  } | null,
};

export type OnDeleteTokenSubscriptionVariables = {
  filter?: ModelSubscriptionTokenFilterInput | null,
};

export type OnDeleteTokenSubscription = {
  onDeleteToken?:  {
    __typename: "Token",
    id: string,
    createdAt: string,
    brandId: string,
    accessToken: string,
    refreshToken?: string | null,
    tokenType?: string | null,
    expiryDate: string,
    platform: Platform,
    updatedAt: string,
  } | null,
};
