import { createTheme, inputLabelClasses, tableCellClasses } from "@mui/material";
import type { Components } from "@mui/material/styles/components";

// import { CheckboxCheckedIcon } from "../../components/checkbox-checked-icon";
// import { CheckboxIcon } from "../../components/checkbox-icon";
// import { CheckboxIntermediateIcon } from "../../components/checkbox-intermediate-icon";

// Used only to create transitions
const muiTheme = createTheme();

export const createComponents = (): Components => ({
  MuiAvatar: {
    styleOverrides: {
      root: {
        fontSize: 14,
        fontWeight: 600,
        letterSpacing: 0,
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        borderRadius: "12px",
        textTransform: "none",
      },
      sizeSmall: {
        padding: "6px 16px",
      },
      sizeMedium: {
        padding: "8px 20px",
      },
      sizeLarge: {
        padding: "11px 24px",
      },
      textSizeSmall: {
        padding: "7px 12px",
      },
      textSizeMedium: {
        padding: "9px 16px",
      },
      textSizeLarge: {
        padding: "12px 16px",
      },
    },
  },
  MuiCard: {
    styleOverrides: {
      root: {
        borderRadius: 20,
      },
    },
  },
  MuiCardContent: {
    styleOverrides: {
      root: {
        padding: "32px 24px",
        "&:last-child": {
          paddingBottom: "32px",
        },
      },
    },
  },
  MuiCardHeader: {
    defaultProps: {
      titleTypographyProps: {
        variant: "h6",
      },
      subheaderTypographyProps: {
        variant: "body2",
      },
    },
    styleOverrides: {
      root: {
        padding: "32px 24px 16px",
      },
    },
  },
  // MuiCheckbox: {
  //   defaultProps: {
  //     checkedIcon: <CheckboxCheckedIcon />,
  //     color: "primary",
  //     icon: <CheckboxIcon />,
  //     indeterminateIcon: <CheckboxIntermediateIcon />,
  //   },
  // },
  MuiChip: {
    styleOverrides: {
      root: {
        fontWeight: 500,
      },
    },
  },
  MuiCssBaseline: {
    styleOverrides: {
      "*": {
        boxSizing: "border-box",
      },
      html: {
        MozOsxFontSmoothing: "grayscale",
        WebkitFontSmoothing: "antialiased",
        display: "flex",
        flexDirection: "column",
        minHeight: "100%",
        width: "100%",
      },
      body: {
        display: "flex",
        flex: "1 1 auto",
        flexDirection: "column",
        minHeight: "100%",
        width: "100%",
      },
      "#__next": {
        display: "flex",
        flex: "1 1 auto",
        flexDirection: "column",
        height: "100%",
        width: "100%",
      },
      "#nprogress": {
        pointerEvents: "none",
      },
      "#nprogress .bar": {
        height: 3,
        left: 0,
        position: "fixed",
        top: 0,
        width: "100%",
        zIndex: 2000,
      },
    },
  },
  MuiIconButton: {
    styleOverrides: {
      sizeSmall: {
        padding: 4,
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      input: {
        "&::placeholder": {
          opacity: 1,
        },
      },
    },
  },
  MuiInput: {
    styleOverrides: {
      input: {
        fontSize: 14,
        fontWeight: 500,
        lineHeight: "24px",
      },
    },
  },
  MuiFilledInput: {
    styleOverrides: {
      root: {
        backgroundColor: "transparent",
        borderRadius: 8,
        borderStyle: "solid",
        borderWidth: 1,
        overflow: "hidden",
        transition: muiTheme.transitions.create(["border-color", "box-shadow"]),
        "&:before": {
          display: "none",
        },
        "&:after": {
          display: "none",
        },
      },
      input: {
        fontSize: 14,
        fontWeight: 500,
        lineHeight: "24px",
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      input: {
        fontSize: 14,
        fontWeight: 500,
        lineHeight: "24px",
      },
      notchedOutline: {
        transition: muiTheme.transitions.create(["border-color", "box-shadow"]),
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        fontSize: "14px",
        fontWeight: 500,
        [`&.${inputLabelClasses.filled}`]: {
          transform: "translate(12px, 18px) scale(1)",
        },
        [`&.${inputLabelClasses.shrink}`]: {
          [`&.${inputLabelClasses.standard}`]: {
            transform: "translate(0, -1.5px) scale(0.85)",
          },
          [`&.${inputLabelClasses.filled}`]: {
            transform: "translate(12px, 6px) scale(0.85)",
          },
          [`&.${inputLabelClasses.outlined}`]: {
            transform: "translate(14px, -9px) scale(0.85)",
          },
        },
      },
    },
  },
  MuiLinearProgress: {
    styleOverrides: {
      root: {
        borderRadius: 3,
        overflow: "hidden",
      },
    },
  },
  MuiLink: {
    defaultProps: {
      underline: "hover",
    },
  },
  MuiListItemIcon: {
    styleOverrides: {
      root: {
        marginRight: "16px",
        minWidth: "unset",
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        backgroundImage: "none",
        boxShadow: "0px 0px 12px -5px rgba(0,0,0,0.30)",
      },
    },
  },
  MuiPopover: {
    defaultProps: {
      elevation: 16,
    },
  },
  MuiRadio: {
    defaultProps: {
      color: "primary",
    },
  },
  MuiSwitch: {
    defaultProps: {
      color: "primary",
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        fontSize: 14,
        fontWeight: 500,
        lineHeight: 1.71,
        minWidth: "auto",
        paddingLeft: 0,
        paddingRight: 0,
        textTransform: "none",
        "& + &": {
          marginLeft: 24,
        },
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        padding: "15px 16px",
      },
    },
  },
  MuiTableHead: {
    styleOverrides: {
      root: {
        borderBottom: "none",
        [`& .${tableCellClasses.root}`]: {
          borderBottom: "none",
          fontSize: 12,
          fontWeight: 600,
          lineHeight: 1,
          letterSpacing: 0.5,
          textTransform: "uppercase",
        },
        [`& .${tableCellClasses.paddingCheckbox}`]: {
          paddingTop: 4,
          paddingBottom: 4,
        },
      },
    },
  },
  MuiTextField: {
    defaultProps: {
      variant: "filled",
    },
  },
  MuiDrawer: {
    styleOverrides: {
      paper: {
        backgroundColor: "#1C2536",
      },
    },
  },
});
