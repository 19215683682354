import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Button, ButtonGroup, Menu, MenuItem, Stack, Typography, useTheme } from "@mui/material";
import dayjs from "dayjs";
import React, { useCallback, useMemo, useState } from "react";

import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { selectComparisonTimeframe, selectTimeframe, setRange, setTimeframe } from "../redux/slices/analyticsSlice";

export type RangeShortcutOption = {
  label: string;
  deltaDays: number;
};

export const rangeShortcuts: RangeShortcutOption[] = [
  {
    label: "Last 7 days",
    deltaDays: 7,
  },
  {
    label: "Last 14 Days",
    deltaDays: 14,
  },
  {
    label: "Last 30 Days",
    deltaDays: 30,
  },
  // {
  //   label: "Last 60 Days",
  //   deltaDays: 60,
  // },
  // {
  //   label: "Last 90 Days",
  //   deltaDays: 90,
  // },
];

const RangeShortcutsButton = () => {
  const today = useMemo(() => dayjs(), []);

  const theme = useTheme();
  const dispatch = useAppDispatch();

  const [dateRangeOption, setDateRangeOption] = useState<RangeShortcutOption>(rangeShortcuts[0]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { timeframe } = useAppSelector((state) => state.analytics);

  const timeframeFormatted = useAppSelector(selectTimeframe);
  const comparisonTimeframeFormatted = useAppSelector(selectComparisonTimeframe);

  const handleMenuToggle = useCallback((event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => setAnchorEl(null), []);

  const handleSetRange = useCallback(
    (rangeDate: number) => {
      const dayBefore = today.subtract(1, "day");

      dispatch(
        setTimeframe({
          timeframe: [today.subtract(rangeDate, "day").toISOString(), dayBefore.toISOString()],
          comparisonTimeframe: [
            today.subtract(rangeDate * 2, "day").toISOString(),
            today.subtract(rangeDate + 1, "day").toISOString(),
          ],
        }),
      );
    },
    [dispatch, today],
  );

  const handleRangeClick = useCallback(
    (option: RangeShortcutOption) => {
      handleClose();
      setDateRangeOption(option);
      dispatch(setRange(option));
      handleSetRange(option.deltaDays);
    },
    [dispatch, handleClose, handleSetRange],
  );

  return (
    <>
      <Stack spacing={1} alignItems="flex-end">
        <Stack direction="row" alignItems="center" justifyContent="flex-end">
          <Stack direction="row" spacing={2} alignItems="center">
            {timeframe && timeframe.length > 0 && (
              <Typography fontWeight={theme.typography.fontWeightMedium}>{timeframeFormatted}</Typography>
            )}

            <ButtonGroup variant="contained" size="small">
              <Button sx={{ cursor: "default" }}>{dateRangeOption.label}</Button>

              <Button size="small" onClick={handleMenuToggle}>
                <ExpandMoreIcon />
              </Button>
            </ButtonGroup>
          </Stack>
        </Stack>

        <Typography variant="caption">{comparisonTimeframeFormatted}</Typography>
      </Stack>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "&:before": {
              // eslint-disable-next-line quotes
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
      >
        {rangeShortcuts.map((option) => (
          <MenuItem key={option.label} sx={{ display: "block", py: 1 }} onClick={() => handleRangeClick(option)}>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="body1">{option.label}</Typography>
            </Stack>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default RangeShortcutsButton;
