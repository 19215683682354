import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { BrandMember, Member, Platform } from "../../API";
import { member } from "../../api/memberApi";
import { RootState } from "../store";

export type ContentView = "grid" | "list" | "kanban";

export type UserState = {
  memberId: string | undefined;
  selectedBrandMemberId: string | undefined;
  member: Member | undefined;
  selectedBrandMember: BrandMember | undefined;
  isLoading: boolean;
  isFirstTimeLogin: boolean;
  selectedContentView: ContentView;
  openAddBrandDialog: boolean;
};

export const initialUserState: UserState = {
  memberId: undefined,
  selectedBrandMemberId: undefined,
  member: undefined,
  selectedBrandMember: undefined,
  isLoading: false,
  isFirstTimeLogin: false,
  selectedContentView: "kanban",
  openAddBrandDialog: false,
};

const userSlice = createSlice({
  name: "user",
  initialState: initialUserState,
  reducers: {
    setMemberId(state, action: PayloadAction<string>) {
      state.memberId = action.payload;
    },
    setIsFirstTimeLogin(state, action: PayloadAction<boolean>) {
      state.isFirstTimeLogin = action.payload;
    },
    setSelectedBrandMemberId(state, action: PayloadAction<string>) {
      state.selectedBrandMemberId = action.payload;
    },
    setMember(state, action: PayloadAction<Member>) {
      state.member = action.payload;
    },
    setSelectedBrandMember(state, action: PayloadAction<BrandMember>) {
      state.selectedBrandMember = action.payload;
    },
    setSelectedContentView(state, action: PayloadAction<ContentView>) {
      state.selectedContentView = action.payload;
    },
    selectBrand(state, action: PayloadAction<BrandMember>) {
      state.selectedBrandMember = action.payload;
      state.selectedBrandMemberId = action.payload.id;
    },
    setOpenAddBrandDialog(state, action: PayloadAction<boolean>) {
      state.openAddBrandDialog = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(member.endpoints.getMember.matchFulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.member = payload.getMember as Member;
      state.memberId = payload.getMember?.id;

      state.selectedBrandMember =
        (payload.getMember?.brandMembers?.items.find((bm) => bm?.id === state.selectedBrandMemberId) as BrandMember) ??
        undefined;
    });
    builder.addMatcher(member.endpoints.getMember.matchPending, (state) => {
      state.isLoading = true;
    });
    builder.addMatcher(member.endpoints.getMember.matchRejected, (state) => {
      state.isLoading = false;
    });
    builder.addMatcher(member.endpoints.getBrandMember.matchPending, (state) => {
      state.isLoading = true;
    });
    builder.addMatcher(member.endpoints.getBrandMember.matchFulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addMatcher(member.endpoints.getBrandMember.matchRejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const {
  setMemberId,
  setSelectedBrandMemberId,
  setMember,
  setSelectedBrandMember,
  setIsFirstTimeLogin,
  setSelectedContentView,
  selectBrand,
  setOpenAddBrandDialog,
} = userSlice.actions;
export default userSlice.reducer;

export const selectUserConnectionStatus = (state: RootState) => {
  const isBrandConnected = !!state.user.selectedBrandMember?.brandId;
  const connections = state.user.selectedBrandMember?.brand.connections;
  const isSocialMediaConnected = connections?.instagramUserId || connections?.facebookPageId;

  return {
    isBrandConnected,
    isSocialMediaConnected,
  };
};

export const selectIsInstagramConnected = (state: RootState) => {
  return !!state.user.selectedBrandMember?.brand.connections?.instagramUserId;
};

export const selectIsFacebookConnected = (state: RootState) => {
  return !!state.user.selectedBrandMember?.brand.connections?.facebookPageId;
};

export const selectIsYouTubeConnected = (state: RootState) => {
  return !!state.user.selectedBrandMember?.brand.connections?.youtubeChannelId;
};

export const selectConnectedPlatforms = (state: RootState) => {
  const connections = state.user.selectedBrandMember?.brand.connections;
  const connectedPlatforms = [];

  if (connections?.instagramUserId) connectedPlatforms.push(Platform.INSTAGRAM);
  if (connections?.facebookPageId) connectedPlatforms.push(Platform.FACEBOOK);
  if (connections?.youtubeChannelId) connectedPlatforms.push(Platform.YOUTUBE);

  return connectedPlatforms;
};

export const selectHasSocialMediaConnection = (state: RootState) => {
  const connections = state.user.selectedBrandMember?.brand.connections;
  return !!(connections?.instagramUserId || connections?.facebookPageId || connections?.youtubeChannelId);
};
