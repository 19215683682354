import GraphQLAPI, { GraphQLOptions, GraphQLResult } from "@aws-amplify/api-graphql";
import { createApi } from "@reduxjs/toolkit/query/react";

type GraphqlBaseQuery = {
  graphqlQuery: string;
  variables?: Record<string, unknown>;
};

type ApiResponse<T> = {
  data: T;
  meta?: Record<string, unknown>;
};

export const defaultQueryLimit = 1000;

const graphqlBaseQuery =
  <T,>() =>
  async ({ graphqlQuery, variables }: GraphqlBaseQuery): Promise<ApiResponse<T>> => {
    try {
      const options: GraphQLOptions = {
        query: graphqlQuery,
        variables,
      };

      const result = (await GraphQLAPI.graphql(options)) as ApiResponse<T>;

      return result;
    } catch (error) {
      const { data, errors } = error as GraphQLResult;
      let errorMessage = null;

      if (errors && errors.length > 0) {
        errorMessage = errors[0].message;
        console.error({ error: errorMessage });
      }

      // TODO: Improve this piece of code, passing error to meta is not the best way to do it
      return { data: data as T, meta: { error: errorMessage } };
    }
  };;

export const rootApi = createApi({
  tagTypes: ["Member", "Brand", "BrandConnections", "ContentBoard", "Calendar", "BrandMember", "ContentListAndBoardView"],
  baseQuery: graphqlBaseQuery(),
  endpoints: () => ({} as const),
});
