import {
  Brand,
  CreateBrandMutation,
  CreateBrandMutationVariables,
  DeleteBrandMemberMutation,
  DeleteBrandMemberMutationVariables,
  DeleteBrandMutation,
  DeleteBrandMutationVariables,
  GetBrandQuery,
  GetBrandQueryVariables,
  ListBrandMembersByBrandIdQuery,
  ListBrandMembersByBrandIdQueryVariables,
  UpdateBrandMemberMutation,
  UpdateBrandMemberMutationVariables,
  UpdateBrandMutation,
  UpdateBrandMutationVariables,
} from "../API";
import { createBrand, deleteBrand, deleteBrandMember, updateBrand, updateBrandMember } from "../graphql/mutations";
import { getBrand, listBrandMembersByBrandId } from "../graphql/queries";
import { rootApi } from "./rootApi";

export const brand = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    createBrand: builder.mutation<CreateBrandMutation, CreateBrandMutationVariables>({
      query: (variables) => ({
        graphqlQuery: createBrand,
        variables,
      }),
    }),

    updateBrand: builder.mutation<UpdateBrandMutation, UpdateBrandMutationVariables>({
      query: (variables) => ({
        graphqlQuery: updateBrand,
        variables,
      }),
      invalidatesTags: ["Member", "BrandMember"],
    }),

    getBrand: builder.query<Brand, GetBrandQueryVariables>({
      query: (variables) => ({
        graphqlQuery: getBrand,
        variables,
      }),
      transformResponse: (response: GetBrandQuery) => response.getBrand as Brand,
      providesTags: ["BrandConnections", "BrandMember"],
    }),

    deleteBrand: builder.mutation<DeleteBrandMutation, DeleteBrandMutationVariables>({
      query: (variables) => ({
        graphqlQuery: deleteBrand,
        variables,
      }),
      invalidatesTags: ["BrandMember"],
    }),

    // ======================================================
    // ============ BRAND MEMBER ============================
    // ======================================================

    deleteBrandMember: builder.mutation<DeleteBrandMemberMutation, DeleteBrandMemberMutationVariables>({
      query: (variables) => ({
        graphqlQuery: deleteBrandMember,
        variables,
      }),
      invalidatesTags: ["BrandMember"],
    }),

    listBrandMembersByBrandId: builder.query<ListBrandMembersByBrandIdQuery, ListBrandMembersByBrandIdQueryVariables>({
      query: (variables) => ({
        graphqlQuery: listBrandMembersByBrandId,
        variables,
      }),
    }),

    updateBrandMember: builder.mutation<UpdateBrandMemberMutation, UpdateBrandMemberMutationVariables>({
      query: (variables) => ({
        graphqlQuery: updateBrandMember,
        variables,
      }),
      invalidatesTags: ["BrandMember"],
    }),
  }),
});

export const {
  useCreateBrandMutation,
  useUpdateBrandMutation,
  useGetBrandQuery,
  useLazyGetBrandQuery,
  useDeleteBrandMutation,
  useDeleteBrandMemberMutation,
  useUpdateBrandMemberMutation,
  useListBrandMembersByBrandIdQuery,
} = brand;
