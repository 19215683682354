import { useAuthenticator } from "@aws-amplify/ui-react";
import React, { lazy, Suspense, useEffect, useRef } from "react";
import { Route, Routes } from "react-router-dom";

import Loading from "../components/Loading/Loading";
import useSetMemberState from "../hooks/useSetMemberState";

const MainLayout = lazy(() => import("../layout/MainLayout"));
const CalendarPage = lazy(() => import("../pages/Calendar/CalendarPage"));
const ConnectionsPage = lazy(() => import("../pages/Connections/ConnectionsPage"));
const DashboardPage = lazy(() => import("../pages/Analytics/AnalyticsPage"));
const OverviewPage = lazy(() => import("../pages/Overview/OverviewPage"));
const LoginPage = lazy(() => import("../pages/Login/LoginPage"));
const PlanningPage = lazy(() => import("../pages/Planning/PlanningPage"));
const SettingsPage = lazy(() => import("../pages/Settings/SettingsPage"));
const ApprovalsPage = lazy(() => import("../pages/Approvals/ApprovalsPage"));
const RequireAuth = lazy(() => import("./RequireAuth"));

const Router: React.FC = () => {
  const { authStatus } = useAuthenticator((context) => [context.user]);

  const { initializeMemberState } = useSetMemberState();

  const initialLoadDone = useRef(false);

  useEffect(() => {
    const setMemberState = async () => {
      await initializeMemberState();
    };

    if (authStatus === "authenticated" && !initialLoadDone.current) {
      setMemberState();
      initialLoadDone.current = true;
    }
  }, [authStatus]);

  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route path="*" element={<h1>Not Found</h1>} />
        <Route path="/" element={<LoginPage />} />

        <Route element={<MainLayout />}>
          <Route element={<RequireAuth />}>
            <Route path="overview" element={<OverviewPage />} />
            <Route path="analytics" element={<DashboardPage />} />
            <Route path="schedule" element={<CalendarPage />} />
            <Route path="connections" element={<ConnectionsPage />} />
            <Route path="planning" element={<PlanningPage />} />
            <Route path="settings" element={<SettingsPage />} />
            <Route path="approvals" element={<ApprovalsPage />} />
          </Route>
        </Route>
      </Routes>
    </Suspense>
  );
};

export default Router;
