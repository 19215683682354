/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-1",
    "aws_appsync_graphqlEndpoint": "https://x7phrvtagjd55cdrmmlvsjxzd4.appsync-api.us-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "mediamechatgptapi",
            "endpoint": "https://011m69jq5c.execute-api.us-west-1.amazonaws.com/dev",
            "region": "us-west-1"
        },
        {
            "name": "mediameresizeapi",
            "endpoint": "https://3uxp57wgk7.execute-api.us-west-1.amazonaws.com/dev",
            "region": "us-west-1"
        },
        {
            "name": "mediametiktokapi",
            "endpoint": "https://ytmizr7ea9.execute-api.us-west-1.amazonaws.com/dev",
            "region": "us-west-1"
        },
        {
            "name": "mediameyoutubeapi",
            "endpoint": "https://94tr3sem2k.execute-api.us-west-1.amazonaws.com/dev",
            "region": "us-west-1"
        },
        {
            "name": "stripeapi",
            "endpoint": "https://s5bf183hne.execute-api.us-west-1.amazonaws.com/dev",
            "region": "us-west-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-west-1:3190be67-d40c-46de-984e-26b767e49e23",
    "aws_cognito_region": "us-west-1",
    "aws_user_pools_id": "us-west-1_ZEFcIRmcm",
    "aws_user_pools_web_client_id": "1huhqj10knvne88obft1ej9m6h",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "mediamestoratebucket173923-dev",
    "aws_user_files_s3_bucket_region": "us-west-1"
};


export default awsmobile;
