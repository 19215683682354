import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Platform } from "../../API";

export type DashboardState = {
  selectedPlatform: Platform;
};

export const initialDashboardState: DashboardState = {
  selectedPlatform: Platform.INSTAGRAM,
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: initialDashboardState,
  reducers: {
    setSelectedPlatform: (state, action: PayloadAction<Platform>) => {
      state.selectedPlatform = action.payload;
    },
  },
});

export const { setSelectedPlatform } = dashboardSlice.actions;
export default dashboardSlice.reducer;
