import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosError, AxiosInstance } from "axios";
import dayjs, { Dayjs } from "dayjs";

import { Brand } from "../../API";
import {
  DataItemIG,
  InsightData,
  InstagramAccountMedia,
  InstagramFeedPost,
  InstagramInsightsData,
  InstagramMetricsValue,
} from "../../types/instagramApi.types";
import { FACEBOOK_BASE_URL, FACEBOOK_DEFAULT_VERSION } from "../../utils/constants";

const getAxiosInstance = (accessToken: string, version = FACEBOOK_DEFAULT_VERSION) => {
  return axios.create({
    baseURL: `${FACEBOOK_BASE_URL}/${version}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

/**
 * Instagram Followers
 *
 * Get the total number of followers from the Instagram profile
 */

export enum InstagramAccountInfoField {
  FollowersCount = "followers_count",
  FollowingCount = "follows_count",
  MediaCount = "media_count",
  Username = "username",
}

type GetInstagramAccountInfoParams = {
  accessToken: string;
  brand: Brand;
  fields?: InstagramAccountInfoField[];
};

type InstagramAccountInfo = {
  followers_count?: number;
  follows_count?: number;
  media_count?: number;
  username?: string;
};

export const getInstagramAccountInfo = createAsyncThunk(
  "instagram/getInstagramBasicInfo",
  async (
    {
      accessToken,
      brand,
      fields = [
        InstagramAccountInfoField.Username,
        InstagramAccountInfoField.FollowersCount,
        InstagramAccountInfoField.FollowingCount,
        InstagramAccountInfoField.MediaCount,
      ],
    }: GetInstagramAccountInfoParams,
    { rejectWithValue },
  ) => {
    try {
      if (!brand.connections?.instagramUserId) return;

      const axiosInstance = getAxiosInstance(accessToken);

      const fieldsParams = fields.join(",");

      const response = await axiosInstance.get(brand.connections?.instagramUserId, {
        params: {
          fields: fieldsParams,
        },
      });
      return response.data as InstagramAccountInfo;
    } catch (error) {
      let errorMessage = "Error fetching Instagram account info.";
      if (error instanceof AxiosError) {
        errorMessage = error.response?.data.message || errorMessage;
      }
      return rejectWithValue(errorMessage);
    }
  },
);

/**
 * Instagram Metrics
 *
 * Get the reach, impressions and followers overtime
 */

type GetInstagramMetricsParams = {
  accessToken: string;
  brand: Brand;
  since: number;
  until: number;
};

/**
 * Get the Instagram account reach, impressions, follower count, profile views, and website clicks
 *
 * Reference: https://developers.facebook.com/docs/instagram-api/reference/ig-user/insights
 * @returns
 */
export const getInstagramInsightsOvertime = async ({ accessToken, brand, since, until }: GetInstagramMetricsParams) => {
  try {
    const axiosInstance = getAxiosInstance(accessToken, FACEBOOK_DEFAULT_VERSION);

    const response = await axiosInstance.get(`${brand.connections?.instagramUserId}/insights`, {
      params: {
        metric: "reach,impressions,follower_count,profile_views,website_clicks",
        period: "day",
        // metric_type: "time_series", // tells the API to aggregate results by time period.
        since,
        until,
      },
    });

    const instagramResponse = response.data.data as InstagramInsightsData[];

    const [reach, impressions, followers, profileViews, websiteClicks] = instagramResponse;

    return [reach.values, impressions.values, followers?.values, profileViews.values, websiteClicks.values];
  } catch (error) {
    let errorMessage = "Error fetching Instagram account info.";
    if (error instanceof AxiosError) {
      errorMessage = error.response?.data.message || errorMessage;
    }

    console.error(error);
    return [[], [], [], [], []];
  }
};

export const getInstagramInsightsTotalValue = async ({ accessToken, brand, since, until }: GetInstagramMetricsParams) => {
  const axiosInstance = getAxiosInstance(accessToken);

  const response = await axiosInstance.get(`${brand.connections?.instagramUserId}/insights`, {
    params: {
      metric: "reach,impressions,website_clicks,profile_views",
      period: "day",
      metric_type: "total_value",
      since,
      until,
    },
  });

  const instagramResponse = response.data.data as InsightData[];

  return [
    instagramResponse[0].total_value.value,
    instagramResponse[1].total_value.value,
    instagramResponse[2].total_value.value,
    instagramResponse[3].total_value.value,
  ];
};

export const getInstagramFollowersOverTime = async ({ accessToken, brand, since, until }: GetInstagramMetricsParams) => {
  const axiosInstance = getAxiosInstance(accessToken);

  const response = await axiosInstance.get(`${brand.connections?.instagramUserId}/insights`, {
    params: {
      metric: "follower_count",
      period: "day",
      since,
      until,
    },
  });

  const openAiResponseData = response.data.data as InstagramInsightsData[];

  return openAiResponseData.length > 0 ? openAiResponseData[0].values : [];
};

export type InstagramMetrics = {
  reach: InstagramMetricsValue;
  impressions: InstagramMetricsValue;
  followers: InstagramMetricsValue;
};

type GetInstagramMetricsThunkParams = {
  accessToken: string;
  brand: Brand;
  timeframe: string[];
  comparisonTimeframe: string[];
};

export const getInstagramMetrics = createAsyncThunk(
  "instagram/getInstagramMetrics",
  async ({ accessToken, brand, timeframe, comparisonTimeframe }: GetInstagramMetricsThunkParams) => {
    const [reach, impressions, followers, profileViews, websiteClicks] = await getInstagramInsightsOvertime({
      accessToken,
      brand,
      since: dayjs(timeframe[0]).unix(),
      until: dayjs(timeframe[1]).unix(),
    });

    const [comparisonReach, comparisonImpressions, comparisonFollowers, comparisonProfileViews, comparisonWebsiteClicks] =
      await getInstagramInsightsOvertime({
        accessToken,
        brand,
        since: dayjs(comparisonTimeframe[0]).unix(),
        until: dayjs(comparisonTimeframe[1]).unix(),
      });

    const [totalReach, totalImpressions, totalWebsiteClicks, totalProfileViews] = await getInstagramInsightsTotalValue({
      accessToken,
      brand,
      since: dayjs(comparisonTimeframe[0]).unix(),
      until: dayjs(comparisonTimeframe[1]).unix(),
    });

    return {
      comparisonFollowers,
      followers,
      reach,
      comparisonReach,
      impressions,
      comparisonImpressions,
      totalReach,
      totalImpressions,
      profileViews,
      comparisonProfileViews,
      totalProfileViews,
      comparisonWebsiteClicks,
      websiteClicks,
      totalWebsiteClicks,
    };
  },
);

type GetInstagramAudienceInsightsThunkParams = {
  accessToken: string;
  brand: Brand;
};

export const getInstagramAudienceInsights = createAsyncThunk(
  "instagram/getInstagramAudienceInsights",
  async ({ accessToken, brand }: GetInstagramAudienceInsightsThunkParams) => {
    const axiosInstance = getAxiosInstance(accessToken);

    const response = await axiosInstance.get(`${brand.connections?.instagramUserId}/insights`, {
      params: {
        metric: "audience_country,audience_city",
        period: "lifetime",
      },
    });

    const audienceInsights = response.data.data as any;

    return {
      audienceCountryInsights: audienceInsights[0].values[0].value,
      audienceCityInsights: audienceInsights[1].values[0].value,
    };
  },
);

type GetInstagramProfileFeedParams = {
  accessToken: string;
  brand: Brand;
};

/**
 * Instagram Profile Feed
 *
 * Get the latest posts from the Instagram profile
 * and also get the total number of posts, followers and following
 */

export enum InstagramAccountMediaField {
  ThumbnailUrl = "thumbnail_url",
  MediaUrl = "media_url",
  CommentsCount = "comments_count",
  LikeCount = "like_count",
  MediaProductType = "media_product_type",
  PermaLink = "permalink",
  Timestamp = "timestamp",
  IsSharedToFeed = "is_shared_to_feed",
  Caption = "caption",
}

type GetInstagramFollowerCounts = {
  accessToken: string;
  brand: Brand;
  timeframe: string[];
  comparisonTimeframe: string[];
};

const getFollowerCounts = async (
  axiosInstance: AxiosInstance,
  instagramUserId: string | null | undefined,
  startDate: Dayjs,
  endDate: Dayjs,
) => {
  const results = [];

  let date = startDate;
  while (date.isBefore(endDate)) {
    const nextDate = date.clone().add(1, "day");

    const response = await axiosInstance.get(`${instagramUserId}/insights`, {
      params: {
        metric: "follows_and_unfollows",
        period: "day",
        metric_type: "total_value",
        since: date.unix(),
        until: nextDate.unix(),
        breakdown: "follow_type",
      },
    });

    const instagramResponse = (response.data.data as DataItemIG[])[0].total_value.breakdowns[0].results;

    if (instagramResponse) {
      const follows = instagramResponse.find((item) => item.dimension_values[0] === "FOLLOWER")?.value || 0;
      const unfollows = instagramResponse.find((item) => item.dimension_values[0] === "NON_FOLLOWER")?.value || 0;

      results.push({ date: nextDate.format("YYYY-MM-DD"), follows, unfollows });
    }

    date = nextDate;
  }

  return results;
};

export const getInstagramFollowerCounts = createAsyncThunk(
  "instagram/getInstagramFollowerCounts",
  async ({ accessToken, brand, timeframe, comparisonTimeframe }: GetInstagramFollowerCounts) => {
    const axiosInstance = getAxiosInstance(accessToken);

    const instagramUserId = brand.connections?.instagramUserId;

    const startDate = dayjs(timeframe[0]).subtract(1, "day");
    const endDate = dayjs(timeframe[1]);

    const comparisonStartDate = dayjs(comparisonTimeframe[0]).subtract(1, "day");
    const comparisonEndDate = dayjs(comparisonTimeframe[1]);

    const followsCounts = await getFollowerCounts(axiosInstance, instagramUserId, startDate, endDate);
    const comparisonFollowsCounts = await getFollowerCounts(
      axiosInstance,
      instagramUserId,
      comparisonStartDate,
      comparisonEndDate,
    );

    return {
      followsCounts,
      comparisonFollowsCounts,
    };
  },
);

type GetInstagramOnlineActivity = {
  accessToken: string;
  brand: Brand;
};

export const getInstagramOnlineActivity = createAsyncThunk(
  "instagram/getInstagramOnlineActivity",
  async ({ accessToken, brand }: GetInstagramOnlineActivity) => {
    const axiosInstance = getAxiosInstance(accessToken);

    const now = dayjs();

    // calculate the previous Monday
    const since = now.subtract(now.day() + 6, "day").startOf("day");

    // calculate the previous Sunday
    const until = since.add(6, "day").endOf("day");

    const response = await axiosInstance.get(`${brand.connections?.instagramUserId}/insights`, {
      params: {
        metric: "online_followers",
        period: "lifetime",
        since,
        until,
      },
    });

    const onlineActivity = response.data.data as any;

    return onlineActivity[0].values;
  },
);

/**
 * Get the latest posts from the Instagram profile
 *
 * docs: https://developers.facebook.com/docs/instagram-basic-display-api/reference/media/
 *
 * Here are some examples of the types of metrics and parameters that you can include in the insights field for different types of Instagram media:
 *
 * For images: impressions, reach, total_interactions, saved, carousel_swipe
 * For videos: impressions, reach, total_interactions, saved, video_views, carousel_swipe
 * For carousels: carousel_album_engagement, carousel_album_impressions, carousel_album_reach, carousel_album_saved, carousel_album_video_views
 *
 */
export const getInstagramProfileFeed = createAsyncThunk(
  "instagram/getInstagramProfileFeed",
  async ({ accessToken, brand }: GetInstagramProfileFeedParams) => {
    if (!brand.connections?.instagramUserId) {
      console.error("No instagram user id, try reconnecting your Instagram account.");
      return;
    }

    const axiosInstance = getAxiosInstance(accessToken, FACEBOOK_DEFAULT_VERSION);

    const response = await axiosInstance.get(brand.connections?.instagramUserId, {
      params: {
        fields: "followers_count,follows_count,media_count",
      },
    });

    const totalFollowers = (response.data as InstagramAccountInfo).followers_count;

    let fields = [
      InstagramAccountMediaField.Caption,
      InstagramAccountMediaField.ThumbnailUrl,
      InstagramAccountMediaField.MediaUrl,
      InstagramAccountMediaField.CommentsCount,
      InstagramAccountMediaField.LikeCount,
      InstagramAccountMediaField.MediaProductType,
      InstagramAccountMediaField.PermaLink,
      InstagramAccountMediaField.Timestamp,
      InstagramAccountMediaField.IsSharedToFeed,
    ].join(",");

    fields += ",insights.metric(impressions,reach,total_interactions,saved,video_views,plays,shares)";

    const userInstagramMedia = await axiosInstance.get(`${brand.connections?.instagramUserId}/media`, {
      params: {
        fields: fields,
        limit: 30,
      },
    });

    const instagramFeedPosts = userInstagramMedia.data.data.map((post: InstagramAccountMedia) => {
      const likes = post.like_count || 0;
      const comments = post.comments_count || 0;
      const reach = post.insights?.data.find((insight) => insight.name === "reach")?.values[0].value || 0;
      const saved = post.insights?.data.find((insight) => insight.name === "saved")?.values[0].value || 0;
      let engagement =
        post.insights?.data.find((insight) => insight.name === "total_interactions")?.values[0].value || undefined;
      const impressions = post.insights?.data.find((insight) => insight.name === "impressions")?.values[0].value || 0;
      const views = post.insights?.data.find((insight) => insight.name === "plays")?.values[0].value || 0;
      const shares = post.insights?.data.find((insight) => insight.name === "shares")?.values[0].value || 0;

      if (engagement === undefined) {
        engagement = likes + comments + shares;
      }

      const score = engagement / (totalFollowers || 0);

      return {
        ...post,
        insights: {
          reach,
          saved,
          engagement,
          impressions,
          views,
          shares,
          score,
        },
      };
    });

    return instagramFeedPosts as InstagramFeedPost[];
  },
);

/**
 * Setting retries with 3 seconds delay, as async video upload may take a while in the backed to return success
 * @param {*} ms
 * @returns
 */
function _wait(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const isInstagramUploadSuccessful = async (axiosInstance: AxiosInstance, mediaId: string) => {
  const maxRetries = 14;

  for (let i = 0; i <= maxRetries; i++) {
    if (i > maxRetries) return false;

    const response = await axiosInstance.get(`${mediaId}?fields=status_code`);

    if (response.data.status_code === "ERROR") {
      console.log("Instagram upload failed");
      return false;
    }

    if (response.data.status_code !== "FINISHED") {
      await _wait(12000);
    } else {
      console.log("Instagram upload successful");
      return true;
    }
  }

  return false;
};

export type InstagramFollows = {
  date: string;
  follows: number;
  unfollows: number;
};

type AudienceInsight = {
  [key: string]: number;
};

export type InstagramState = {
  followers: InstagramMetricsValue[] | null;
  comparisonFollowers: InstagramMetricsValue[] | null;
  totalFollowers: number | null;
  reach: InstagramMetricsValue[] | null;
  comparisonReach: InstagramMetricsValue[] | null;
  totalReach: number | null;
  impressions: InstagramMetricsValue[] | null;
  comparisonImpressions: InstagramMetricsValue[] | null;
  totalImpressions: number | null;
  profileViews: InstagramMetricsValue[] | null;
  comparisonProfileViews: InstagramMetricsValue[] | null;
  totalProfileViews: number | null;
  websiteClicks: InstagramMetricsValue[] | null;
  comparisonWebsiteClicks: InstagramMetricsValue[] | null;
  totalWebsiteClicks: number | null;
  username: string | null;
  followingCount: number | null;
  postsCount: number | null;
  isLoading: boolean;
  isPublishingContent: boolean;
  error: string | null;
  posts: InstagramFeedPost[] | null;
  openInstagramAccontTypeDialog: boolean;
  instagramPostPreviewTabValue: number;
  followsCounts: InstagramFollows[] | undefined;
  comparisonFollowsCounts: InstagramFollows[] | undefined;
  audienceCountryInsights: AudienceInsight;
  audienceCityInsights: AudienceInsight;
  onlineActivity: any[];
};

export const initialInstagramState: InstagramState = {
  followers: null,
  comparisonFollowers: null,
  totalFollowers: null,
  reach: null,
  comparisonReach: null,
  totalReach: null,
  impressions: null,
  comparisonImpressions: null,
  totalImpressions: null,
  profileViews: null,
  comparisonProfileViews: null,
  totalProfileViews: null,
  websiteClicks: null,
  comparisonWebsiteClicks: null,
  totalWebsiteClicks: null,
  username: null,
  followingCount: null,
  postsCount: null,
  isLoading: false,
  isPublishingContent: false,
  error: null,
  posts: null,
  openInstagramAccontTypeDialog: false,
  instagramPostPreviewTabValue: 0,
  followsCounts: undefined,
  comparisonFollowsCounts: undefined,
  audienceCountryInsights: {} as AudienceInsight,
  audienceCityInsights: {} as AudienceInsight,
  onlineActivity: [],
};

const instagramSlice = createSlice({
  name: "instagram",
  initialState: initialInstagramState,
  reducers: {
    setInstagramIsLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    setInstagramPostPreviewTabValue: (state, { payload }) => {
      state.instagramPostPreviewTabValue = payload;
    },
    setInstagramIsPublishingContent: (state, { payload }) => {
      state.isPublishingContent = payload;
    },
    setOpenInstagramAccontTypeDialog: (state, { payload }) => {
      state.openInstagramAccontTypeDialog = payload;
    },
    resetInstagramData(state) {
      state.followers = null;
      state.totalFollowers = null;
      state.reach = null;
      state.totalReach = null;
      state.impressions = null;
      state.totalImpressions = null;
      state.username = null;
      state.followingCount = null;
      state.postsCount = null;
      state.isLoading = false;
      state.error = null;
      state.posts = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getInstagramMetrics.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getInstagramMetrics.fulfilled, (state, { payload }) => {
      state.reach = payload.reach;
      state.comparisonReach = payload.comparisonReach;
      state.totalReach = payload.totalReach;
      state.impressions = payload.impressions;
      state.comparisonImpressions = payload.comparisonImpressions;
      state.totalImpressions = payload.totalImpressions;
      state.followers = payload.followers;
      state.comparisonFollowers = payload.comparisonFollowers;
      state.profileViews = payload.profileViews;
      state.comparisonProfileViews = payload.comparisonProfileViews;
      state.totalProfileViews = payload.totalProfileViews;
      state.websiteClicks = payload.websiteClicks;
      state.comparisonWebsiteClicks = payload.comparisonWebsiteClicks;
      state.totalWebsiteClicks = payload.totalWebsiteClicks;
      state.isLoading = false;
    });
    builder.addCase(getInstagramMetrics.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getInstagramProfileFeed.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getInstagramProfileFeed.fulfilled, (state, { payload }) => {
      state.posts = payload || null;
      state.isLoading = false;
    });
    builder.addCase(getInstagramProfileFeed.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getInstagramFollowerCounts.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getInstagramFollowerCounts.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.followsCounts = payload.followsCounts;
      state.comparisonFollowsCounts = payload.comparisonFollowsCounts;
    });
    builder.addCase(getInstagramFollowerCounts.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getInstagramOnlineActivity.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getInstagramOnlineActivity.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.onlineActivity = payload;
    });
    builder.addCase(getInstagramOnlineActivity.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getInstagramAudienceInsights.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getInstagramAudienceInsights.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.audienceCityInsights = payload.audienceCityInsights;
      state.audienceCountryInsights = payload.audienceCountryInsights;
    });
    builder.addCase(getInstagramAudienceInsights.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getInstagramAccountInfo.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getInstagramAccountInfo.fulfilled, (state, { payload }) => {
      state.totalFollowers = payload?.followers_count ?? null;
      state.followingCount = payload?.follows_count ?? null;
      state.postsCount = payload?.media_count ?? null;
      state.username = payload?.username ?? null;
      state.isLoading = false;
    });
    builder.addCase(getInstagramAccountInfo.rejected, (state) => {
      state.isLoading = false;
    });
    // builder.addCase(getInstagramFollowersOverTime.fullfilled, (state) => {
    //   state.isLoading = false;
    // });
  },
});

export const {
  setInstagramIsLoading,
  setInstagramIsPublishingContent,
  resetInstagramData,
  setOpenInstagramAccontTypeDialog,
  setInstagramPostPreviewTabValue,
} = instagramSlice.actions;
export default instagramSlice.reducer;
