import { useAuthenticator } from "@aws-amplify/ui-react";
import { useCallback } from "react";

import { Member } from "../API";
import { useLazyGetMemberQuery } from "../api/memberApi";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { setMemberId, setSelectedBrandMember, setSelectedBrandMemberId } from "../redux/slices/userSlice";
import { addUserToOnboardingCampaign } from "../utils/convertKitUtils";

const useSetMemberState = () => {
  const dispatch = useAppDispatch();

  const { selectedBrandMember } = useAppSelector((state) => state.user);

  const [getMember] = useLazyGetMemberQuery();

  const { user, authStatus } = useAuthenticator((context) => [context.user]);

  const initializeMemberState = useCallback(async () => {
    if (authStatus !== "authenticated") {
      return;
    }

    // If the user is authenticated, set the member state
    const memberId = user?.attributes?.sub;

    if (memberId) {
      const memberResponse = await getMember({ id: memberId });

      if ("data" in memberResponse) {
        const member = memberResponse.data?.getMember as Member;

        if (member && member?.brandMembers && member?.brandMembers?.items.length > 0) {
          const brandMember = selectedBrandMember ?? member.brandMembers.items[0];

          if (brandMember) {
            dispatch(setMemberId(memberId));
            dispatch(setSelectedBrandMemberId(brandMember.id));
            dispatch(setSelectedBrandMember(brandMember));
          }
        }
      }
    }
  }, [authStatus, dispatch, getMember, selectedBrandMember, user?.attributes]);

  return { initializeMemberState };
};

export default useSetMemberState;
