import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import dayjs from "dayjs";
import toast from "react-hot-toast";

import { FacebookInsight, FacebookPagePost, FacebookPageVideo } from "../../types/facebookApi.types";
import { FACEBOOK_BASE_URL, FACEBOOK_DEFAULT_VERSION } from "../../utils/constants";

const getAxiosInstance = (accessToken: string, version = FACEBOOK_DEFAULT_VERSION) => {
  return axios.create({
    baseURL: `${FACEBOOK_BASE_URL}/${version}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

type GetFacebookPagePostsParams = {
  accessToken: string;
  pageId: string;
};

/**
 * Get Facebook page posts
 */
export const getFacebookPagePosts = createAsyncThunk(
  "facebook/getFacebookPagePosts",
  async ({ accessToken, pageId }: GetFacebookPagePostsParams) => {
    const axiosInstance = getAxiosInstance(accessToken);

    try {
      const postResponse = await axiosInstance.get(`${pageId}/feed`, {
        params: {
          fields:
            "id,message,created_time,shares,permalink_url,attachments{media_type,media,subattachments},insights.metric(post_engaged_users,post_impressions,post_reactions_like_total,post_reactions_love_total,post_reactions_wow_total,post_reactions_haha_total,post_reactions_sorry_total,post_reactions_anger_total,post_video_views)",
          limit: 15,
        },
      });

      // Get Facebook page videos
      const videoResponse = await axiosInstance.get(`${pageId}/videos`, {
        params: {
          fields:
            "id,title,description,source,created_time,permalink_url,updated_time,views,thumbnails{uri},video_insights.metric(post_video_view_time,post_video_social_actions,post_impressions_unique,post_video_avg_time_watched,post_video_likes_by_reaction_type)",
          limit: 15,
        },
      });

      return {
        posts: postResponse.data.data as FacebookPagePost[],
        videos: videoResponse.data.data as FacebookPageVideo[],
      };
    } catch (error) {
      toast.error("Error fetching Facebook posts");
      console.error(error);
      return {
        posts: null,
        videos: null,
      };
    }
  },
);

type GetFacebookMetricsThunkParams = {
  accessToken: string;
  pageId: string;
  timeframe: string[];
};

export const getFacebookMetrics = createAsyncThunk(
  "facebook/getFacebookMetrics",
  async ({ accessToken, pageId, timeframe }: GetFacebookMetricsThunkParams) => {
    const axiosInstance = getAxiosInstance(accessToken);

    const response = await axiosInstance.get(`${pageId}/insights`, {
      params: {
        metric: "page_impressions,page_posts_impressions,page_engaged_users,page_follows",
        period: "week",
        since: dayjs(timeframe[0]).unix(),
        until: dayjs(timeframe[1]).unix(),
      },
    });

    return response.data.data as FacebookInsight[];
  },
);

// End of thunk functions

export type FacebookState = {
  isLoading: boolean;
  posts: FacebookPagePost[] | null;
  videos: FacebookPageVideo[] | null;
  metrics: FacebookInsight[] | null;
};

export const initialFacebookState: FacebookState = {
  isLoading: false,
  posts: null,
  videos: null,
  metrics: null,
};

const facebookSlice = createSlice({
  name: "facebook",
  initialState: initialFacebookState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getFacebookPagePosts.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getFacebookPagePosts.fulfilled, (state, { payload }) => {
      state.posts = payload.posts;
      state.videos = payload.videos;
      state.isLoading = false;
    });
    builder.addCase(getFacebookPagePosts.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(getFacebookMetrics.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getFacebookMetrics.fulfilled, (state, { payload }) => {
      state.metrics = payload;
      state.isLoading = false;
    });
    builder.addCase(getFacebookMetrics.rejected, (state) => {
      state.isLoading = false;
    });
  },
});
// export const {} = facebookSlice.actions;
export default facebookSlice.reducer;
