import { BaseQueryFn, createApi } from "@reduxjs/toolkit/query/react";
import axios from "axios";

import {
  GetInstagramReelContainerIdArgs,
  GetInstagramStoryContainerIdArgs,
  PostInstagramStoryArgs,
} from "../types/instagramApi.types";
import { FACEBOOK_BASE_URL, FACEBOOK_DEFAULT_VERSION } from "../utils/constants";

type CustomBaseQueryArgs = {
  url: string;
  method: "GET" | "POST" | "PUT" | "PATCH" | "DELETE";
  accessToken: string;
  params?: Record<string, unknown>;
};

const customBaseQuery: BaseQueryFn<CustomBaseQueryArgs, unknown, unknown> = async ({ url, method, accessToken, params }) => {
  try {
    const axiosInstance = axios.create({
      baseURL: `${FACEBOOK_BASE_URL}/${FACEBOOK_DEFAULT_VERSION}`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const result = await axiosInstance({
      url,
      method,
      ...(method === "GET" || method === "DELETE" ? { params } : { data: params }),
    });

    // Always return data and error in the same structure
    return { data: result.data, error: null };
  } catch (axiosError) {
    // TODO: handle error better
    return { error: "lol" };
  }
};

export const instagramApi = createApi({
  reducerPath: "instagramApi",
  baseQuery: customBaseQuery,
  endpoints: (builder) => ({
    getInstagramStoryContainerId: builder.mutation<void, GetInstagramStoryContainerIdArgs>({
      query: ({ accessToken, instagramUserId, params }) => ({
        url: `/${instagramUserId}/media`,
        method: "POST",
        accessToken,
        params: {
          media_type: "STORIES",
          image_url: params.imageUrl,
        },
      }),
    }),

    getInstagramReelsContainerId: builder.mutation<void, GetInstagramReelContainerIdArgs>({
      query: ({ accessToken, instagramUserId, params }) => ({
        url: `/${instagramUserId}/media`,
        method: "POST",
        accessToken,
        params: {
          media_type: "REELS",
          video_url: params.videoUrl,
          cover_url: params.thumbnailUrl,
          share_to_feed: params.shareToFeed,
          caption: params.caption,
        },
      }),
    }),

    publishInstagramStory: builder.mutation<void, PostInstagramStoryArgs>({
      query: ({ accessToken, instagramUserId, params }) => ({
        url: `/${instagramUserId}/media_publish`,
        method: "POST",
        accessToken,
        params: {
          creation_id: params.creationId,
        },
      }),
    }),
  }),
});

export const { useGetInstagramStoryContainerIdMutation, usePublishInstagramStoryMutation } = instagramApi;
